export enum AlertType {
  SEARCH = 'search',
  MAGAZINE = 'magazine',
  NEWS = 'news',
  ALL_TAB = 'all',
  REGULAR = 'regular',
}

export interface AlertFacetValue {
  /** The label name of the facet. eg; 'Naslag' or 'Dividenbelasting' */
  label: string;
  /**
   * The selected values.
   * Tabs facet uses a string[] because of tabs can contain ['lit','artikel'] for example
   * Other filters use a plain string ans in and output
   **/
  value: string | string[];
}

interface AlertFacets {
  [key: string]: AlertFacetValue[];
}

export interface AlertParameters {
  type?: AlertType;
  facets: AlertFacets;
  title?: string;
  term?: string;
  query?: string;
}
