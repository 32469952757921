import {
  CONTENT_EXPORT_TYPES,
  ContentExportDrawer,
  ContentExportPluginDockIconItem,
} from '@elseu/sdu-titan-content-export-react';
import type { DockItemData } from '@elseu/sdu-titan-content-rendering';
import { canUseContentExportPluginResult } from '@elseu/sdu-titan-product-site-components';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useAuthentication } from '@repo/auth';

import { TaxviceUpsellDrawer } from '../TaxviceUpsellDrawer/TaxviceUpsellDrawer';

const TAXVICE_ROLE = 'taxvice';

interface TaxviceSharePluginDockIconItemProps {
  itemData: DockItemData;
  onClick: () => void;
}

export const TaxviceSharePluginDockIconItem = ({
  onClick,
}: TaxviceSharePluginDockIconItemProps) => (
  <ContentExportPluginDockIconItem
    labelPluginButton={t`Addify`}
    type={CONTENT_EXPORT_TYPES.SHARE_TO_TAXVICE}
    onClick={onClick}
  />
);

interface TaxviceSharePluginDockComponentsProps {
  itemData: DockItemData;
  isShown: boolean;
  baseUrl?: string;
  onDrawerToggle: (isShown: boolean) => void;
}
const TaxviceSharePluginDockComponents = ({
  itemData,
  isShown,
  onDrawerToggle,
  baseUrl,
}: TaxviceSharePluginDockComponentsProps) => {
  const { data } = itemData;
  const { swsId, swsDocumentTitle, activeContentPart } = data;
  const { isLoggedIn, claims } = useAuthentication();
  const { i18n } = useLingui();

  if (!isLoggedIn || !swsId) {
    return null;
  }

  if (!claims?.role?.includes(TAXVICE_ROLE)) {
    return <TaxviceUpsellDrawer isShown={isShown} onDrawerToggle={onDrawerToggle} />;
  }

  const { canExportFullDocument, canExportParts } = canUseContentExportPluginResult(data);

  if (!swsId || !swsDocumentTitle) return null;

  // add fed_id and sub as sso_hint_sub and sso_hint_fed_id to url.
  const taxviceShareUrl = new URL(baseUrl || '');

  if (claims.fed_id) {
    taxviceShareUrl.searchParams.set('sso_hint_fed_id', claims.fed_id);
  }

  if (claims.sub) {
    taxviceShareUrl.searchParams.set('sso_hint_sub', claims.sub);
  }

  return (
    <ContentExportDrawer
      activeId={activeContentPart}
      canExportFullDocument={canExportFullDocument}
      canExportParts={canExportParts}
      isShown={isShown}
      labelCancelButtonTitle={<Trans>Annuleren</Trans>}
      labelCannotSaveDocument={
        <Trans>Je kunt dit document helaas niet exporteren naar Addify</Trans>
      }
      labelDrawerErrorButton={<Trans>Opnieuw opslaan</Trans>}
      labelDrawerErrorDescription={
        <Trans>Het lijkt erop dat er onverwacht iets fout is gegaan.</Trans>
      }
      labelDrawerErrorHeader={<Trans>Er is een fout opgetreden</Trans>}
      labelDrawerHeader={<Trans>Opslaan in Addify dossier</Trans>}
      labelDrawerLoader=""
      labelDrawerSubtitle={
        <Trans>Selecteer de onderdelen die je wilt opslaan in het Addify dossier.</Trans>
      }
      labelhasTooManyParts={({ limit }) => (
        <Trans>
          Maximaal {limit} onderdeel mag in één keer geëxporteerd worden. Pas deselectie aan om
          verder te gaan.
        </Trans>
      )}
      labelSaveButtonTitle={<Trans>Opslaan</Trans>}
      labelSelectAll={t`Volledige document`}
      limit={1}
      locale={i18n.locale}
      swsId={swsId}
      taxviceShareBaseUrl={taxviceShareUrl.toString()}
      type={CONTENT_EXPORT_TYPES.SHARE_TO_TAXVICE}
      onDrawerToggle={onDrawerToggle}
    />
  );
};

export default TaxviceSharePluginDockComponents;
