import { Box, Button, ContentWrapper, Header, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { NextSeo } from 'next-seo';

const AccessDenied = () => {
  useLingui();
  return (
    <>
      <NextSeo
        nofollow
        noindex
        description={t`Je hebt geen toegang tot deze pagina.
        Wij helpen je graag de weg terug te vinden. Je kan altijd naar de homepage of de zoekfunctie gebruiken.`}
        title={t`Geen toegang`}
      />
      <ContentWrapper id="main-content" maxWidth={640} spaceAfter={20} testId="accessDenied">
        <Header
          hasContentWrapper={false}
          labelBackLink={<Trans>Home</Trans>}
          title={<Trans>Geen toegang</Trans>}
        />
        <Box spaceAfter={4}>
          <Text type="paragraph">
            <Trans>
              Je hebt geen toegang tot deze pagina.
              <br />
              Wij helpen je graag de weg terug te vinden. Je kan altijd naar de homepage of de
              zoekfunctie gebruiken.
            </Trans>
          </Text>
        </Box>
        <Button href="/" testId="notFoundHomeButton">
          <Trans>Terug naar home</Trans>
        </Button>
      </ContentWrapper>
    </>
  );
};

export { AccessDenied };
