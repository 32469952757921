import { useMemo } from 'react';

/**
 * useDefaultHash() returns the hash that was there when you loaded the page / route, but the return value
 * doesn't change when the hash changes within the page itself
 */
const useDefaultHash = () => {
  const pathWithoutParamsOrHash =
    typeof window === 'undefined' ? undefined : window.location.href.split(/[?#]/)[0];

  const defaultHash = useMemo(
    () => (pathWithoutParamsOrHash ? window.location.hash.slice(1) : undefined),
    [pathWithoutParamsOrHash],
  );

  return defaultHash;
};

export { useDefaultHash };
