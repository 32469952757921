import type { SearchRootProviderProps, TabWithKeys } from '@elseu/sdu-titan-search';
import qs from 'qs';

/**
 * Constructs a resolver function for search URLs.
 * @param {object} config - Configuration object containing URL templates and tab information.
 * @param {string} config.searchUrl - Base URL for the search page.
 * @param {string} config.magazineUrl - URL template for magazine pages.
 * @param {TabWithKeys[]} config.tabs - List of tab configurations.
 * @param {string} config.tabsFacet - Name of the facet used to filter by tabs.
 * @returns {SearchRootProviderProps['searchUrlResolver']} A function that resolves to the appropriate URL based on the given state.
 */
export const constructSearchUrlResolver =
  ({
    searchUrl,
    magazineUrl,
    tabs,
    tabsFacet,
  }: {
    searchUrl: string;
    magazineUrl: string;
    tabs: TabWithKeys[];
    tabsFacet: string;
  }): SearchRootProviderProps['searchUrlResolver'] =>
  (state) => {
    const { tabId, tabKeyId, hasEditions, sort, ...queryStringObject } = state;
    let tab: TabWithKeys | undefined;

    if (tabId) tab = tabs.find((tab) => tab.id === tabId);
    if (tabKeyId) tab = tabs.find((tab) => tab.keys.includes(tabKeyId!));

    if (hasEditions && state.ro_source) {
      return magazineUrl.replace(':magazineId', state.ro_source as string);
    }

    if (tab) {
      queryStringObject[tabsFacet] = tab.keys;
    }

    if (sort) {
      queryStringObject.sort = `${sort.by}.${sort.order}`;
    }

    const queryString = qs.stringify(queryStringObject, {
      addQueryPrefix: false,
      arrayFormat: 'comma',
    });
    return `${searchUrl}?${queryString}`;
  };
