import type { AnalyticsPlugin } from 'analytics';
import type { IAnalyticsPlugin } from 'use-analytics';

export type CombinedAnalyticsPlugin = IAnalyticsPlugin & AnalyticsPlugin;

export enum PaywallAnalyticsEvent {
  SHOWN = 'paywall_shown',
}

export enum BluetickAnalyticsEvent {
  EVENT = 'bluetick',
  CHAT = 'genial-chat',
  ANSWER = 'search_result_genai_answer',
}

export enum PingAnalyticsEvent {
  PING_KEY_PENDING = 'pingKeyPending',
  PING_KEY_AVAILABLE = 'pingKeyAvailable',
  USER_INITIATED_PING_LOGIN = 'userInitiatedPingLogin',
  USER_ID_AVAILABLE = 'userIdAvailable',
}

const AnalyticsEventObject = {
  ...PaywallAnalyticsEvent,
  ...BluetickAnalyticsEvent,
  ...PingAnalyticsEvent,
};

export type AnalyticsEvent = (typeof AnalyticsEventObject)[keyof typeof AnalyticsEventObject];
export function isAnalyticsEvent(event: any): event is AnalyticsEvent {
  return Object.values(AnalyticsEventObject).includes(event);
}
