import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
/** Possible values for the types for explanation */
export var ExplanationType;
(function (ExplanationType) {
    /** Chapter (Hoofdstuk) code type */
    ExplanationType["CHAPTER"] = "chapter";
    /** Goods code type */
    ExplanationType["GOODS"] = "goods";
    /** Section (Afdeling) code type */
    ExplanationType["SECTION"] = "section";
})(ExplanationType || (ExplanationType = {}));
export const GenericTocEntryFragmentDoc = gql `
  fragment GenericTocEntry on TocEntry {
    id
    anchor
    label: name
    parentId
    hasChildren
  }
`;
export const PracticeNoteTocEntryFragmentDoc = gql `
  fragment PracticeNoteTocEntry on PracticeNoteTocEntry {
    id
    anchor
    label: name
    parentId
    hasChildren
  }
`;
export const CaseLawTocEntryFragmentDoc = gql `
  fragment CaseLawTocEntry on CaseLawTocEntry {
    id
    anchor
    label: name
    parentId
    hasChildren
  }
`;
export const LawTocEntryFragmentDoc = gql `
  fragment LawTocEntry on LawTocEntry {
    label: name
    parentId
    ... on GenericLawTocEntry {
      id
      anchor
      hasChildren
    }
    ... on OpschriftLawTocEntry {
      id
      anchor
      hasChildren
    }
    ... on ArticleLawTocEntry {
      id
      anchor
      hasChildren
    }
  }
`;
export const BookTocEntryFragmentDoc = gql `
  fragment BookTocEntry on BookTocEntry {
    id
    label: name
    anchor
    parentId
    hasChildren
  }
`;
export const ArticleTocEntryFragmentDoc = gql `
  fragment ArticleTocEntry on ArticleTocEntry {
    id
    label: name
    anchor
    parentId
    hasChildren
  }
`;
export const TocEntriesDocument = gql `
  query TocEntries($swsId: String!, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      title
      ... on Commentary {
        toc {
          children: entries {
            ...GenericTocEntry
          }
        }
      }
      ... on PracticeNote {
        toc {
          children: entries {
            ...PracticeNoteTocEntry
          }
        }
      }
      ... on CaseLaw {
        toc {
          children: entries {
            ...CaseLawTocEntry
          }
        }
      }
      ... on Law {
        toc {
          children: entries {
            ...LawTocEntry
          }
        }
      }
      ... on Eurlex {
        toc {
          children: entries {
            ...LawTocEntry
          }
        }
      }
      ... on Book {
        toc {
          children: entries {
            ...BookTocEntry
          }
        }
      }
      ... on Article {
        toc {
          children: entries {
            ...ArticleTocEntry
          }
        }
      }
    }
  }
  ${GenericTocEntryFragmentDoc}
  ${PracticeNoteTocEntryFragmentDoc}
  ${CaseLawTocEntryFragmentDoc}
  ${LawTocEntryFragmentDoc}
  ${BookTocEntryFragmentDoc}
  ${ArticleTocEntryFragmentDoc}
`;
/**
 * __useTocEntriesQuery__
 *
 * To run a query within a React component, call `useTocEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTocEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTocEntriesQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useTocEntriesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TocEntriesDocument, options);
}
export function useTocEntriesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TocEntriesDocument, options);
}
