function findDeep<T extends { children?: T[] }>(
  haystack: T[],
  needle: string,
  value: string,
): T | undefined {
  for (let i = 0; i < haystack.length; i += 1) {
    // @ts-expect-error fix this typing
    if (haystack[i][needle as keyof T] === value) {
      return haystack[i];
    }

    // @ts-expect-error fix this typing
    if (haystack[i].children) {
      // @ts-expect-error fix this typing
      const item = findDeep(haystack[i].children as T[], needle, value);
      if (item) return item;
    }
  }
}

export { findDeep };
