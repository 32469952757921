import { useCallback } from 'react';

interface UseConsentManager {
  showConsentManager: () => void;
}

export const useConsentManager = (): UseConsentManager => {
  const showConsentManager = useCallback(() => {
    window.sduGdprConsent?.('showConsentManager');
  }, []);

  return { showConsentManager };
};
