import type { CheckboxOptionProps } from '@elseu/sdu-titan/dist/types/components/CheckboxGroup/CheckboxGroup';

/**
 * Interface representing a dictionary of CheckboxOptionProps indexed by their value.
 */
export interface CheckboxOptionPropsByValue {
  [key: string]: CheckboxOptionProps;
}

/**
 * Recursively flattens a tree of checkbox options into an object indexed by the value of each option.
 *
 * @param {CheckboxOptionProps[]} items - The initial list of checkbox options.
 * @returns {CheckboxOptionPropsByValue} An object where each key is a checkbox value and the corresponding value is the checkbox option.
 */
const getCheckboxOptionsByValue = (items: CheckboxOptionProps[]): CheckboxOptionPropsByValue =>
  items.reduce(
    (acc: CheckboxOptionPropsByValue, item: CheckboxOptionProps): CheckboxOptionPropsByValue => ({
      ...acc,
      [item.value]: item,
      ...(item.children?.length ? getCheckboxOptionsByValue(item.children) : {}),
    }),
    {},
  );

export { getCheckboxOptionsByValue };
