import type { Icon, SidebarItemProps } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { arrayToTree } from 'performant-array-to-tree';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import {
  NavigationDocument,
  type NavigationMenuPageLink,
  type NavigationQuery,
  type NavigationQueryVariables,
} from '../../generated/cms/graphql';
import icons from '../../helpers/icons';
import { isNotEmpty } from '../../helpers/isNotEmpty';
import { useCMSQuery } from '../../hooks/useCmsQuery';
import { useSiteConfig } from '../SiteConfigProvider';

export interface MenuPagesBySlug {
  [key: string]: NavigationMenuPageLink;
}
export interface HighlightedItem {
  title: string;
  children: SidebarItemProps[];
}
interface NavigationContextProps {
  navigation?: SidebarItemProps[];
  menuPagesBySlug?: MenuPagesBySlug;
  highlightedItem?: HighlightedItem;
  isLoading: boolean;
  hasError: boolean;
  isShown: boolean;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavigationContext = React.createContext<NavigationContextProps | undefined>({
  isLoading: true,
  hasError: false,
  isShown: false,
  setShown: () => undefined,
});

export const useNavigation = () => {
  const context = React.useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};

type NavigationSite = NonNullable<NavigationQuery['site']>;
type Navigation = NonNullable<NavigationSite['navigation']>;
type NavigationChildren = NonNullable<Navigation['children']>;

export const NavigationProvider: React.FC<React.PropsWithChildren> = ({ ...rest }) => {
  const { _ } = useLingui();
  const { urls } = useSiteConfig();
  const { response, isLoading, error } = useCMSQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    { skip: process.env.BUILD === 'true' },
  );
  const [isShown, setShown] = useState(false);

  const getNavigationLinksByTypename = useCallback(
    (item: {
      __typename?: string;
      slug?: string;
      swsDocumentId?: string;
      href?: string;
    }): string => {
      const { __typename } = item;

      if (__typename === 'NavigationMenuPageLink' && item.slug) {
        return `/overzicht${item.slug}`;
      }
      if (__typename === 'NavigationLinkSwsDocumentLink' && item.swsDocumentId) {
        return `${urls.contentUrl}/${item.swsDocumentId}`;
      }
      if (__typename === 'NavigationCustomLink') {
        return item.href || '/';
      }
      return '/';
    },
    [urls.contentUrl],
  );

  const getFilteredChildren = useCallback(
    (children: NavigationChildren) => children.filter(isNotEmpty),
    [],
  );
  const mapToSidebarItems = useCallback(
    (children: NavigationChildren): Array<SidebarItemProps & { parent?: string }> =>
      getFilteredChildren(children).map((child) => {
        const { icon, id, title, parent } = child;
        const IconComponent = icon ? (icons[icon as keyof typeof icons] as Icon) : undefined;

        const item: SidebarItemProps & { parent?: string } = {
          id,
          title,
          exact: true,
          parent,
          to: getNavigationLinksByTypename(child),
          ...(icon ? { icon: IconComponent } : {}),
        };
        return item;
      }),
    [getFilteredChildren, getNavigationLinksByTypename],
  );

  const getNavigationItems = useCallback(
    (children: NavigationChildren): SidebarItemProps[] | undefined => {
      if (!children.length) return;

      const sidebarItems = mapToSidebarItems(children);
      const sidebarItemsTree = arrayToTree(sidebarItems, {
        rootParentIds: { '0': true },
        parentId: 'parent',
        dataField: null,
      });

      return sidebarItemsTree as SidebarItemProps[];
    },
    [mapToSidebarItems],
  );

  const getMenuPagesBySlug = useCallback(
    (children: NavigationChildren): MenuPagesBySlug | undefined => {
      if (!children.length) return;

      return getFilteredChildren(children).reduce(
        (acc: MenuPagesBySlug, item: ReturnType<typeof getFilteredChildren>[number]) => ({
          ...acc,
          ...(item.__typename === 'NavigationMenuPageLink' ? { [item.slug]: item } : {}),
        }),
        {},
      );
    },
    [getFilteredChildren],
  );

  /**
   * Get the highlighted parent item and its children, otherwise check for single highlighted items and use those
   */
  const getHighlightedItem = useCallback(
    (children: NavigationChildren): HighlightedItem | undefined => {
      const filteredChildren = getFilteredChildren(children);
      /** Highlighted parent item with children */
      const highlightedParentItem = filteredChildren.find(
        (item) => item.__typename === 'NavigationLinkWithChildren' && item.isHighlighted,
      );
      if (highlightedParentItem) {
        const { title } = highlightedParentItem;
        return {
          title: t`Navigeer via ${title}`,
          children: mapToSidebarItems(
            children.filter((child) => child && child.parent === highlightedParentItem.id),
          ),
        };
      }

      /** Single highlighted items */
      const highlightedSingleItems = filteredChildren.filter(
        (item) => item.__typename !== 'NavigationLinkWithChildren' && item.isHighlightedItem,
      );
      if (highlightedSingleItems.length) {
        return {
          title: t`Navigeer direct naar`,
          children: mapToSidebarItems(highlightedSingleItems),
        };
      }
    },
    [getFilteredChildren, mapToSidebarItems],
  );

  const data = useMemo(
    () => (response?.site?.navigation?.children || []).filter(isNotEmpty),
    [response],
  );

  const context = useMemo(
    () => ({
      navigation: getNavigationItems(data),
      menuPagesBySlug: getMenuPagesBySlug(data),
      highlightedItem: getHighlightedItem(data),
      isLoading,
      hasError: !!error,
      isShown,
      setShown,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getNavigationItems,
      _,
      data,
      getHighlightedItem,
      getMenuPagesBySlug,
      isLoading,
      error,
      isShown,
      setShown,
    ],
  );

  return <NavigationContext.Provider {...rest} value={context} />;
};
