import { jsx as _jsx } from "react/jsx-runtime";
import { CheckboxTree, Loader, rem } from '@elseu/sdu-titan';
import { useScrollElement } from 'react-unstuck';
import styled from 'styled-components';
import { useContentExportDrawer } from './ContentExportDrawerProvider';
const StyledContainer = styled.div `
  margin-left: -${rem(16)};
`;
const CheckboxTreeWithScrollContainer = (props) => {
    const scrollContainer = useScrollElement();
    return (_jsx(CheckboxTree, Object.assign({}, props, { scrollContainer: scrollContainer, testId: "contentExportDrawerCheckboxTree" })));
};
const ContentExportDrawerTree = () => {
    const { activeId, contentParts, treeOptions, onSelectionChange, isTreeDataLoading, drawerScrollOffset, } = useContentExportDrawer();
    if (isTreeDataLoading) {
        return _jsx(Loader, { height: 48, testId: "contentExportDrawerTocLoader", variant: "spinner" });
    }
    return (_jsx(StyledContainer, { children: _jsx(CheckboxTreeWithScrollContainer, { data: treeOptions, defaultExpandedValue: activeId ? [activeId] : [], defaultScrolledToValue: activeId, defaultValue: contentParts, listItemVariant: "plus", scrollOffset: drawerScrollOffset, value: contentParts, onChange: onSelectionChange }) }));
};
export { ContentExportDrawerTree };
