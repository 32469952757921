import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AddFacet = {
  /** Name of the facet */
  name?: InputMaybe<Scalars['String']['input']>;
  /** selected values for that facet name */
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Alerts for user */
export type Alert = {
  __typename?: 'Alert';
  /** ISO 8601 formatted date of alert creation */
  created: Scalars['String']['output'];
  /** Day on which weekly alert should occur */
  day: Scalars['String']['output'];
  /** selected facets for alert */
  facets: Array<Facet>;
  /** Frequency of alert occurrence */
  frequency: Scalars['String']['output'];
  /** Id of alert */
  id: Scalars['String']['output'];
  /** if alert is in active state */
  isActive: Scalars['Boolean']['output'];
  /** if alert should be sent together with other alerts of the same user */
  isGrouped: Scalars['Boolean']['output'];
  /** is alert about certain magazine */
  isMagazine: Scalars['Boolean']['output'];
  /** ISO 8601 formatted date of last alert modification */
  modified: Scalars['String']['output'];
  /** Name of the alert */
  name: Scalars['String']['output'];
  /** Site id */
  siteId: Scalars['String']['output'];
  /** Search term */
  term: Scalars['String']['output'];
  /** User id */
  userId: Scalars['String']['output'];
};

export type CreateAlertParameters = {
  /** Day on which the weekly alert should occur */
  day: Scalars['String']['input'];
  /** Facets selected for the alert */
  facets?: InputMaybe<Array<AddFacet>>;
  /** Frequency of alerts ( weekly, daily, ASAP, never ) */
  frequency: Scalars['String']['input'];
  /** Search term for alert */
  term?: InputMaybe<Scalars['String']['input']>;
};

/** List of ExternalUser */
export type ExternalUser = {
  __typename?: 'ExternalUser';
  /** External user email address */
  email: Scalars['String']['output'];
  /** External user full name */
  name?: Maybe<Scalars['String']['output']>;
  /** External user id */
  uid: Scalars['String']['output'];
};

/** Facets for alert */
export type Facet = {
  __typename?: 'Facet';
  /** Name of the facet */
  name: Scalars['String']['output'];
  /** selected values for that facet name */
  value: Array<Scalars['String']['output']>;
};

/** Reading list of a user */
export type ReadingList = {
  __typename?: 'ReadingList';
  /** ISO 8601 formatted datetime value in UTC */
  created: Scalars['String']['output'];
  /** Description of a reading list */
  description?: Maybe<Scalars['String']['output']>;
  /** Id of a reading list */
  id: Scalars['String']['output'];
  /** Reading list items */
  items: Array<ReadingListItem>;
  /** ISO 8601 formatted datetime value in UTC */
  modified: Scalars['String']['output'];
  /** Name of a reading list */
  name: Scalars['String']['output'];
  /** Site id */
  siteId: Scalars['String']['output'];
  /** Reading list items */
  totalDocuments: Scalars['Int']['output'];
  /** List of user access for a reading list */
  userAccess: Array<ReadingListAccess>;
  /** User id */
  userId: Scalars['String']['output'];
  /** User's name */
  userName?: Maybe<Scalars['String']['output']>;
};

/** Reading list of a user */
export type ReadingListItemsArgs = {
  sort?: InputMaybe<ReadingListItemSortParameters>;
};

/** List of user access records of a reading list */
export type ReadingListAccess = {
  __typename?: 'ReadingListAccess';
  /** Create date in ISO 8601 formatted datetime value in UTC */
  created: Scalars['String']['output'];
  /** Email address associated to the user access record */
  email?: Maybe<Scalars['String']['output']>;
  /** Id of a reading list user access record */
  id: Scalars['String']['output'];
  /** Update date in ISO 8601 formatted datetime value in UTC */
  modified: Scalars['String']['output'];
  /** User id associated to the user access record */
  userId: Scalars['String']['output'];
  /** User full name associated to the user access record */
  userName?: Maybe<Scalars['String']['output']>;
  /** User role associated to the user access record */
  userRole: Scalars['String']['output'];
};

export type ReadingListAccessParameters = {
  /** User id with access */
  userId: Scalars['String']['input'];
  /** User role for access */
  userRole: Scalars['String']['input'];
};

/** Item of a reading list */
export type ReadingListItem = {
  __typename?: 'ReadingListItem';
  /** Indication if item is accessible to user */
  accessible?: Maybe<Scalars['Boolean']['output']>;
  /** Item author */
  author?: Maybe<Scalars['String']['output']>;
  /** Create date in ISO 8601 formatted datetime value in UTC */
  created: Scalars['String']['output'];
  /** User id of the user who added the item */
  createdByUserId: Scalars['String']['output'];
  /** User's name of the user who added the item */
  createdByUserName?: Maybe<Scalars['String']['output']>;
  /** Item description */
  description?: Maybe<Scalars['String']['output']>;
  /** Id of a reading list */
  id: Scalars['String']['output'];
  /** Item reference, document key or a url */
  itemReference: Scalars['String']['output'];
  /** Item type */
  itemType: Scalars['String']['output'];
  /** Update date in ISO 8601 formatted datetime value in UTC */
  modified: Scalars['String']['output'];
  /** Publication date */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** Item source */
  source?: Maybe<Scalars['String']['output']>;
  /** Item title */
  title: Scalars['String']['output'];
};

export type ReadingListItemParameters = {
  /** Description of the item */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Item reference (key or url) */
  itemReference: Scalars['String']['input'];
  /** Title of the item */
  title: Scalars['String']['input'];
};

/** default sort by modified, order descending */
export type ReadingListItemSortParameters = {
  /** what to sort on */
  by: ReadingListItemSort;
  /** sort order */
  order?: InputMaybe<Order>;
};

/** default sort by modified, order descending */
export type ReadingListSortParameters = {
  /** what to sort on */
  by: ReadingListSort;
  /** sort order */
  order?: InputMaybe<Order>;
};

/** Sharing link which can be shared to get access to a reading list */
export type SharingLink = {
  __typename?: 'SharingLink';
  /** Create date in ISO 8601 formatted datetime value in UTC */
  created: Scalars['String']['output'];
  /** User id of the user who added the item */
  createdByUserId: Scalars['String']['output'];
  /** Id of a sharing link */
  id: Scalars['String']['output'];
  /** Id of the reading list */
  readingListId: Scalars['String']['output'];
  /** Site id */
  siteId: Scalars['String']['output'];
  /** Valid until date in ISO 8601 formatted datetime value in UTC */
  validUntil?: Maybe<Scalars['String']['output']>;
};

/** Item of favourite sources */
export type Source = {
  __typename?: 'Source';
  /** Id of a source */
  id: Scalars['String']['output'];
  /** Name of a source */
  name: Scalars['String']['output'];
  /** user id */
  userId: Scalars['String']['output'];
};

export type UpdateAlertParameters = {
  /** Day on which the weekly alert should occur */
  day?: InputMaybe<Scalars['String']['input']>;
  /** Frequency of alerts ( weekly, daily, ASAP, never ) */
  frequency?: InputMaybe<Scalars['String']['input']>;
  /** Name for alert */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences services mutations */
export type Mutation = {
  __typename?: 'mutation';
  /** Accept access to reading list using a sharing link */
  acceptSharingLink?: Maybe<ReadingList>;
  /** Add new document to reading list */
  addDocumentToReadingList?: Maybe<ReadingList>;
  /** Add user access to a reading list */
  addReadingListAccess?: Maybe<ReadingList>;
  /** Create new alert */
  createAlert?: Maybe<Alert>;
  /** Add source to user favorite sources */
  createFavoriteSource?: Maybe<Source>;
  /** Create new reading list for a user, with specified documents */
  createReadingList?: Maybe<ReadingList>;
  /** Create/get new sharing link for a given reading list */
  createSharingLink?: Maybe<SharingLink>;
  /** Delete alert */
  deleteAlert?: Maybe<Alert>;
  /** Delete document from reading list */
  deleteDocument?: Maybe<ReadingList>;
  /** Delete source from user favorite sources */
  deleteFavoriteSource?: Maybe<Source>;
  /** Delete reading list */
  deleteReadingList?: Maybe<ReadingList>;
  /** Remove user access to a reading list */
  removeReadingListAccess?: Maybe<ReadingList>;
  /** Update alert */
  updateAlert?: Maybe<Alert>;
  /** Change the description of a reading list for specified user */
  updateReadingListDescription?: Maybe<ReadingList>;
  /** Change name and description of a reading list for specified user */
  updateReadingListDetails?: Maybe<ReadingList>;
  /** Change the name of a reading list for specified user */
  updateReadingListName?: Maybe<ReadingList>;
};

/** User preferences services mutations */
export type MutationAcceptSharingLinkArgs = {
  sharingLinkId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationAddDocumentToReadingListArgs = {
  itemDescription?: InputMaybe<Scalars['String']['input']>;
  itemReference: Scalars['String']['input'];
  itemTitle: Scalars['String']['input'];
  listId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationAddReadingListAccessArgs = {
  listId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  userAccess: ReadingListAccessParameters;
};

/** User preferences services mutations */
export type MutationCreateAlertArgs = {
  alert: CreateAlertParameters;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isGrouped?: InputMaybe<Scalars['Boolean']['input']>;
  isMagazine?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences services mutations */
export type MutationCreateFavoriteSourceArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences services mutations */
export type MutationCreateReadingListArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<ReadingListItemParameters>>;
  name: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationCreateSharingLinkArgs = {
  listId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationDeleteAlertArgs = {
  alertId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationDeleteDocumentArgs = {
  itemReference: Scalars['String']['input'];
  listId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationDeleteFavoriteSourceArgs = {
  siteId: Scalars['String']['input'];
  sourceId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences services mutations */
export type MutationDeleteReadingListArgs = {
  listId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationRemoveReadingListAccessArgs = {
  listId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationUpdateAlertArgs = {
  alert: UpdateAlertParameters;
  alertId: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isGrouped?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences services mutations */
export type MutationUpdateReadingListDescriptionArgs = {
  listDescription?: InputMaybe<Scalars['String']['input']>;
  listId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationUpdateReadingListDetailsArgs = {
  listDescription?: InputMaybe<Scalars['String']['input']>;
  listId: Scalars['String']['input'];
  listName: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** User preferences services mutations */
export type MutationUpdateReadingListNameArgs = {
  listId: Scalars['String']['input'];
  listName: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** Possible values for the sort param */
export enum Order {
  /** sort in ascending order (smallest to largest/oldest to newest */
  Asc = 'asc',
  /** sort in descending order (largest to smallest/newest to oldest) */
  Desc = 'desc',
}

/** User preferences service queries */
export type Query = {
  __typename?: 'query';
  /** Retrieve user alerts */
  alert: Array<Alert>;
  /** Retrieve all alerts for site */
  alerts: Array<Alert>;
  /** Retrieve users from external systems */
  externalUsers: Array<ExternalUser>;
  /** Retrieve favourite sources */
  favoriteSources: Array<Source>;
  /** Retrieve reading lists */
  readingList: Array<ReadingList>;
};

/** User preferences service queries */
export type QueryAlertArgs = {
  alertId?: InputMaybe<Scalars['String']['input']>;
  magazineId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences service queries */
export type QueryAlertsArgs = {
  siteId: Scalars['String']['input'];
};

/** User preferences service queries */
export type QueryExternalUsersArgs = {
  externalUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences service queries */
export type QueryFavoriteSourcesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  sourceId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** User preferences service queries */
export type QueryReadingListArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  itemReference?: InputMaybe<Scalars['String']['input']>;
  listId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<ReadingListSortParameters>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Possible values for the sort param */
export enum ReadingListItemSort {
  /** sort by created date */
  Created = 'created',
  /** sort by modified date */
  Modified = 'modified',
  /** sort by title */
  Title = 'title',
}

/** Possible values for the sort param */
export enum ReadingListSort {
  /** sort by created date */
  Created = 'created',
  /** sort by modified date */
  Modified = 'modified',
  /** sort by name */
  Name = 'name',
  /** sort by total documents count */
  TotalDocuments = 'totalDocuments',
}

export type AddDocumentToReadingListMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  listId: Scalars['String']['input'];
  itemReference: Scalars['String']['input'];
  itemTitle: Scalars['String']['input'];
}>;

export type AddDocumentToReadingListMutation = {
  __typename?: 'mutation';
  addDocumentToReadingList?: {
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    items: Array<{
      __typename?: 'ReadingListItem';
      id: string;
      itemReference: string;
      title: string;
      description?: string | null;
      created: string;
      modified: string;
      createdByUserName?: string | null;
      source?: string | null;
      author?: string | null;
      publicationDate?: string | null;
      accessible?: boolean | null;
    }>;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  } | null;
};

export type CreateReadingListMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<ReadingListItemParameters> | ReadingListItemParameters>;
}>;

export type CreateReadingListMutation = {
  __typename?: 'mutation';
  createReadingList?: {
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  } | null;
};

export type DeleteDocumentMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  listId: Scalars['String']['input'];
  itemReference: Scalars['String']['input'];
}>;

export type DeleteDocumentMutation = {
  __typename?: 'mutation';
  deleteDocument?: {
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    items: Array<{
      __typename?: 'ReadingListItem';
      id: string;
      itemReference: string;
      title: string;
      description?: string | null;
      created: string;
      modified: string;
      createdByUserName?: string | null;
      source?: string | null;
      author?: string | null;
      publicationDate?: string | null;
      accessible?: boolean | null;
    }>;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  } | null;
};

export type DeleteReadingListMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  listId: Scalars['String']['input'];
}>;

export type DeleteReadingListMutation = {
  __typename?: 'mutation';
  deleteReadingList?: { __typename?: 'ReadingList'; id: string } | null;
};

export type UpdateReadingListDetailsMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  listId: Scalars['String']['input'];
  listName: Scalars['String']['input'];
  listDescription: Scalars['String']['input'];
}>;

export type UpdateReadingListDetailsMutation = {
  __typename?: 'mutation';
  updateReadingListDetails?: {
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  } | null;
};

export type AcceptSharingLinkMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  sharingLinkId: Scalars['String']['input'];
}>;

export type AcceptSharingLinkMutation = {
  __typename?: 'mutation';
  acceptSharingLink?: {
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    items: Array<{
      __typename?: 'ReadingListItem';
      id: string;
      itemReference: string;
      title: string;
      description?: string | null;
      created: string;
      modified: string;
      createdByUserName?: string | null;
      source?: string | null;
      author?: string | null;
      publicationDate?: string | null;
      accessible?: boolean | null;
    }>;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  } | null;
};

export type ReadingListsPluginQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
  applicationKey: Scalars['String']['input'];
  itemReference?: InputMaybe<Scalars['String']['input']>;
}>;

export type ReadingListsPluginQuery = {
  __typename?: 'query';
  readingList: Array<{ __typename?: 'ReadingList'; id: string }>;
};

export type AddReadingListAccessMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  listId: Scalars['String']['input'];
  userAccess: ReadingListAccessParameters;
}>;

export type AddReadingListAccessMutation = {
  __typename?: 'mutation';
  addReadingListAccess?: {
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  } | null;
};

export type CreateSharingLinkMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  listId: Scalars['String']['input'];
}>;

export type CreateSharingLinkMutation = {
  __typename?: 'mutation';
  createSharingLink?: {
    __typename?: 'SharingLink';
    created: string;
    createdByUserId: string;
    id: string;
    readingListId: string;
    siteId: string;
    validUntil?: string | null;
  } | null;
};

export type ExternalUsersQueryVariables = Exact<{
  externalUserIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExternalUsersQuery = {
  __typename?: 'query';
  externalUsers: Array<{
    __typename?: 'ExternalUser';
    email: string;
    name?: string | null;
    uid: string;
  }>;
};

export type RemoveReadingListAccessMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  listId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type RemoveReadingListAccessMutation = {
  __typename?: 'mutation';
  removeReadingListAccess?: {
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    items: Array<{
      __typename?: 'ReadingListItem';
      id: string;
      itemReference: string;
      title: string;
      description?: string | null;
      created: string;
      modified: string;
      createdByUserName?: string | null;
      source?: string | null;
      author?: string | null;
      publicationDate?: string | null;
      accessible?: boolean | null;
    }>;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  } | null;
};

export type ReadingListsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
  applicationKey: Scalars['String']['input'];
  itemReference?: InputMaybe<Scalars['String']['input']>;
  listId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ReadingListSortParameters>;
}>;

export type ReadingListsQuery = {
  __typename?: 'query';
  readingList: Array<{
    __typename?: 'ReadingList';
    id: string;
    name: string;
    description?: string | null;
    totalDocuments: number;
    created: string;
    modified: string;
    items: Array<{
      __typename?: 'ReadingListItem';
      id: string;
      itemReference: string;
      title: string;
      description?: string | null;
      created: string;
      modified: string;
      createdByUserName?: string | null;
      source?: string | null;
      author?: string | null;
      publicationDate?: string | null;
      accessible?: boolean | null;
    }>;
    userAccess: Array<{
      __typename?: 'ReadingListAccess';
      id: string;
      userName?: string | null;
      userId: string;
      email?: string | null;
      userRole: string;
      created: string;
      modified: string;
    }>;
  }>;
};

export type ExternalUserFragment = {
  __typename?: 'ExternalUser';
  email: string;
  name?: string | null;
  uid: string;
};

export type ReadingListFragment = {
  __typename?: 'ReadingList';
  id: string;
  name: string;
  description?: string | null;
  totalDocuments: number;
  created: string;
  modified: string;
  items: Array<{
    __typename?: 'ReadingListItem';
    id: string;
    itemReference: string;
    title: string;
    description?: string | null;
    created: string;
    modified: string;
    createdByUserName?: string | null;
    source?: string | null;
    author?: string | null;
    publicationDate?: string | null;
    accessible?: boolean | null;
  }>;
  userAccess: Array<{
    __typename?: 'ReadingListAccess';
    id: string;
    userName?: string | null;
    userId: string;
    email?: string | null;
    userRole: string;
    created: string;
    modified: string;
  }>;
};

export type ReadingListBaseFragment = { __typename?: 'ReadingList'; id: string };

export type ReadingListExtendedFragment = {
  __typename?: 'ReadingList';
  id: string;
  name: string;
  description?: string | null;
  totalDocuments: number;
  created: string;
  modified: string;
  userAccess: Array<{
    __typename?: 'ReadingListAccess';
    id: string;
    userName?: string | null;
    userId: string;
    email?: string | null;
    userRole: string;
    created: string;
    modified: string;
  }>;
};

export type ReadingListItemFragment = {
  __typename?: 'ReadingListItem';
  id: string;
  itemReference: string;
  title: string;
  description?: string | null;
  created: string;
  modified: string;
  createdByUserName?: string | null;
  source?: string | null;
  author?: string | null;
  publicationDate?: string | null;
  accessible?: boolean | null;
};

export type SharingLinkFragment = {
  __typename?: 'SharingLink';
  created: string;
  createdByUserId: string;
  id: string;
  readingListId: string;
  siteId: string;
  validUntil?: string | null;
};

export const ExternalUserFragmentDoc = gql`
  fragment ExternalUser on ExternalUser {
    email
    name
    uid
  }
`;
export const ReadingListBaseFragmentDoc = gql`
  fragment ReadingListBase on ReadingList {
    id
  }
`;
export const ReadingListExtendedFragmentDoc = gql`
  fragment ReadingListExtended on ReadingList {
    id
    name
    description
    totalDocuments
    created
    modified
    userAccess {
      id
      userName
      userId
      email
      userRole
      created
      modified
    }
  }
`;
export const ReadingListItemFragmentDoc = gql`
  fragment ReadingListItem on ReadingListItem {
    id
    itemReference
    title
    description
    created
    modified
    createdByUserName
    source
    author
    publicationDate
    accessible
  }
`;
export const ReadingListFragmentDoc = gql`
  fragment ReadingList on ReadingList {
    ...ReadingListBase
    ...ReadingListExtended
    items {
      ...ReadingListItem
    }
  }
  ${ReadingListBaseFragmentDoc}
  ${ReadingListExtendedFragmentDoc}
  ${ReadingListItemFragmentDoc}
`;
export const SharingLinkFragmentDoc = gql`
  fragment SharingLink on SharingLink {
    created
    createdByUserId
    id
    readingListId
    siteId
    validUntil
  }
`;
export const AddDocumentToReadingListDocument = gql`
  mutation AddDocumentToReadingList(
    $siteId: String!
    $listId: String!
    $itemReference: String!
    $itemTitle: String!
  ) {
    addDocumentToReadingList(
      siteId: $siteId
      listId: $listId
      itemReference: $itemReference
      itemTitle: $itemTitle
    ) {
      ...ReadingList
    }
  }
  ${ReadingListFragmentDoc}
`;
export type AddDocumentToReadingListMutationFn = Apollo.MutationFunction<
  AddDocumentToReadingListMutation,
  AddDocumentToReadingListMutationVariables
>;

/**
 * __useAddDocumentToReadingListMutation__
 *
 * To run a mutation, you first call `useAddDocumentToReadingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentToReadingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentToReadingListMutation, { data, loading, error }] = useAddDocumentToReadingListMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      listId: // value for 'listId'
 *      itemReference: // value for 'itemReference'
 *      itemTitle: // value for 'itemTitle'
 *   },
 * });
 */
export function useAddDocumentToReadingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDocumentToReadingListMutation,
    AddDocumentToReadingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddDocumentToReadingListMutation,
    AddDocumentToReadingListMutationVariables
  >(AddDocumentToReadingListDocument, options);
}
export type AddDocumentToReadingListMutationHookResult = ReturnType<
  typeof useAddDocumentToReadingListMutation
>;
export type AddDocumentToReadingListMutationResult =
  Apollo.MutationResult<AddDocumentToReadingListMutation>;
export type AddDocumentToReadingListMutationOptions = Apollo.BaseMutationOptions<
  AddDocumentToReadingListMutation,
  AddDocumentToReadingListMutationVariables
>;
export const CreateReadingListDocument = gql`
  mutation CreateReadingList(
    $siteId: String!
    $name: String!
    $description: String
    $documents: [ReadingListItemParameters!]
  ) {
    createReadingList(
      siteId: $siteId
      name: $name
      description: $description
      documents: $documents
    ) {
      ...ReadingListBase
      ...ReadingListExtended
    }
  }
  ${ReadingListBaseFragmentDoc}
  ${ReadingListExtendedFragmentDoc}
`;
export type CreateReadingListMutationFn = Apollo.MutationFunction<
  CreateReadingListMutation,
  CreateReadingListMutationVariables
>;

/**
 * __useCreateReadingListMutation__
 *
 * To run a mutation, you first call `useCreateReadingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReadingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReadingListMutation, { data, loading, error }] = useCreateReadingListMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useCreateReadingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReadingListMutation,
    CreateReadingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReadingListMutation, CreateReadingListMutationVariables>(
    CreateReadingListDocument,
    options,
  );
}
export type CreateReadingListMutationHookResult = ReturnType<typeof useCreateReadingListMutation>;
export type CreateReadingListMutationResult = Apollo.MutationResult<CreateReadingListMutation>;
export type CreateReadingListMutationOptions = Apollo.BaseMutationOptions<
  CreateReadingListMutation,
  CreateReadingListMutationVariables
>;
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($siteId: String!, $listId: String!, $itemReference: String!) {
    deleteDocument(siteId: $siteId, listId: $listId, itemReference: $itemReference) {
      ...ReadingList
    }
  }
  ${ReadingListFragmentDoc}
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      listId: // value for 'listId'
 *      itemReference: // value for 'itemReference'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(
    DeleteDocumentDocument,
    options,
  );
}
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const DeleteReadingListDocument = gql`
  mutation DeleteReadingList($siteId: String!, $listId: String!) {
    deleteReadingList(siteId: $siteId, listId: $listId) {
      ...ReadingListBase
    }
  }
  ${ReadingListBaseFragmentDoc}
`;
export type DeleteReadingListMutationFn = Apollo.MutationFunction<
  DeleteReadingListMutation,
  DeleteReadingListMutationVariables
>;

/**
 * __useDeleteReadingListMutation__
 *
 * To run a mutation, you first call `useDeleteReadingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReadingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReadingListMutation, { data, loading, error }] = useDeleteReadingListMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useDeleteReadingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReadingListMutation,
    DeleteReadingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReadingListMutation, DeleteReadingListMutationVariables>(
    DeleteReadingListDocument,
    options,
  );
}
export type DeleteReadingListMutationHookResult = ReturnType<typeof useDeleteReadingListMutation>;
export type DeleteReadingListMutationResult = Apollo.MutationResult<DeleteReadingListMutation>;
export type DeleteReadingListMutationOptions = Apollo.BaseMutationOptions<
  DeleteReadingListMutation,
  DeleteReadingListMutationVariables
>;
export const UpdateReadingListDetailsDocument = gql`
  mutation UpdateReadingListDetails(
    $siteId: String!
    $listId: String!
    $listName: String!
    $listDescription: String!
  ) {
    updateReadingListDetails(
      siteId: $siteId
      listId: $listId
      listName: $listName
      listDescription: $listDescription
    ) {
      ...ReadingListExtended
    }
  }
  ${ReadingListExtendedFragmentDoc}
`;
export type UpdateReadingListDetailsMutationFn = Apollo.MutationFunction<
  UpdateReadingListDetailsMutation,
  UpdateReadingListDetailsMutationVariables
>;

/**
 * __useUpdateReadingListDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateReadingListDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadingListDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadingListDetailsMutation, { data, loading, error }] = useUpdateReadingListDetailsMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      listId: // value for 'listId'
 *      listName: // value for 'listName'
 *      listDescription: // value for 'listDescription'
 *   },
 * });
 */
export function useUpdateReadingListDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReadingListDetailsMutation,
    UpdateReadingListDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReadingListDetailsMutation,
    UpdateReadingListDetailsMutationVariables
  >(UpdateReadingListDetailsDocument, options);
}
export type UpdateReadingListDetailsMutationHookResult = ReturnType<
  typeof useUpdateReadingListDetailsMutation
>;
export type UpdateReadingListDetailsMutationResult =
  Apollo.MutationResult<UpdateReadingListDetailsMutation>;
export type UpdateReadingListDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateReadingListDetailsMutation,
  UpdateReadingListDetailsMutationVariables
>;
export const AcceptSharingLinkDocument = gql`
  mutation AcceptSharingLink($siteId: String!, $sharingLinkId: String!) {
    acceptSharingLink(siteId: $siteId, sharingLinkId: $sharingLinkId) {
      ...ReadingList
    }
  }
  ${ReadingListFragmentDoc}
`;
export type AcceptSharingLinkMutationFn = Apollo.MutationFunction<
  AcceptSharingLinkMutation,
  AcceptSharingLinkMutationVariables
>;

/**
 * __useAcceptSharingLinkMutation__
 *
 * To run a mutation, you first call `useAcceptSharingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSharingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSharingLinkMutation, { data, loading, error }] = useAcceptSharingLinkMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      sharingLinkId: // value for 'sharingLinkId'
 *   },
 * });
 */
export function useAcceptSharingLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptSharingLinkMutation,
    AcceptSharingLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptSharingLinkMutation, AcceptSharingLinkMutationVariables>(
    AcceptSharingLinkDocument,
    options,
  );
}
export type AcceptSharingLinkMutationHookResult = ReturnType<typeof useAcceptSharingLinkMutation>;
export type AcceptSharingLinkMutationResult = Apollo.MutationResult<AcceptSharingLinkMutation>;
export type AcceptSharingLinkMutationOptions = Apollo.BaseMutationOptions<
  AcceptSharingLinkMutation,
  AcceptSharingLinkMutationVariables
>;
export const ReadingListsPluginDocument = gql`
  query ReadingListsPlugin($siteId: String!, $applicationKey: String!, $itemReference: String) {
    readingList(siteId: $siteId, applicationKey: $applicationKey, itemReference: $itemReference) {
      id
    }
  }
`;

/**
 * __useReadingListsPluginQuery__
 *
 * To run a query within a React component, call `useReadingListsPluginQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadingListsPluginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadingListsPluginQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      applicationKey: // value for 'applicationKey'
 *      itemReference: // value for 'itemReference'
 *   },
 * });
 */
export function useReadingListsPluginQuery(
  baseOptions: Apollo.QueryHookOptions<ReadingListsPluginQuery, ReadingListsPluginQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReadingListsPluginQuery, ReadingListsPluginQueryVariables>(
    ReadingListsPluginDocument,
    options,
  );
}
export function useReadingListsPluginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadingListsPluginQuery,
    ReadingListsPluginQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReadingListsPluginQuery, ReadingListsPluginQueryVariables>(
    ReadingListsPluginDocument,
    options,
  );
}
export type ReadingListsPluginQueryHookResult = ReturnType<typeof useReadingListsPluginQuery>;
export type ReadingListsPluginLazyQueryHookResult = ReturnType<
  typeof useReadingListsPluginLazyQuery
>;
export type ReadingListsPluginQueryResult = Apollo.QueryResult<
  ReadingListsPluginQuery,
  ReadingListsPluginQueryVariables
>;
export const AddReadingListAccessDocument = gql`
  mutation AddReadingListAccess(
    $siteId: String!
    $listId: String!
    $userAccess: ReadingListAccessParameters!
  ) {
    addReadingListAccess(siteId: $siteId, listId: $listId, userAccess: $userAccess) {
      ...ReadingListExtended
    }
  }
  ${ReadingListExtendedFragmentDoc}
`;
export type AddReadingListAccessMutationFn = Apollo.MutationFunction<
  AddReadingListAccessMutation,
  AddReadingListAccessMutationVariables
>;

/**
 * __useAddReadingListAccessMutation__
 *
 * To run a mutation, you first call `useAddReadingListAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReadingListAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReadingListAccessMutation, { data, loading, error }] = useAddReadingListAccessMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      listId: // value for 'listId'
 *      userAccess: // value for 'userAccess'
 *   },
 * });
 */
export function useAddReadingListAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReadingListAccessMutation,
    AddReadingListAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddReadingListAccessMutation, AddReadingListAccessMutationVariables>(
    AddReadingListAccessDocument,
    options,
  );
}
export type AddReadingListAccessMutationHookResult = ReturnType<
  typeof useAddReadingListAccessMutation
>;
export type AddReadingListAccessMutationResult =
  Apollo.MutationResult<AddReadingListAccessMutation>;
export type AddReadingListAccessMutationOptions = Apollo.BaseMutationOptions<
  AddReadingListAccessMutation,
  AddReadingListAccessMutationVariables
>;
export const CreateSharingLinkDocument = gql`
  mutation CreateSharingLink($siteId: String!, $listId: String!) {
    createSharingLink(siteId: $siteId, listId: $listId) {
      ...SharingLink
    }
  }
  ${SharingLinkFragmentDoc}
`;
export type CreateSharingLinkMutationFn = Apollo.MutationFunction<
  CreateSharingLinkMutation,
  CreateSharingLinkMutationVariables
>;

/**
 * __useCreateSharingLinkMutation__
 *
 * To run a mutation, you first call `useCreateSharingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharingLinkMutation, { data, loading, error }] = useCreateSharingLinkMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useCreateSharingLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSharingLinkMutation,
    CreateSharingLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSharingLinkMutation, CreateSharingLinkMutationVariables>(
    CreateSharingLinkDocument,
    options,
  );
}
export type CreateSharingLinkMutationHookResult = ReturnType<typeof useCreateSharingLinkMutation>;
export type CreateSharingLinkMutationResult = Apollo.MutationResult<CreateSharingLinkMutation>;
export type CreateSharingLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateSharingLinkMutation,
  CreateSharingLinkMutationVariables
>;
export const ExternalUsersDocument = gql`
  query ExternalUsers($externalUserIds: [String!], $searchPhrase: String, $userId: String) {
    externalUsers(externalUserIds: $externalUserIds, searchPhrase: $searchPhrase, userId: $userId) {
      ...ExternalUser
    }
  }
  ${ExternalUserFragmentDoc}
`;

/**
 * __useExternalUsersQuery__
 *
 * To run a query within a React component, call `useExternalUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalUsersQuery({
 *   variables: {
 *      externalUserIds: // value for 'externalUserIds'
 *      searchPhrase: // value for 'searchPhrase'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useExternalUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ExternalUsersQuery, ExternalUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalUsersQuery, ExternalUsersQueryVariables>(
    ExternalUsersDocument,
    options,
  );
}
export function useExternalUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExternalUsersQuery, ExternalUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalUsersQuery, ExternalUsersQueryVariables>(
    ExternalUsersDocument,
    options,
  );
}
export type ExternalUsersQueryHookResult = ReturnType<typeof useExternalUsersQuery>;
export type ExternalUsersLazyQueryHookResult = ReturnType<typeof useExternalUsersLazyQuery>;
export type ExternalUsersQueryResult = Apollo.QueryResult<
  ExternalUsersQuery,
  ExternalUsersQueryVariables
>;
export const RemoveReadingListAccessDocument = gql`
  mutation RemoveReadingListAccess($siteId: String!, $listId: String!, $userId: String!) {
    removeReadingListAccess(siteId: $siteId, listId: $listId, userId: $userId) {
      ...ReadingList
    }
  }
  ${ReadingListFragmentDoc}
`;
export type RemoveReadingListAccessMutationFn = Apollo.MutationFunction<
  RemoveReadingListAccessMutation,
  RemoveReadingListAccessMutationVariables
>;

/**
 * __useRemoveReadingListAccessMutation__
 *
 * To run a mutation, you first call `useRemoveReadingListAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReadingListAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReadingListAccessMutation, { data, loading, error }] = useRemoveReadingListAccessMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      listId: // value for 'listId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveReadingListAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReadingListAccessMutation,
    RemoveReadingListAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveReadingListAccessMutation,
    RemoveReadingListAccessMutationVariables
  >(RemoveReadingListAccessDocument, options);
}
export type RemoveReadingListAccessMutationHookResult = ReturnType<
  typeof useRemoveReadingListAccessMutation
>;
export type RemoveReadingListAccessMutationResult =
  Apollo.MutationResult<RemoveReadingListAccessMutation>;
export type RemoveReadingListAccessMutationOptions = Apollo.BaseMutationOptions<
  RemoveReadingListAccessMutation,
  RemoveReadingListAccessMutationVariables
>;
export const ReadingListsDocument = gql`
  query ReadingLists(
    $siteId: String!
    $applicationKey: String!
    $itemReference: String
    $listId: String
    $sort: ReadingListSortParameters
  ) {
    readingList(
      siteId: $siteId
      applicationKey: $applicationKey
      itemReference: $itemReference
      listId: $listId
      sort: $sort
    ) {
      ...ReadingList
    }
  }
  ${ReadingListFragmentDoc}
`;

/**
 * __useReadingListsQuery__
 *
 * To run a query within a React component, call `useReadingListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadingListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadingListsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      applicationKey: // value for 'applicationKey'
 *      itemReference: // value for 'itemReference'
 *      listId: // value for 'listId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadingListsQuery(
  baseOptions: Apollo.QueryHookOptions<ReadingListsQuery, ReadingListsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReadingListsQuery, ReadingListsQueryVariables>(
    ReadingListsDocument,
    options,
  );
}
export function useReadingListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReadingListsQuery, ReadingListsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReadingListsQuery, ReadingListsQueryVariables>(
    ReadingListsDocument,
    options,
  );
}
export type ReadingListsQueryHookResult = ReturnType<typeof useReadingListsQuery>;
export type ReadingListsLazyQueryHookResult = ReturnType<typeof useReadingListsLazyQuery>;
export type ReadingListsQueryResult = Apollo.QueryResult<
  ReadingListsQuery,
  ReadingListsQueryVariables
>;
