import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputField } from '../FormFields/InputField';

/**
 * The form for creating a new reading list.
 * @returns {JSX.Element} - The rendered component.
 */
const NewReadingListForm = () => {
  useLingui();

  return (
    <>
      <InputField
        key="newReadingListFormName"
        autoComplete="off"
        label={t`Naam`}
        name="name"
        placeholder={t`Naam van de nieuwe leeslijst`}
        spaceAfter={4}
        testId="newReadingListFormName"
      />
      <InputField
        key="newReadingListFormDescription"
        autoComplete="off"
        label={t`Beschrijving`}
        name="description"
        placeholder={t`Beschrijving van de nieuwe leeslijst`}
        testId="newReadingListFormDescription"
        type="textarea"
      />
    </>
  );
};

export { NewReadingListForm };
