import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useAlertsClient } from '../hooks/useAlertsClient';
export const AlertsContext = React.createContext(undefined);
export const AlertsProvider = ({ accessToken, alertsOverviewUrl, children, formComponent, generateName, graphqlUrl, isLoggedIn, isAnonymousUser, profile, renderDescription, renderParameters, }) => {
    const client = useAlertsClient({
        graphqlUrl,
        accessToken,
    });
    const context = useMemo(() => ({
        alertsOverviewUrl,
        client,
        formComponent,
        generateName,
        isLoggedIn,
        isAnonymousUser,
        profile,
        renderDescription,
        renderParameters,
    }), [
        alertsOverviewUrl,
        client,
        formComponent,
        generateName,
        isLoggedIn,
        isAnonymousUser,
        profile,
        renderDescription,
        renderParameters,
    ]);
    /**
     * We can't use ApolloProvider because there can only exist one per application.
     * We can however use the `useQuery` function outside ApolloProvider (or inside
     * an unknown ApolloProvider) by using the `client` parameter.
     */
    return _jsx(AlertsContext.Provider, { value: context, children: children });
};
