import type { SearchFacetProps } from '@elseu/sdu-titan-search';
import { array, object, string } from 'yup';

/**
 * Generates a validation schema for the alert form.
 *
 * @param {string} typeName - The name of the type facet.
 * @param {SearchFacetProps[]} [facets] - The list of facets to include in the schema.
 * @returns {object} The validation schema for the alert form.
 */
export const getAlertFormSchema = (typeName: string, facets?: SearchFacetProps[]) => {
  return object({
    term: string().trim().max(255), // Term field: optional, trimmed, maximum length of 255 characters
    customName: string().trim().max(255), // Custom name field: optional, trimmed, maximum length of 255 characters
    facets: object({
      [typeName]: array().of(string().trim().min(1).required()), // Type facet: required array of non-empty trimmed strings
      ...facets?.reduce(
        (acc, facet: SearchFacetProps) => ({
          ...acc,
          [facet.name]: array().of(string().trim().min(1).required()), // Other facets: required array of non-empty trimmed strings
        }),
        {},
      ),
    }).required(), // Facets object is required
  });
};

/**
 * Type representing the values of the alert form.
 */
export type AlertFormValues = {
  term: string;
  customName: string;
  facets: {
    [key: string]: string[];
  };
};
