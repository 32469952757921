import { AlertsProvider as AlertsProviderComponent } from '@elseu/sdu-alert-react';
import { useAuthentication } from '@repo/auth';
import type * as React from 'react';

import { useSiteConfig } from '../../../context/SiteConfigProvider';
import { useIsAnonymousUser } from '../../../hooks';
import { AlertForm } from '../components/AlertForm/AlertForm';
import { generateName } from '../helpers/generateName';
import { renderDescription } from '../helpers/renderDescription';
import { renderParameters } from '../helpers/renderParameters';

interface AlertsProviderProps {
  children?: React.ReactNode;
}
export const AlertsProvider = ({ children }: AlertsProviderProps) => {
  const { alertsGraphqlUrl, applicationKey, urls } = useSiteConfig();
  const { isLoggedIn, getAccessToken } = useAuthentication();
  const isAnonymousUser = useIsAnonymousUser();

  return (
    <AlertsProviderComponent
      accessToken={getAccessToken}
      alertsOverviewUrl={urls.alertsUrl}
      formComponent={AlertForm}
      generateName={generateName}
      graphqlUrl={alertsGraphqlUrl}
      isAnonymousUser={isAnonymousUser}
      isLoggedIn={isLoggedIn}
      profile={applicationKey}
      renderDescription={renderDescription}
      renderParameters={renderParameters}
    >
      {children}
    </AlertsProviderComponent>
  );
};
