import { BookmarkAddFilledIcon, BookmarkAddOutlineIcon, DockIcon } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export interface ReadingListsPluginDockIconItemProps {
  isFilled?: boolean;
  onClick: () => void;
}

const ReadingListsPluginDockIconItem = ({
  isFilled,
  onClick,
}: ReadingListsPluginDockIconItemProps) => {
  useLingui();
  return (
    <DockIcon
      icon={isFilled ? <BookmarkAddFilledIcon color="primary60" /> : <BookmarkAddOutlineIcon />}
      label={t`Opslaan`}
      testId="dockIconItemReadingLists"
      onClick={onClick}
    />
  );
};

export { ReadingListsPluginDockIconItem };
