import {
  BookIcon,
  BoxAltIcon,
  CardsIcon,
  ChatAltIcon,
  ChatIcon,
  ChevronRightIcon,
  HelpIcon,
  HomeIcon,
  LibraryIcon,
  MagazineIcon,
  NewsIcon,
  NtfrIcon,
  PeopleIcon,
  PersonIcon,
  StarOutlineIcon,
} from '@elseu/sdu-titan';

const icons = {
  BookIcon,
  ChevronRightIcon,
  BoxAltIcon,
  HelpIcon,
  HomeIcon,
  LibraryIcon,
  MagazineIcon,
  NewsIcon,
  NtfrIcon,
  PeopleIcon,
  PersonIcon,
  ChatIcon,
  ChatAltIcon,
  CardsIcon,
  StarOutlineIcon,
} as const;

export default icons;
