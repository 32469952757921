import type { SearchFacetProps } from '@elseu/sdu-titan-search';
import type { FilterStateProviderProps } from '@elseu/sdu-titan-search/dist/types/context/filters/types';

import type { SearchConfig } from '../../../types/SearchConfig';
import type { AlertParameters } from '../types/AlertParameters';
import { IGNORE_FILTERS } from './IGNORE_FILTERS';

const getSearchFacet = (key: string, preset?: SearchFacetProps) => ({
  [key]: {
    reset: preset?.options.reset || true,
    ...(preset?.options.bucketsDepth ? { bucketsDepth: preset.options.bucketsDepth } : {}),
  },
});

const transformAlertFormToSearchFacets = (
  key: string,
  facetsPreset: Omit<SearchConfig, 'searchTabs'>,
): FilterStateProviderProps['facets'] => {
  const preset = facetsPreset.facets.find((facet) => facet.name === key);

  if (key === facetsPreset.tabsFacet) {
    return getSearchFacet(key, preset);
  }

  return getSearchFacet(key, preset);
};

const getAlertParametersFacetsToSearchFacets = (
  facets: AlertParameters['facets'],
  facetsPreset: Omit<SearchConfig, 'searchTabs'>,
): FilterStateProviderProps['facets'] => {
  return Object.keys(facets).reduce(
    (all: FilterStateProviderProps['facets'], key: string) => ({
      ...all,
      ...(!IGNORE_FILTERS.includes(key) ? transformAlertFormToSearchFacets(key, facetsPreset) : {}),
    }),
    {},
  );
};

export { getAlertParametersFacetsToSearchFacets };
