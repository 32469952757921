const prependHostToUrls = (urls) => {
    if (typeof window === 'undefined')
        return urls;
    const host = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    return Object.keys(urls).reduce((all, key) => {
        const url = urls[key];
        if (!url)
            return all;
        const urlParts = url.split('/').filter(Boolean);
        return Object.assign(Object.assign({}, all), { [key]: [host, ...urlParts].join('/') });
    }, urls);
};
export { prependHostToUrls };
