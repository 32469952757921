import { useEffect } from 'react';

const ContentRoutingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  /**
   * Completely disable scroll restoration as we want to handle this ourselves
   */
  useEffect(() => {
    if (typeof window !== 'undefined') window.history.scrollRestoration = 'manual';

    return () => {
      window.history.scrollRestoration = 'auto';
    };
  }, []);

  return <>{children}</>;
};

export { ContentRoutingProvider };
