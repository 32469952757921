import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { useMemo } from 'react';
import { getHeaders } from '../helpers/getRequestHeaders';
export const useContentExportClient = ({ graphqlUrl, fetch, accessToken, }) => {
    const httpLink = useMemo(() => new HttpLink({
        uri: graphqlUrl,
        fetch,
    }), [graphqlUrl, fetch]);
    const wsLink = useMemo(() => typeof window !== 'undefined' && graphqlUrl
        ? new GraphQLWsLink(createClient({
            url: `${graphqlUrl.replace(/^http/i, 'ws')}/subscriptions`,
            lazy: true,
            lazyCloseTimeout: 5000,
        }))
        : undefined, [graphqlUrl]);
    const authLink = useMemo(() => setContext((_, { headers }) => {
        if (!accessToken || typeof accessToken === 'string') {
            return getHeaders(headers, accessToken);
        }
        return accessToken().then((token) => getHeaders(headers, token));
    }), [accessToken]);
    const httpLinkWithAuth = useMemo(() => authLink.concat(httpLink), [authLink, httpLink]);
    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const link = useMemo(() => wsLink
        ? split(({ query }) => {
            const definition = getMainDefinition(query);
            return (definition.kind === 'OperationDefinition' && definition.operation === 'subscription');
        }, wsLink, httpLinkWithAuth)
        : httpLinkWithAuth, [httpLinkWithAuth, wsLink]);
    return useMemo(() => graphqlUrl
        ? new ApolloClient({
            ssrMode: typeof window === 'undefined',
            link,
            cache: new InMemoryCache(),
        })
        : undefined, [link, graphqlUrl]);
};
