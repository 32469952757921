import { getDataLayer, pushToDataLayer } from '@repo/analytics';

import { type CombinedAnalyticsPlugin, isAnalyticsEvent } from './types';

export const addToDataLayerPlugin = (): CombinedAnalyticsPlugin => {
  return {
    name: 'addToDataLayerPlugin',
    initializeStart: ({ abort }) => {
      if (typeof window === 'undefined') {
        return abort('Cancel because server side rendering');
      }
    },
    initialize: () => getDataLayer(),
    track: ({ payload }) => {
      const { event, userId, properties, ...rest } = payload;
      if (!isAnalyticsEvent(event)) return;

      const dataLayerPayload = { event, ...rest, ...properties };
      if (userId) dataLayerPayload.userId = userId;
      pushToDataLayer(dataLayerPayload);
    },
  };
};
