import type { SelectProps } from '@elseu/sdu-titan';
import type { FacetContext } from '@elseu/sdu-titan-search';
import { t } from '@lingui/macro';
import { useMemo } from 'react';

import { useSiteConfig } from '../../../../../../context/SiteConfigProvider';

/**
 * Type for transforming form values for the type field.
 */
export type TypeFormValueTransform = {
  input: (value: string[]) => string;
  output: (value: string) => string[];
};

/**
 * Interface for the return value of useTypeField.
 */
interface IUseTypeField {
  facet?: FacetContext;
  options?: SelectProps['options'];
  transform: TypeFormValueTransform;
}

/**
 * Constant for representing the "all" value.
 */
const ALL_VALUE = 'all';

/**
 * Tabs to exclude from the type field.
 */
const excludeTabIds = ['xposi'];

/**
 * Custom hook to manage type field options and their corresponding context.
 *
 * @returns {IUseTypeField} The type field options, value transformation functions, and the facet context.
 */
const useTypeField = (): IUseTypeField => {
  const { searchTabs } = useSiteConfig();

  /**
   * Value transformation functions for the type field.
   */
  const transform: TypeFormValueTransform = useMemo(
    () => ({
      input: (values: string[] | undefined) => (!values?.length ? ALL_VALUE : values.join('-')),
      output: (value: string) => (value === ALL_VALUE ? [] : value.split('-')),
    }),
    [],
  );

  /**
   * Generate options for the type field based on search tabs configuration.
   */
  const options = useMemo(
    () =>
      searchTabs
        .filter(({ id }) => !excludeTabIds.includes(id))
        .map(({ label, keys }) => ({
          label,
          value: keys.join('-'),
        })),
    [searchTabs],
  );

  /**
   * Default option representing "all" values.
   */
  const allOption = { label: t`Alles`, value: ALL_VALUE };

  return {
    options: [allOption, ...options],
    transform,
  };
};

export { useTypeField };
