import type { I18n, MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';

export type LabelMap = Record<string, MessageDescriptor>;
export type LabelMapTranslated = Record<string, string>;

export const tabLabelMap: LabelMap = {
  all: msg`Alles`,
  article: msg`Artikelen`,
  blogs: msg`Blogs`,
  books: msg`Boeken`,
  cmt: msg`Commentaar`,
  eu: msg`EU`,
  international: msg`Internationaal`,
  jur: msg`Jurisprudentie`,
  law: msg`Wet- en regelgeving`,
  news: msg`Nieuws`,
  op: msg`Officiële publicaties`,
  other: msg`Overig`,
  pn: msg`Practice Notes`,
  reference: msg`Naslag`,
  tools: msg`Tools`,
  verdiepingen: msg`Verdiepingen`,
};

export const facetLabelMap: LabelMap = {
  authorities: msg`Instantie`,
  editionnumber: msg`Editie`,
  legalarea: msg`Rechtsgebied`,
  parts: msg`Delen`,
  pub: msg`Publicatie`,
  pubyear: msg`Publicatiejaar`,
  rubric: msg`Rubriek`,
  rulingyear: msg`Uitspraakjaar`,
  subject: msg`Onderwerp`,
  theme: msg`Thema`,
  type: msg`Soort`,
  year: msg`Jaar`,
};

/**
 * Returns a dictionary of tab / facet ids and corresponding (translated) labels
 * @param {I18n} i18n An active i18n instance
 * @param {LabelMap} labelMap A dictionary of ids and corresponding message descriptors
 * @returns {LabelMapTranslated} Dictionary of ids and corresponding translated labels following the active locale
 */
export const getTranslatedLabelDict = (i18n: I18n, labelMap: LabelMap): LabelMapTranslated =>
  Object.fromEntries(Object.entries(labelMap).map(([id, msg]) => [id, i18n._(msg)]));
