import { t } from '@lingui/macro';
import emojiRegexCreator from 'emoji-regex';
import type { InferType } from 'yup';
import { object, string } from 'yup';

export const readingListFormSchema = () => {
  const emojiRegex = emojiRegexCreator();

  return object({
    name: string()
      .trim()
      .min(1, t`De naam moet minimaal 1 teken bevatten`)
      .max(200, t`De naam mag maximaal 200 tekens bevatten`)
      .test('has-emoji', t`Emojis zijn niet toegestaan`, (value) => !value?.match(emojiRegex))
      .required(t`Voer een geldige naam in`),
    description: string()
      .trim()
      .max(500, t`De beschrijving mag maximaal 500 tekens bevatten`)
      .test('has-emoji', t`Emojis zijn niet toegestaan`, (value) => !value?.match(emojiRegex))
      .nullable(),
  });
};

export type ReadingListFormValues = InferType<ReturnType<typeof readingListFormSchema>>;
