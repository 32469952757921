/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { FilterStateProviderProps } from '@elseu/sdu-titan-search/dist/types/context/filters/types';

import type { SearchConfig } from '../../../types/SearchConfig';
import type { AlertFormValues } from '../components/AlertForm/AlertFormSchema';
import { type AlertParameters } from '../types/AlertParameters';
import { getAlertParametersFacetsToSearchFacets } from './getAlertParametersFacetsToSearchFacets';

const transformSearchFacetsToFlatArray = (
  searchFacets: FilterStateProviderProps['facets'],
  defaultParameters: AlertParameters,
) =>
  Object.keys(searchFacets).reduce(
    (all, name) => ({
      ...all,
      [name]: defaultParameters.facets[name]?.map((facet) => facet.value).flat() || [],
    }),
    {},
  );

const getAlertParametersToAlertFormValues = ({
  defaultCustomName,
  defaultParameters,
  facetsPreset,
}: {
  defaultParameters: AlertParameters;
  defaultCustomName: string;
  facetsPreset: Omit<SearchConfig, 'searchTabs'>;
}): AlertFormValues => {
  const { term, facets } = defaultParameters;

  const searchFacets = getAlertParametersFacetsToSearchFacets(facets, facetsPreset);
  const facetsAsFlatArray = transformSearchFacetsToFlatArray(searchFacets, defaultParameters);

  return {
    term: term || '',
    customName: defaultCustomName,
    facets: facetsAsFlatArray,
  };
};

export { getAlertParametersToAlertFormValues };
