import { useAuthentication } from '@repo/auth';
import * as Sentry from '@sentry/nextjs';
import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { useSiteConfig } from '../../context';
import { usePingAnalytics } from '../../helpers/analytics/usePingAnalytics';

const TrackLoginEvents = () => {
  const { applicationKey } = useSiteConfig();
  const { trackUserInitiatedPingLogin, trackPingKeyAvailable, trackUserIdAvailable } =
    usePingAnalytics();
  const { isInitialized, isSessionExpired, isLoggedIn, claims } = useAuthentication();

  const isPrevLoggedIn = usePrevious<boolean>(isLoggedIn);
  const [isLoginButtonClicked, setLoginButtonClicked] = useState(false);

  useEffect(() => {
    if (!isInitialized || isSessionExpired) return;

    trackPingKeyAvailable(claims);
  }, [claims, isInitialized, isSessionExpired, trackPingKeyAvailable]);

  useEffect(() => {
    if (!isLoginButtonClicked || !isInitialized || !claims || isSessionExpired) return;

    trackUserInitiatedPingLogin(claims);
  }, [claims, isInitialized, isLoginButtonClicked, isSessionExpired, trackUserInitiatedPingLogin]);

  useEffect(() => {
    if (typeof isPrevLoggedIn === 'boolean' && !isPrevLoggedIn && isLoggedIn) {
      setLoginButtonClicked(true);
    }
  }, [isLoggedIn, isPrevLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn || !claims?.sub) {
      return;
    }
    trackUserIdAvailable({
      applicationKey,
      sub: claims.sub,
    });
  }, [isLoggedIn, claims, trackUserIdAvailable, applicationKey]);

  useEffect(() => {
    if (!claims) return;

    Sentry.setUser(claims);
  }, [claims]);

  return null;
};

export { TrackLoginEvents };
