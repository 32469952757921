import { msg } from '@lingui/macro';

/**
 * The user roles that can be assigned to a user.
 */
export enum UserRole {
  Administrator = 'Administrator',
  Editor = 'Editor',
  Reader = 'Reader',
}

/**
 * The labels and descriptions for the user roles.
 */
export const userRoles = {
  Reader: {
    label: msg`Kan kijken`,
    description: msg`De persoon kan alleen documenten in de leeslijst bekijken.`,
  },
  Editor: {
    label: msg`Kan bewerken`,
    description: msg`De persoon kan de leeslijst bewerken en documenten toevoegen en verwijderen.`,
  },
  Administrator: {
    label: msg`Beheerder`,
    description: msg`De persoon kan de leeslijst delen, rechten toekennen en verwijderen.`,
  },
};
