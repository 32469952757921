import type { InPageSearchPlugin } from '@elseu/sdu-titan';

import { useContentRendering } from '../../context/ContentRenderingProvider';
import {
  type ContentPartHit,
  InPageSearchDocument,
  type InPageSearchQuery,
  type InPageSearchQueryVariables,
} from '../../generated/content/graphql';

export type InPageSearchRecord = {
  partId: string;
  indexInPart: number;
};

const useInPageSearchPlugin = (documentKey: string): InPageSearchPlugin<InPageSearchRecord> => {
  const { client, applicationKey } = useContentRendering();

  const inPageSearch = async (text: string) =>
    await client?.query<InPageSearchQuery, InPageSearchQueryVariables>({
      query: InPageSearchDocument,
      variables: {
        documentKey,
        query: { text },
        applicationKey,
      },
    });

  const onSearch = async (text: string) => {
    const response = await inPageSearch(text);

    const data = response?.data.inPageSearch?.reduce(
      (all: InPageSearchRecord[], hit: ContentPartHit) => {
        const indices = Array.from(Array(hit.totalResults).keys());
        return [
          ...all,
          ...indices.map((index) => ({
            partId: hit.partId,
            indexInPart: index,
          })),
        ];
      },
      [],
    );

    return data || [];
  };

  return {
    id: 'ContentParts',
    onSearch,
  };
};

export { useInPageSearchPlugin };
