import { withTitan } from '@elseu/sdu-titan';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { AnalyticsPageViewTracker } from '../components/AnalyticsPageViewTracker/AnalyticsPageViewTracker';
import { ErrorBoundary } from '../components/Error/ErrorBoundary/ErrorBoundary';
import { ErrorBoundaryPageFallback } from '../components/Error/ErrorBoundaryPageFallback/ErrorBoundaryPageFallback';
import { KonamiTools } from '../components/Konami/KonamiTools';
import { Navigation } from '../components/Navigation/Navigation';
import { OfflineMessage } from '../components/OfflineMessage/OfflineMessage';
import { PopStateHandler } from '../components/PopStateHandler/PopStateHandler';
import { useSiteConfig } from '../context/SiteConfigProvider';
import { sentryDialogOptions } from '../helpers/sentryDialogOptions';
import { Consents } from '../hooks/useConsents';

const Main = styled.main`
  display: block;
`;

const OnboardingSlides = dynamic(
  () =>
    import(
      /* webpackChunkName: "OnboardingSlides" */ '../components/Onboarding/OnboardingSlidesContainer'
    ),
  {
    ssr: false,
  },
);

const OnboardingTooltips = dynamic(
  () =>
    import(
      /* webpackChunkName: "OnboardingTooltips" */ '../components/Onboarding/OnboardingTooltipsContainer'
    ),
  {
    ssr: false,
  },
);

const TooltipsContainer = dynamic(
  () =>
    import(
      /* webpackChunkName: "TooltipsContainer" */ '../components/TooltipsContainer/TooltipsContainer'
    ),
  {
    ssr: false,
  },
);

const AuthLoadingIndicator = dynamic(
  () =>
    import(
      /* webpackChunkName: "AuthLoadingIndicator" */ '../components/AuthLoadingIndicator/AuthLoadingIndicator'
    ),
  { ssr: false },
);

export const BaseLayout = withTitan(
  ({ children, testId = 'baseLayout' }) => {
    const { featureFlags, isBot } = useSiteConfig();

    return (
      <div data-test-id={testId}>
        <Navigation />
        <Main id="content">
          <ErrorBoundary
            showDialog
            dialogOptions={sentryDialogOptions()}
            fallback={<ErrorBoundaryPageFallback />}
          >
            {featureFlags.WITH_FEATURE_FLAGS && <KonamiTools />}
            <OfflineMessage />
            {featureFlags.WITH_ONBOARDING && <OnboardingSlides />}
            {featureFlags.WITH_ONBOARDING_TOOLTIPS && <OnboardingTooltips />}
            {children}
          </ErrorBoundary>
        </Main>
        <Consents />
        {!isBot && <AnalyticsPageViewTracker />}
        {!isBot && !featureFlags.WITH_PUBLIC_SITE && <AuthLoadingIndicator />}
        <PopStateHandler />
        <TooltipsContainer />
      </div>
    );
  },
  { name: 'BaseLayout' },
);
