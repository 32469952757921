import { useFormContext } from 'react-hook-form';

import { useSiteConfig } from '../../../../../../context/SiteConfigProvider';
import type { AlertFormValues } from '../../AlertFormSchema';

/**
 * Custom hook to determine if a facet field should be shown based on the selected tabsFacet.
 *
 * @param {boolean | string[]} display - The display condition which can be a boolean or an array of strings.
 * @returns {boolean | undefined} A boolean indicating if the facet field should be shown, or undefined if no tabsFacet is selected.
 */
const useShouldShowFacetField = (display: boolean | string[]) => {
  const { facetsPreset } = useSiteConfig();
  const { watch } = useFormContext<AlertFormValues>();

  // Watch the tabsFacet field in the form
  const tabsFacetValues = watch(`facets.${facetsPreset.tabsFacet}`) as string[] | undefined;

  // If there are no selected tabsFacet values, return undefined
  if (!tabsFacetValues?.length) return;

  // If display is a boolean, return it directly
  if (typeof display === 'boolean') return display;

  // Check if any of the tabsFacet values match the display array
  return tabsFacetValues.some((item) => display.includes(item));
};

export { useShouldShowFacetField };
