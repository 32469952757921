const Lefebvre = (
  <svg fill="none" height="24" viewBox="0 0 161 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1192 0H0V28.7269H3.18741V3.27569H19.1192V0Z" fill="#001978" />
    <path d="M28.6814 3.27698V28.7256H12.747V32H31.8688V3.27698H28.6814Z" fill="#001978" />
    <path d="M9.55994 9.7998H22.3096V12.3835H9.55994V9.7998Z" fill="#001978" />
    <path d="M22.3096 19.2543H9.55994V21.8354H22.3096V19.2543Z" fill="#001978" />
    <path d="M52.6578 21.7433V24.5204H41.5493V6.97473H44.4796V21.7433H52.6578Z" fill="#001978" />
    <path
      d="M58.0874 9.75186V14.1948H65.0283V16.9719H58.0874V21.7433H66.6447V24.5217H55.1584V6.97473H66.5175V9.75186H58.0874Z"
      fill="#001978"
    />
    <path
      d="M73.9307 9.75186V14.1701H80.3678V16.9472H73.9307V24.5217H71.0016V6.97473H82.1089V9.75186H73.9307Z"
      fill="#001978"
    />
    <path
      d="M87.6894 9.75186V14.1948H94.6329V16.9719H87.6894V21.7433H96.248V24.5217H84.7617V6.97473H96.1208V9.75186H87.6894Z"
      fill="#001978"
    />
    <path
      d="M103.535 21.7445H108.103C109.769 21.7445 110.982 20.5072 110.982 19.018C110.982 17.6041 109.719 16.3162 108.103 16.3162H103.535V21.7445ZM103.535 13.6948H107.321C108.507 13.6948 109.365 12.7094 109.365 11.7253C109.365 10.7411 108.633 9.75568 107.321 9.75568H103.535V13.6948ZM107.321 6.97595C110.351 6.97595 112.244 9.07016 112.244 11.7214C112.244 13.034 111.36 14.2713 110.781 14.6997C112.167 15.2308 113.859 16.9978 113.859 19.018C113.859 22.047 111.587 24.5216 108.103 24.5216H100.606V6.97595H107.321Z"
      fill="#001978"
    />
    <path
      d="M114.288 6.97473H117.57L122.644 18.7143L127.72 6.97473H131.002L122.644 25.0254L114.288 6.97473Z"
      fill="#001978"
    />
    <path
      d="M136.252 14.4479H140.342C142.261 14.4479 143.296 13.5897 143.296 12.1005C143.296 10.7113 142.209 9.75179 140.267 9.75179H136.252V14.4479ZM140.267 6.97595C143.675 6.97595 146.174 8.92345 146.174 12.1005C146.174 14.5751 144.486 16.3668 142.74 17.0237L146.653 24.5216H143.322L139.557 17.225H136.25V24.5216H133.371V6.97595H140.267Z"
      fill="#001978"
    />
    <path
      d="M152.433 9.75186V14.1948H159.377V16.9719H152.433V21.7433H160.993V24.5217H149.506V6.97473H160.866V9.75186H152.433Z"
      fill="#001978"
    />
  </svg>
);
export { Lefebvre };
