import { type FetchResult } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerContainer,
  DrawerContent,
  DrawerError,
  DrawerFooter,
  DrawerLoader,
  useBinding,
  useCloseDrawer,
} from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useReadingLists } from '../../context/ReadingListsProvider';
import type {
  AddDocumentToReadingListMutation,
  DeleteDocumentMutation,
  ReadingList,
} from '../../generated/userPreferences/graphql';
import {
  Order,
  ReadingListSort,
  useAddDocumentToReadingListMutation,
  useDeleteDocumentMutation,
  useReadingListsQuery,
} from '../../generated/userPreferences/graphql';
import { useAnalytics } from '../../helpers/analyticsTracker';
import { NewReadingList } from './NewReadingList';
import { RecentReadingLists } from './RecentReadingLists';

export interface AddToReadingListDrawerProps {
  /** The item reference of the document to add  */
  itemReference: string;
  /** The document title of the document to add */
  itemTitle: string;
  /** The controlled default open value of the drawer */
  isDefaultShown?: boolean;
  /** The controlled open value of the drawer */
  isShown?: boolean;
  /** The controlled show callback */
  onDrawerToggle?: (isShown: boolean) => void;
  /** The confirm callback with the response of the save action */
  onSuccess?: (
    data: Array<FetchResult<AddDocumentToReadingListMutation | DeleteDocumentMutation>>,
  ) => void;
  /** The error callback with the error response of the save action */
  onError?: (error: any) => void;
}

const StyledFooter = styled(ButtonGroup)`
  margin-left: auto;
`;

interface AddToReadingListDrawerContentProps {
  /** Whether the form is loading */
  isLoading?: boolean;
  /** Whether the form has an error */
  hasError?: boolean;
  /** The reset callback of the form */
  onReset: () => void;
}

const AddToReadingListDrawerContent = ({
  isLoading,
  hasError,
  onReset,
  children,
}: React.PropsWithChildren<AddToReadingListDrawerContentProps>) => {
  if (isLoading) {
    return (
      <DrawerLoader
        loadingText={<Trans>Leeslijsten opslaan...</Trans>}
        testId="addToReadingListDrawerLoading"
      />
    );
  }

  if (hasError) {
    return (
      <DrawerError
        buttonText={<Trans>Opnieuw proberen</Trans>}
        description={<Trans>Probeer het nog later nog eens</Trans>}
        testId="addToReadingListDrawerError"
        title={<Trans>Helaas is het opslaan van sommige leeslijsten mislukt</Trans>}
        onReset={onReset}
      />
    );
  }

  return (
    <DrawerContainer
      header={<Trans>Mijn leeslijsten</Trans>}
      subtitle={
        <Trans>
          Maak een nieuwe leeslijst aan om het document in te bewaren of kies een bestaande
          leeslijst.
        </Trans>
      }
    >
      {children}
    </DrawerContainer>
  );
};

type AddToReadingListDrawerPropsContainer = Pick<
  AddToReadingListDrawerProps,
  'onSuccess' | 'onError' | 'itemReference' | 'itemTitle'
>;

const AddToReadingListDrawerContainer = ({
  itemReference,
  itemTitle,
  onSuccess,
  onError,
}: AddToReadingListDrawerPropsContainer) => {
  const { client, siteId, applicationKey } = useReadingLists();
  const { trackFeatureInteraction } = useAnalytics();

  const close = useCloseDrawer();

  const {
    loading: isLoading,
    data,
    error,
  } = useReadingListsQuery({
    variables: {
      siteId,
      applicationKey,
      sort: {
        by: ReadingListSort.Modified,
        order: Order.Desc,
      },
    },
    client,
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [hasSubmitError, setSubmitError] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const initialCheckedItems = useMemo(
    () =>
      data?.readingList
        ? data.readingList.reduce((all: string[], list) => {
            const hasDocument = list.items.some((item) => item.itemReference === itemReference);
            return [...all, ...(hasDocument ? [list.id] : [])];
          }, [])
        : [],
    [data, itemReference],
  );

  useEffect(() => {
    setCheckedItems(initialCheckedItems);
  }, [initialCheckedItems]);

  const [addDocumentToReadingList] = useAddDocumentToReadingListMutation({
    client,
  });

  const [deleteDocument] = useDeleteDocumentMutation({
    client,
  });

  const addItemToReadingList = useCallback(
    (listId: ReadingList['id']) =>
      addDocumentToReadingList({
        variables: {
          siteId,
          listId,
          itemReference,
          itemTitle,
        },
      }),
    [addDocumentToReadingList, siteId, itemReference, itemTitle],
  );

  const removeItemFromReadingList = useCallback(
    (listId: ReadingList['id']) =>
      deleteDocument({
        variables: {
          siteId,
          listId,
          itemReference,
        },
      }),
    [deleteDocument, siteId, itemReference],
  );

  const removeItemsFromReadingList = useCallback(
    (items: string[] = []) => items.map((id) => removeItemFromReadingList(id)),
    [removeItemFromReadingList],
  );

  const addItemsToReadingList = useCallback(
    (items: string[] = []) => items.map((id) => addItemToReadingList(id)),
    [addItemToReadingList],
  );

  const updateReadingLists = useCallback(
    () =>
      Promise.all([
        ...removeItemsFromReadingList(
          initialCheckedItems.filter((val) => !checkedItems.includes(val)),
        ),
        ...addItemsToReadingList(checkedItems.filter((val) => !initialCheckedItems.includes(val))),
      ]),
    [addItemsToReadingList, checkedItems, initialCheckedItems, removeItemsFromReadingList],
  );

  const onSaveError = useCallback(
    (errors: any) => {
      setSubmitting(false);
      setSubmitError(true);
      onError?.(errors);
    },
    [onError],
  );

  const onSaveSuccess = useCallback(
    (responses: Array<FetchResult<DeleteDocumentMutation | AddDocumentToReadingListMutation>>) => {
      const hasError = responses.some((response) => response.errors);
      if (hasError) return onSaveError(responses);

      onSuccess?.(responses);
      setSubmitting(false);
      close();
    },
    [close, onSuccess, onSaveError],
  );

  const onSave = useCallback(async () => {
    setSubmitting(true);
    trackFeatureInteraction({
      id: 'addToReadingListDrawerSave',
      elementType: 'button',
      interactionType: 'form_submission',
    });
    try {
      const responses = await updateReadingLists();

      onSaveSuccess(responses);
    } catch (e) {
      onSaveError(e);
    }
  }, [trackFeatureInteraction, updateReadingLists, onSaveSuccess, onSaveError]);

  const onReset = useCallback(async () => {
    setSubmitting(false);
    setSubmitError(false);
  }, []);

  return (
    <AddToReadingListDrawerContent
      hasError={hasSubmitError}
      isLoading={isSubmitting}
      onReset={onReset}
    >
      <DrawerContent data-test-id="addToReadingListDrawerContent">
        <NewReadingList itemReference={itemReference} itemTitle={itemTitle} />
        <RecentReadingLists
          checkedItems={checkedItems}
          data={data}
          error={error}
          initialCheckedItems={initialCheckedItems}
          isLoading={isLoading}
          onChange={setCheckedItems}
        />
      </DrawerContent>
      <DrawerFooter>
        <StyledFooter spaceBetween={4} testId="addToReadingListDrawerActions">
          <Button
            testId="addToReadingListDrawerClose"
            variant="clear"
            onClick={() => {
              close();
              trackFeatureInteraction({
                id: 'addToReadingListDrawerClose',
                elementType: 'button',
                interactionType: 'button_click',
              });
            }}
          >
            <Trans>Annuleren</Trans>
          </Button>
          <Button testId="addToReadingListDrawerSave" type="submit" onClick={onSave}>
            <Trans>Opslaan</Trans>
          </Button>
        </StyledFooter>
      </DrawerFooter>
    </AddToReadingListDrawerContent>
  );
};

const AddToReadingListDrawer = ({
  itemReference,
  itemTitle,
  isDefaultShown,
  isShown,
  onDrawerToggle,
  onSuccess,
  onError,
}: AddToReadingListDrawerProps) => {
  const [isOpen, setOpen] = useBinding<boolean>(isDefaultShown, isShown, onDrawerToggle, false);
  const { trackElementVisibility } = useAnalytics();

  useEffect(() => {
    if (isDefaultShown || isShown) {
      trackElementVisibility({
        elementType: 'div',
        id: 'addToReadingListDrawer',
        isVisible: true,
      });
    }
  }, [isDefaultShown, isShown, trackElementVisibility]);

  return (
    <Drawer
      isShown={isOpen}
      position="right"
      testId="addToReadingListDrawer"
      width={480}
      onClose={() => {
        setOpen(false);
        trackElementVisibility({
          elementType: 'div',
          id: 'addToReadingListDrawer',
          isVisible: false,
        });
      }}
    >
      {itemReference && itemTitle && (
        <AddToReadingListDrawerContainer
          itemReference={itemReference}
          itemTitle={itemTitle}
          onError={onError}
          onSuccess={onSuccess}
        />
      )}
    </Drawer>
  );
};

export { AddToReadingListDrawer };
