import type { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';

import { AccessDenied } from './AccessDenied/AccessDenied';
import { ErrorBoundaryPageFallback } from './ErrorBoundaryPageFallback/ErrorBoundaryPageFallback';
import { ErrorStates, useErrorState } from './hooks/useErrorState';
import { NotFound } from './NotFound/NotFound';

interface ErrorPageProps {
  statusCode?: number;
  error?: ApolloError;
  forcedErrorState?: number;
}

const PaywallNoAccess = dynamic(
  () =>
    import(/* webpackChunkName: "PaywallNoAccess" */ '../Paywall/PaywallNoAccess/PaywallNoAccess'),
  {
    ssr: true,
  },
);

const PaywallNoSubscription = dynamic(
  () =>
    import(
      /* webpackChunkName: "PaywallNoSubscription" */ '../Paywall/PaywallNoSubscription/PaywallNoSubscription'
    ),
  {
    ssr: true,
  },
);

const PaywallUnknownUser = dynamic(
  () =>
    import(
      /* webpackChunkName: "PaywallUnknownUser" */ '../Paywall/PaywallUnknownUser/PaywallUnknownUser'
    ),
  {
    ssr: true,
  },
);

const Error = ({ statusCode, error, forcedErrorState }: ErrorPageProps) => {
  const errorState = useErrorState(statusCode, forcedErrorState);

  switch (errorState) {
    case ErrorStates.NOT_FOUND:
      return <NotFound />;
    case ErrorStates.ACCESS_DENIED:
      return <AccessDenied />;
    case ErrorStates.NO_ACCESS:
      return <PaywallNoAccess />;
    case ErrorStates.UNKNOWN_USER:
      return <PaywallUnknownUser />;
    case ErrorStates.NO_SUBSCRIPTION:
      return <PaywallNoSubscription />;
    default:
      Sentry.captureException(error, { extra: { errorType: 'NextJS component/Error/Error' } });
      return <ErrorBoundaryPageFallback />;
  }
};

export { Error };
