import type { JSX } from 'react';

import { Dalloz } from './Dalloz';
import { ESGbyKnowledge } from './ESGbyKnowledge';
import { ESGbyWorkflow } from './ESGbyWorkflow';
import { Giuffre } from './Giuffre';
import { Lefebvre } from './Lefebvre';
import { Rechtsorde } from './Rechtsorde';
import { Stollfuss } from './Stollfuss';

const logos: Record<string, JSX.Element> = {
  'esgby-knowledge': ESGbyKnowledge,
  'esgby-workflow': ESGbyWorkflow,
  dalloz: Dalloz,
  giuffre: Giuffre,
  lefebvre: Lefebvre,
  rechtsorde: Rechtsorde,
  stollfuss: Stollfuss,
};

export { logos };
