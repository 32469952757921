import { useCallback } from 'react';

import { useReadingLists } from '../../context/ReadingListsProvider';
import { useReadingListsPluginQuery } from '../../generated/userPreferences/graphql';
import { AddToReadingListDrawer } from '../AddToReadingListDrawer/AddToReadingListDrawer';

export interface ReadingListsPluginComponentsProps {
  itemReference: string;
  title: string;
  isShown?: boolean;
  onClose?: () => void;
  onQueryCompleted?: (hasDocument: boolean) => void;
}

const ReadingListsPluginComponents = ({
  itemReference,
  title,
  isShown,
  onQueryCompleted,
  onClose,
}: ReadingListsPluginComponentsProps) => {
  const { client, siteId, applicationKey } = useReadingLists();

  const { refetch } = useReadingListsPluginQuery({
    variables: {
      siteId,
      applicationKey,
      itemReference,
    },
    onCompleted: (data) => onQueryCompleted?.(!!data.readingList.length),
    onError: () => onQueryCompleted?.(false),
    client,
  });

  const handleDrawerToggle = useCallback(
    (isShown: boolean) => {
      if (!isShown) {
        onClose?.();
        refetch();
      }
    },
    [refetch, onClose],
  );

  return (
    <AddToReadingListDrawer
      isShown={isShown}
      itemReference={itemReference}
      itemTitle={title}
      onDrawerToggle={handleDrawerToggle}
    />
  );
};

export { ReadingListsPluginComponents };
