import { ExternalLinkIcon, Link } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useFormContext } from 'react-hook-form';

import { useSiteConfig } from '../../../../context/SiteConfigProvider';
import { transformAlertFieldsToSearchUrl } from '../../helpers/transformAlertFieldsToSearchUrl';
import type { AlertFormValues } from './AlertFormSchema';

/**
 * Custom hook to generate the alert preview URL based on the form fields.
 *
 * @returns {string} The URL for the alert preview.
 */
const useAlertPreviewUrl = () => {
  const { siteUrl, urls } = useSiteConfig();
  const { watch } = useFormContext<AlertFormValues>();

  /** Watch the form fields to get their current values */
  const fields = watch();

  /** Transform the form fields into a search URL */
  return transformAlertFieldsToSearchUrl(fields, siteUrl, urls.searchUrl);
};

/**
 * Component to render a link for previewing the alert search results.
 *
 * @returns {JSX.Element} The rendered link component.
 */
const AlertPreviewLink = () => {
  const url = useAlertPreviewUrl();

  return (
    <Link spaceAfter={4} suffixAdornment={<ExternalLinkIcon />} testId="alertPreviewLink" to={url}>
      <Trans>Voorbeeld zoekresultaat weergeven</Trans>
    </Link>
  );
};

export { AlertPreviewLink };
