import type { ApolloError } from '@apollo/client';
import { Checkbox, Flex, Loader, Text, useGreaterThan } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useCallback } from 'react';
import styled from 'styled-components';

import { useReadingLists } from '../../context/ReadingListsProvider';
import type { ReadingList, ReadingListsQuery } from '../../generated/userPreferences/graphql';
import { getAccessLevel } from '../../helpers/accessLevel';
import { parse, timeZone } from '../../helpers/date';
import { UserRole } from '../ShareReadingListDrawer/UserRoles';

interface RecentReadingListsProps {
  /** The initial checked items */
  initialCheckedItems: string[];
  /** The checked items */
  checkedItems: string[];
  /** The reading list data */
  data?: ReadingListsQuery;
  /** Whether the reading list data is loading */
  isLoading?: boolean;
  /** The reading list data error */
  error?: ApolloError;
  /** The change callback */
  onChange: (checkedItems: string[]) => void;
}

const RecentReadingListsContainer = styled(Flex).withConfig({
  componentId: 'RecentReadingListsContainer',
})`
  width: 100%;
`;

const CheckboxLabelContainer = styled(Flex).withConfig({
  componentId: 'CheckboxLabelContainer',
})`
  margin-right: auto;
`;

const RecentReadingListsTitle = styled(Text)`
  margin-top: 0;
`;

const RecentReadingListsContent = ({
  checkedItems,
  initialCheckedItems,
  isLoading,
  error,
  data,
  onChange,
}: RecentReadingListsProps) => {
  const { dateFormatFn, userId } = useReadingLists();
  const isLarge = useGreaterThan('s');

  const addItemToReadingList = useCallback(
    (listId: ReadingList['id']) => {
      onChange([...checkedItems, listId]);
    },
    [checkedItems, onChange],
  );

  const removeItemFromReadingList = useCallback(
    (listId: ReadingList['id']) => {
      onChange(checkedItems.filter((item) => item !== listId));
    },
    [checkedItems, onChange],
  );

  const handleChange = (listId: ReadingList['id'], checked: boolean) => {
    if (checked) {
      return addItemToReadingList(listId);
    }
    return removeItemFromReadingList(listId);
  };

  if (isLoading) {
    return <Loader height={48} variant="spinner" />;
  }

  if (error || !data?.readingList.length) {
    return (
      <Text type="paragraph">
        <Trans>Als je leeslijsten hebt aangemaakt kun je die hier terugvinden.</Trans>
      </Text>
    );
  }

  /**
   * Filter out reading lists where the user is a reader
   * Sort the reading lists by modified date
   */
  const readingLists = [...data.readingList]
    .filter((readingList) => getAccessLevel({ readingList, userId }) !== UserRole.Reader)
    .sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime());

  const readingListsWithDocument = readingLists.filter(({ id }) =>
    initialCheckedItems.includes(id),
  );
  const otherReadingLists = readingLists.filter(({ id }) => !initialCheckedItems.includes(id));

  return (
    <>
      {readingListsWithDocument.map(({ id, name, totalDocuments, modified }) => (
        <Checkbox
          key={id}
          defaultChecked={checkedItems.includes(id)}
          isChecked={checkedItems.includes(id)}
          isReverse={isLarge}
          label={
            <CheckboxLabelContainer
              flexDirection="column"
              spaceAfter={6}
              testId="recentReadingListsCheckboxLabel"
            >
              <Text spaceAfter={2} type="label">
                {name}
              </Text>
              <Text color="grey70" type="labelSmall">
                <Trans>{`${totalDocuments} documenten - Laatst gewijzigd op ${dateFormatFn(
                  parse(modified),
                  { dateStyle: 'short', timeZone },
                )}`}</Trans>
              </Text>
            </CheckboxLabelContainer>
          }
          name={id}
          testId="recentReadingListsCheckbox"
          onChange={(isChecked) => handleChange(id, isChecked)}
        />
      ))}
      {otherReadingLists.map(({ id, name, totalDocuments, modified }) => (
        <Checkbox
          key={id}
          defaultChecked={checkedItems.includes(id)}
          isChecked={checkedItems.includes(id)}
          isReverse={isLarge}
          label={
            <CheckboxLabelContainer
              flexDirection="column"
              spaceAfter={6}
              testId="recentReadingListsCheckboxLabel"
            >
              <Text type="label">{name}</Text>
              <Text color="grey70" type="labelSmall">
                <Trans>{`${totalDocuments} Documenten - Laatst gewijzigd op ${dateFormatFn(
                  new Date(modified),
                  { dateStyle: 'short', timeZone },
                )}`}</Trans>
              </Text>
            </CheckboxLabelContainer>
          }
          name={id}
          testId="recentReadingListsCheckbox"
          onChange={(isChecked) => handleChange(id, isChecked)}
        />
      ))}
    </>
  );
};

const RecentReadingLists = (props: RecentReadingListsProps) => (
  <RecentReadingListsContainer flexDirection="column" testId="recentReadingLists">
    <RecentReadingListsTitle as="h4" spaceAfter={4} type="labelBold">
      <Trans>Bestaande leeslijsten</Trans>
    </RecentReadingListsTitle>
    <RecentReadingListsContent {...props} />
  </RecentReadingListsContainer>
);

export { RecentReadingLists };
