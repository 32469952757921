import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';
import introspectionQueryResultData from '../generated/content-api/fragmentTypes.json';
import { getContentCacheTypePolicies } from '../helpers/getContentCacheTypePolicies';
import { getHeaders } from '../helpers/getRequestHeaders';
export const useContentClient = ({ graphqlUrl, accessToken, initialState, registerCache, fetch, }) => {
    const httpLink = useMemo(() => new HttpLink({
        uri: graphqlUrl,
        fetch,
    }), [graphqlUrl, fetch]);
    const authLink = useMemo(() => setContext((_, { headers }) => {
        if (!accessToken || typeof accessToken === 'string') {
            return getHeaders(headers, accessToken);
        }
        return accessToken().then((token) => getHeaders(headers, token));
    }), [accessToken]);
    return useMemo(() => {
        const cache = new InMemoryCache({
            possibleTypes: introspectionQueryResultData.possibleTypes,
            typePolicies: Object.assign({}, getContentCacheTypePolicies()),
        }).restore(initialState || {});
        if (registerCache) {
            registerCache(cache);
        }
        return new ApolloClient({
            ssrMode: typeof window === 'undefined',
            link: authLink.concat(httpLink),
            cache,
        });
    }, [authLink, httpLink, initialState, registerCache]);
};
