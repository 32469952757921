// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: fix the typing

import nextApp from 'next/app.js';
import React from 'react';

import cjsDefaultImport from './cjsDefaultImport';
import ServerContextContext from './ServerContextContext';
import type { ServerContext } from './useServerContext';

const NextApp = cjsDefaultImport(nextApp);

/**
 * Decorates a Next.js custom `App` or page React component, to provide
 * {@link ServerContextContext server context}.
 * @param {import("next").NextComponentType<
 *   import("next/app.js").AppContext | import("next").NextPageContext,
 *   any,
 *   any
 * >} Component Next.js custom `App` or page React component.
 * @returns Next.js custom `App` or page higher-order React component.
 * @example
 * A Next.js custom `App` in `pages/_app.js`:
 *
 * ```jsx
 * import withServerContext from "next-server-context/withServerContext.mjs";
 * import App from "next/app";
 *
 * export default withServerContext(App);
 * ```
 */
export function withServerContext<
  TComponent extends React.ComponentType<React.PropsWithChildren<TComponentType>>,
  TComponentType,
>(Component: TComponent): () => React.ReactElement {
  /**
   * Next.js custom `App` or page higher-order React component.
   * @type {import("next").NextComponentType<
   *   import("next/app.js").AppContext | import("next").NextPageContext,
   *   {
   *     serverContext?: import("./ServerContextContext.mjs").ServerContext,
   *     [key: string]: unknown,
   *   },
   *   {
   *     serverContext?: import("./ServerContextContext.mjs").ServerContext,
   *     [key: string]: unknown,
   *   }
   * >}
   */
  const WithServerContext = ({ serverContext, ...props }: any) => {
    return React.createElement(
      ServerContextContext.Provider,
      { value: serverContext },
      React.createElement(Component, props),
    );
  };

  if (typeof process === 'object' && process.env.NODE_ENV !== 'production')
    /**
     * The display name.
     * @see [React display name conventions](https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging).
     */
    WithServerContext.displayName = `WithServerContext(${
      Component.displayName || Component.name || 'Component'
    })`;

  WithServerContext.getInitialProps = async (context: ServerContext) => {
    const isApp = 'ctx' in context;
    const { req, res } = isApp ? context.ctx : context;
    // eslint-disable-next-line no-nested-ternary
    const props = Component.getInitialProps
      ? await Component.getInitialProps(context)
      : isApp
        ? await NextApp.getInitialProps(context)
        : {};

    return req
      ? {
          ...props,
          serverContext: {
            request: req,
            response: res,

            // This prevents the server attempting to serialize the server
            // context object to JSON for hydration on the client.
            toJSON: () => undefined,
          },
        }
      : props;
  };

  return WithServerContext;
}
