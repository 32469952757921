import { useCallback } from 'react';

import { useSiteConfig } from '../../../../../context/SiteConfigProvider';
import type { AlertParameters } from '../../../types/AlertParameters';
import type { AlertFormValues } from '../AlertFormSchema';
import { useAlertStore } from '../stores/AlertStore';

type UseFacetsWithLabelsReturn = (facets: AlertFormValues['facets']) => AlertParameters['facets'];

/**
 * Custom hook to map form facet values to alert parameters with corresponding labels.
 *
 * @returns {UseFacetsWithLabelsReturn} A function that transforms form facets into alert parameters with labels.
 */
export const useFacetsWithLabels = (): UseFacetsWithLabelsReturn => {
  const { searchTabs, facetsPreset } = useSiteConfig();
  const facetsWithLabels = useAlertStore((state) => state.facetsWithLabels);

  /**
   * Get the labeled tab facet based on the selected values.
   *
   * @param {string} name - The name of the facet.
   * @param {string[]} values - The selected values of the facet.
   * @returns {AlertParameters['facets']} The facet with labels.
   */
  const getTabsFacetWithLabel = useCallback(
    (name: string, values: string[]): AlertParameters['facets'] => ({
      [name]: [
        {
          label:
            searchTabs.find(({ keys }) => values[0] && keys.includes(values[0]))?.label || 'Alles',
          value: values,
        },
      ],
    }),
    [searchTabs],
  );

  /**
   * Get the labeled filter facets based on the selected values.
   *
   * @param {string} name - The name of the facet.
   * @param {string[]} values - The selected values of the facet.
   * @returns {AlertParameters['facets']} The facets with labels.
   */
  const getFiltersWithLabels = useCallback(
    (name: string, values: string[]): AlertParameters['facets'] => ({
      [name]: values.map((value) => ({
        label: facetsWithLabels?.[name]?.[value]?.label ?? value,
        value,
      })),
    }),
    [facetsWithLabels],
  );

  /**
   * Transform form facets into alert parameters with labels.
   *
   * @param {AlertFormValues['facets']} formFacets - The form facets to transform.
   * @returns {AlertParameters['facets']} The transformed facets with labels.
   */
  const getFacetsWithLabels = useCallback(
    (formFacets: AlertFormValues['facets']): AlertParameters['facets'] => {
      return Object.entries(formFacets).reduce((all, [name, values]) => {
        /** When the facet has no selected values; do not add it */
        if (!values.length) {
          return {
            ...all,
            [name]: [],
          };
        }

        /** If facet is a tabs facet */
        if (name === facetsPreset.tabsFacet) {
          return {
            ...all,
            ...getTabsFacetWithLabel(name, values),
          };
        }

        /** Otherwise get the filter facet labels */
        return {
          ...all,
          ...getFiltersWithLabels(name, values),
        };
      }, {});
    },
    [facetsPreset.tabsFacet, getFiltersWithLabels, getTabsFacetWithLabel],
  );

  return getFacetsWithLabels;
};
