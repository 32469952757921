import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';

export const AnalyticsPageViewTracker = () => {
  const analytics = useAnalytics();
  const router = useRouter();

  useEffect(() => {
    analytics.page({ path: router.asPath, search: router.query.toString() });
  }, [analytics, router]);

  return null;
};
