import { BookmarkAddFilledIcon, BookmarkAddOutlineIcon, ListLinkItem } from '@elseu/sdu-titan';
import { ReadingListsPlugin } from '@elseu/sdu-titan-reading-lists-react';
import type { SearchRootProviderProps } from '@elseu/sdu-titan-search';
import { Trans } from '@lingui/macro';

export const renderReadingListButtonFunction: SearchRootProviderProps['renderReadingListButton'] =
  ({ itemReference, title }) => (
    <ReadingListsPlugin itemReference={itemReference} title={title}>
      {({ hasDocument, openDrawer }) => {
        return (
          <ListLinkItem
            key="readingListButton"
            prefixAdornment={hasDocument ? <BookmarkAddFilledIcon /> : <BookmarkAddOutlineIcon />}
            onClick={openDrawer}
          >
            {hasDocument ? <Trans>Opgeslagen</Trans> : <Trans>Opslaan</Trans>}
          </ListLinkItem>
        );
      }}
    </ReadingListsPlugin>
  );
