import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useSiteConfig } from '../../context/SiteConfigProvider';
import { trigger } from '../../helpers/events';

interface TransitionOptionsProps {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}
export interface NextHistoryStateProps {
  url: string;
  as: string;
  options: TransitionOptionsProps;
}

export const PopStateHandler = () => {
  const { urls } = useSiteConfig();
  const router = useRouter();

  useEffect(() => {
    router.beforePopState(({ as, ...rest }) => {
      const [currentAsPath, currentHash] = router.asPath.split('#');
      const [newAsPath, newHash] = as.split('#');

      /** Catch back button for content so we can scroll to hash */
      if (
        as.includes(urls.contentUrl) &&
        currentAsPath === newAsPath &&
        currentHash !== newHash &&
        newHash
      ) {
        // When hash does not exist on page, let Next.js handle popstate normally
        const targetElement = document.getElementById(newHash);
        if (!targetElement) return true;

        trigger<NextHistoryStateProps>('popstate:content', { as, ...rest });
        // Make sure to return false so the Next.js router will not handle popstate.
        return false;
      }

      /** Catch back button in search */
      if (as.includes(urls.searchUrl)) {
        trigger<NextHistoryStateProps>('popstate:search', { as, ...rest });
      }

      /** Catch back button in magazine urls */
      if (as.includes(urls.magazinesUrl) && newHash) {
        trigger<NextHistoryStateProps>('popstate:magazines', { as, ...rest });
      }

      if (as.includes(urls.sourcesOverviewUrl)) {
        trigger<NextHistoryStateProps>('popstate:sources', { as, ...rest });
      }

      return true;
    });
  }, [router, urls.contentUrl, urls.magazinesUrl, urls.searchUrl, urls.sourcesOverviewUrl]);

  return null;
};
