import type { InputProps } from '@elseu/sdu-titan';
import { Input } from '@elseu/sdu-titan';
import emojiRegexCreator from 'emoji-regex';
import React, { useCallback } from 'react';
import type { Noop } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';

export type InputFieldProps = Omit<InputProps, 'onChange' | 'onInputChange'> & {
  testId?: string;
  onChange?: (value: string) => void;
};

const InputFieldComponent = ({
  name,
  onBlur: controlledOnBlur,
  onChange: controlledOnChange,
  ...rest
}: InputFieldProps) => {
  const { control } = useFormContext();
  const {
    field: { onBlur, onChange, ...field },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const killEmojis = useCallback((e: any) => {
    const emojiRegex = emojiRegexCreator();
    const newValue = e.target.value.replace(emojiRegex, '');

    e.target.value = newValue;
  }, []);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>, onBlur: Noop) => {
      onChange(e.target.value.trim());
      controlledOnChange?.(e.target.value.trim());
      onBlur();
      controlledOnBlur?.(e);
    },
    [controlledOnBlur, onChange, controlledOnChange],
  );

  return (
    <Input
      {...rest}
      {...field}
      errorText={errors[name]?.message as string | undefined}
      hasError={!!errors[name]}
      onBlur={(e) => handleBlur(e, onBlur)}
      onInput={killEmojis}
      onInputChange={(value) => {
        onChange(value);
        controlledOnChange?.(value);
      }}
    />
  );
};

const InputField = React.memo(InputFieldComponent);

export { InputField };
