import type { ReferenceId } from '@elseu/sdu-alert-react';

import type { AlertParameters } from '../types/AlertParameters';
import { AlertType } from '../types/AlertParameters';

export const renderDescription = (referenceId: ReferenceId, parameters: AlertParameters) => {
  // If alert is a quick alert
  if (referenceId) return null;

  const { type, facets } = parameters;

  // If alert is a search alert
  if (type === AlertType.SEARCH) {
    const facetsCount = Object.entries(facets)
      .filter(([_, values]) => values.length)
      .reduce((a, [_, values]) => a + (values.length > 0 ? 1 : 0), 0);
    return facetsCount ? `+${facetsCount} filters` : 'Geen filters gezet';
  }
  return null;
};
