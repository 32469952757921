import type { ErrorBoundaryProps } from '@sentry/nextjs';
import * as Sentry from '@sentry/nextjs';
import type { Primitive } from '@sentry/types';

interface CustomErrorBoundaryProps extends Omit<ErrorBoundaryProps, 'beforeCapture'> {
  tags?: Array<{ key: string; value: Primitive }>;
}

/**
 * Custom ErrorBoundary component that can set additional Sentry context.
 * @param tags - set tags in errors thrown by this error boundary.
 * Setting multiple different tags is possible, but not different values for the same tag.
 * Sentry will simply keep the latest one.
 */
const ErrorBoundary: React.FC<CustomErrorBoundaryProps> = ({ ...props }) => {
  const beforeCaptureFn = () => {
    props.tags?.forEach((tag) => {
      Sentry.setTag(tag.key, tag.value);
    });
  };

  return <Sentry.ErrorBoundary beforeCapture={beforeCaptureFn} {...props} />;
};

export { ErrorBoundary };
