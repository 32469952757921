'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import React from 'react';
export const AuthenticationContext = React.createContext(undefined);
export const AuthenticationProvider = ({ children, isLoggedIn, isInitialized, isSessionExpired, getAccessToken, authorize, claims, logout, isLoading, userInfo, }) => {
    const store = useMemo(() => {
        return {
            isLoggedIn,
            isInitialized,
            getAccessToken,
            isSessionExpired,
            authorize,
            claims,
            logout,
            isLoading,
            userInfo,
        };
    }, [
        authorize,
        claims,
        getAccessToken,
        isInitialized,
        isLoading,
        isLoggedIn,
        isSessionExpired,
        logout,
        userInfo,
    ]);
    return _jsx(AuthenticationContext.Provider, { value: store, children: children });
};
