import { Link, StatusMessage } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useCallback, useEffect, useState } from 'react';

const OfflineMessage = () => {
  const [isOffline, setOffline] = useState(false);

  const handleOffline = useCallback(() => {
    setOffline(true);
  }, []);
  const handleOnline = useCallback(() => {
    setOffline(false);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [handleOffline, handleOnline]);

  return (
    <div>
      <StatusMessage
        alignment="center"
        isShown={isOffline}
        testId="offlineStatusMessage"
        type="warning"
      >
        <Trans>
          Het lijkt erop dat je niet verbonden bent met het internet. Probeer de pagina{' '}
          <Link onClick={() => window.location.reload()}>opnieuw te laden</Link> of probeer het
          later nog eens.
        </Trans>
      </StatusMessage>
    </div>
  );
};

export { OfflineMessage };
