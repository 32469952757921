/* eslint-disable @typescript-eslint/naming-convention */
import { Claims } from '@repo/auth';
import { useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { useAnalytics } from 'use-analytics';

import { PingAnalyticsEvent } from '../../helpers';

const usePingAnalytics = () => {
  const { track } = useAnalytics();

  useEffectOnce(() => {
    track(PingAnalyticsEvent.PING_KEY_PENDING);
  });

  const trackPingKeyAvailable = useCallback(
    (claims?: Claims | null) => {
      const pingKey = claims?.sid || null;
      const pingKeyValidForCurrentProduct = !!claims?.role?.length;

      track(PingAnalyticsEvent.PING_KEY_AVAILABLE, {
        pingKey,
        pingKeyValidForCurrentProduct,
      });
    },
    [track],
  );

  const trackUserInitiatedPingLogin = useCallback(
    (claims?: Claims | null) => {
      const pingKey = claims?.sid || null;
      const pingKeyValidForCurrentProduct = !!claims?.role?.length;

      track('userInitiatedPingLogin', {
        pingKey,
        pingKeyValidForCurrentProduct,
      });
    },
    [track],
  );

  const trackUserIdAvailable = useCallback(
    ({ applicationKey, sub }: { applicationKey: string; sub: string }) => {
      track('userIdAvailable', {
        userId: `${applicationKey}:${sub}`,
      });
    },
    [track],
  );

  return useMemo(
    () => ({
      trackPingKeyAvailable,
      trackUserIdAvailable,
      trackUserInitiatedPingLogin,
    }),
    [trackPingKeyAvailable, trackUserIdAvailable, trackUserInitiatedPingLogin],
  );
};

export { usePingAnalytics };
