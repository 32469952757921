import type { SearchFacetProps } from '@elseu/sdu-titan-search';

import type { AlertFormValues } from '../components/AlertForm/AlertFormSchema';

const getAlertFormFacets = (facets?: SearchFacetProps[]) =>
  facets?.reduce(
    (acc: Record<string, string[]>, facet: SearchFacetProps): Record<string, string[]> => ({
      ...acc,
      [facet.name]: [],
    }),
    {},
  );

export const getAlertFormDefaultValues = (
  typeName: string,
  facets?: SearchFacetProps[],
  typeValues?: string[],
): AlertFormValues => ({
  term: '',
  customName: '',
  facets: {
    [typeName]: typeValues || [],
    ...getAlertFormFacets(facets),
  },
});
