/* eslint-disable @typescript-eslint/naming-convention */
import type { ApolloError, MutationResult, MutationTuple } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { MutationHookOptions } from '@apollo/client/react/types/types';
import type { DocumentNode } from 'graphql';
import { useState } from 'react';

type IResettableMutationState<TData = any> = Pick<
  MutationResult<TData>,
  'data' | 'called' | 'loading' | 'error'
>;

export function useResettableMutation<TData = any, TVariables = any>(
  query: DocumentNode,
  props: MutationHookOptions<TData, TVariables>,
): [MutationTuple<TData, TVariables>[0], MutationResult<TData> & { reset: () => void }] {
  const [{ loading, data, error, called }, setState] = useState<IResettableMutationState>({
    called: false,
    loading: false,
  });
  const onCompleted = (d: TData) => {
    const { onCompleted } = props;
    setState({ data: d, loading: false, called, error });
    onCompleted?.(data);
  };
  const onError = (e: ApolloError) => {
    const { onError } = props;
    setState({ loading: false, called, error: e });
    onError?.(e);
  };
  const reset = () => {
    setState({
      data: undefined,
      loading: false,
      called: false,
      error: undefined,
    });
  };

  const [mutate, { client }] = useMutation<TData, TVariables>(query, {
    ...props,
    onCompleted,
    onError,
  });
  const mutateWrapper = (opts: Parameters<MutationTuple<TData, TVariables>[0]>[0]) => {
    setState({
      data: undefined,
      loading: true,
      called: true,
      error: undefined,
    });
    return mutate(opts);
  };

  return [mutateWrapper, { data, loading, error, called, client, reset }];
}
