/* eslint-disable no-nested-ternary */
import type { SidebarItemProps } from '@elseu/sdu-titan';
import {
  BellOutlineIcon,
  BookmarkIcon,
  BookOpenedIcon,
  Clickable,
  ElementPropsProvider,
  Logo,
  Navigation as TitanNavigation,
  NavigationWidgetButton,
  Text,
  useSidebar,
} from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { useAuthentication } from '@repo/auth';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useNavigation } from '../../context/NavigationProvider/NavigationProvider';
import { useSiteConfig } from '../../context/SiteConfigProvider';
import { findDeep } from '../../helpers/findDeep';
import { useConsentManager } from '../../hooks/useConsentManager';
import { useIsAnonymousUser } from '../../hooks/useIsAnonymousUser';
import { logos } from '../Logos';
import { SkipLinks } from '../SkipLinks/SkipLinks';

const SidebarUser = dynamic(
  () => import(/* webpackChunkName: "SidebarUser" */ '../SidebarUser/SidebarUser'),
  {
    ssr: false,
  },
);
const SearchBarWithInPageSearch = dynamic(
  () =>
    import(
      /* webpackChunkName: "SearchBarWithInPageSearch" */ '../SearchBar/SearchBarWithInPageSearch'
    ),
  {
    ssr: false,
  },
);

const XposiNavigationButton = dynamic(
  () =>
    import(/* webpackChunkName: "XposiNavigationButton" */ '@elseu/sdu-titan-search').then(
      (module) => module.XposiNavigationButton,
    ),
  {
    ssr: false,
  },
);

const StyledClickable = styled(Clickable)`
  &:hover span,
  &:focus span {
    text-decoration: underline;
  }
`;

const CloseSidebarButton = () => {
  const { closeSidebar } = useSidebar();
  const { showConsentManager } = useConsentManager();

  return (
    <StyledClickable
      data-test-id="cookieSettingsButton"
      type="button"
      onClick={() => {
        closeSidebar?.();

        // wait until sidebar is closed
        setTimeout(() => {
          showConsentManager();
        }, 500);
      }}
    >
      <Text color="grey70" type="labelSmall">
        <Trans>Cookies wijzigen</Trans>
      </Text>
    </StyledClickable>
  );
};

const Navigation = () => {
  const { pathname, asPath, push: routerPush } = useRouter();

  const { brandName, logo, siteUrl, version, featureFlags, urls, shouldHideBrandName } =
    useSiteConfig();
  const isAnonymousUser = useIsAnonymousUser();
  const { navigation, isLoading, hasError, isShown, setShown } = useNavigation();
  const [activeSidebarId, setActiveSidebarId] = useState<string>();
  const { isLoggedIn } = useAuthentication();

  const isSearchHomePage = useMemo(
    () => pathname === '/' && featureFlags.WITH_HOMEPAGE_SEARCH,
    [pathname, featureFlags],
  );

  useEffect(() => {
    if (!navigation) return;
    const activeItem = findDeep<SidebarItemProps>(navigation, 'to', asPath);
    if (activeItem) setActiveSidebarId(activeItem.id);
  }, [asPath, navigation]);

  const widgets: React.ReactElement[] = [
    ...(featureFlags.WITH_XPOSI
      ? [
          <XposiNavigationButton
            key="xposiWidget"
            aria-label={t`Xposi`}
            data-onboarding="xposi"
            data-test-id="xposiNavigationWidget"
            label={<Trans>Xposi</Trans>}
          />,
        ]
      : []),
    ...(featureFlags.WITH_SOURCES
      ? [
          <NavigationWidgetButton
            key="sourcesOverviewWidget"
            aria-label={t`Bronnenoverzicht`}
            data-onboarding="sources-overview"
            data-test-id="sourcesOverviewWidget"
            label={<Trans>Bronnen</Trans>}
            onClick={() => routerPush(urls.sourcesOverviewUrl)}
          >
            <BookOpenedIcon />
          </NavigationWidgetButton>,
        ]
      : []),
    ...(!featureFlags.WITH_PUBLIC_SITE
      ? [
          <NavigationWidgetButton
            key="bookmarkWidget"
            aria-label={t`Leeslijsten`}
            data-onboarding="reading-lists"
            data-test-id="bookmarkWidget"
            label={<Trans>Leeslijsten</Trans>}
            onClick={() => routerPush(urls.readingListsUrl)}
          >
            <BookmarkIcon />
          </NavigationWidgetButton>,
        ]
      : []),
    ...(!featureFlags.WITH_PUBLIC_SITE && featureFlags.WITH_ALERTS
      ? [
          <NavigationWidgetButton
            key="notificationWidget"
            aria-label={t`Attenderingen`}
            data-onboarding="notifications"
            data-test-id="notificationsWidget"
            label={<Trans>Attenderingen</Trans>}
            onClick={() => routerPush(urls.alertsUrl)}
          >
            <BellOutlineIcon />
          </NavigationWidgetButton>,
        ]
      : []),
  ];

  const brand =
    process.env.NODE_ENV !== 'production' ||
    siteUrl?.includes('test') ||
    siteUrl?.includes('ota') ||
    siteUrl?.includes('dev')
      ? `${brandName} ${version}`
      : brandName;

  return (
    <>
      <SkipLinks />
      <ElementPropsProvider
        elementProps={{
          sidebarMenuButton: {
            'data-onboarding': 'menu',
          },
        }}
      >
        <TitanNavigation
          isSticky
          activeSidebarId={activeSidebarId}
          avatar={!featureFlags.WITH_PUBLIC_SITE ? <SidebarUser /> : undefined}
          brandName={logo ? '' : brand}
          hasError={hasError}
          isLoading={isLoading}
          isSearchShown={isShown && !isSearchHomePage}
          labelHamburger={<Trans>Menu</Trans>}
          labelSidebarClose={t`Sluit menu`}
          labelSidebarOpen={t`Open menu`}
          logoComponent={
            isSearchHomePage ? (
              <></>
            ) : (
              <Logo
                brandName={shouldHideBrandName ? undefined : brand}
                svg={logo ? logos[logo] : undefined}
              />
            )
          }
          matchFunction={(href) => href === asPath}
          searchComponent={
            isSearchHomePage ? undefined : (
              <SearchBarWithInPageSearch isShown={isShown} onToggle={setShown} />
            )
          }
          sidebarItems={navigation}
          widgets={isLoggedIn && !isAnonymousUser ? widgets : undefined}
          onToggleSearch={setShown}
        >
          <CloseSidebarButton />
        </TitanNavigation>
      </ElementPropsProvider>
    </>
  );
};

export { Navigation };
