import type { Spacings } from '@elseu/sdu-titan';
import { Box, ContentWrapper, Header, Logo, spacing } from '@elseu/sdu-titan';
import { NextSeo } from 'next-seo';
import type * as React from 'react';
import styled from 'styled-components';

import { logos } from '../../Logos';

interface IErrorComponent {
  title: string;
  description: string;
  content: React.ReactNode;
  button?: React.ReactElement;
  isFullScreen?: boolean;
  brandName?: string;
  logo?: string;
  spaceAfter?: Spacings;
}

const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: ${spacing(5)};
  padding-bottom: ${spacing(5)};
`;

function forceReload() {
  window.location.href = '/';
  window.location.reload();
}

const ErrorComponent = ({
  title,
  description,
  content,
  button,
  isFullScreen,
  brandName,
  logo,
  spaceAfter = 20,
}: IErrorComponent) => {
  return (
    <>
      <NextSeo nofollow noindex description={description} title={title} />
      <StyledContentWrapper
        id="main-content"
        maxWidth={640}
        spaceAfter={spaceAfter}
        testId="errorComponent"
      >
        {isFullScreen && brandName && (
          <Logo
            brandName={logo ? undefined : brandName}
            svg={logo ? logos[logo] : undefined}
            onClick={forceReload}
          />
        )}
        <Header hasContentWrapper={false} title={title} />
        <Box spaceAfter={4}>{content}</Box>
        {button}
      </StyledContentWrapper>
    </>
  );
};

export { ErrorComponent };
