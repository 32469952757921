import type { ApolloError } from '@apollo/client';
import type { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useCallback } from 'react';

const mapping = {
  ReadingListAlreadyExists: msg`Er bestaat al een leeslijst met deze naam.`,
  ReadingListEmptyName: msg`De naam van de leeslijst mag niet leeg zijn.`,
  ReadingListEmojiName: msg`De naam van de leeslijst mag geen emoji bevatten.`,
} as const;

const fieldErrorsMap = (
  name: keyof typeof mapping,
  message?: string,
): MessageDescriptor | string => {
  const fallbackMessage = msg`Dit veld bevat een fout`;

  if (!(mapping[name] as MessageDescriptor | undefined)) return message || fallbackMessage;

  return (mapping[name] as MessageDescriptor | undefined) || message || fallbackMessage;
};

export const useApiFieldError = <T>() =>
  useCallback(
    (
      error: ApolloError,
      callback: (field: keyof T, message: string | MessageDescriptor) => void,
    ) => {
      error.graphQLErrors.forEach(({ message, extensions }) => {
        if (!extensions.validationErrorCode) return;
        const translatedMessage = fieldErrorsMap(
          extensions.validationErrorCode as keyof typeof mapping,
          message,
        );
        callback(extensions.field as keyof T, translatedMessage);
      });
    },
    [],
  );
