import type { DockItem } from '@elseu/sdu-titan-content-rendering';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { isShownContentExportPlugin } from './ContentExportHelper';
import { DownloadPluginDockIconItem } from './DownloadPlugin';
import { PrintPluginDockIconItem } from './PrintPlugin';
import { ReadingListsPluginDockIconItem } from './ReadingListsPlugin';

const DownloadPluginDockComponents = dynamic(
  () => import(/* webpackChunkName: "DownloadPluginDockComponents" */ './DownloadPlugin'),
  {
    ssr: false,
  },
);

const PrintPluginDockComponents = dynamic(
  () => import(/* webpackChunkName: "PrintPluginDockComponents" */ './PrintPlugin'),
  {
    ssr: false,
  },
);

const ReadingListsPluginDockComponents = dynamic(
  () => import(/* webpackChunkName: "ReadingListsPluginDockComponents" */ './ReadingListsPlugin'),
  {
    ssr: false,
  },
);

const useDownloadPlugin = ({ brandingKey }: { brandingKey?: string }): DockItem => {
  const [isShown, setShown] = useState<boolean>(false);

  return {
    iconItem: {
      isShown: ({ data, isMobile }) => !isMobile && isShownContentExportPlugin(data),
      render: (data) => (
        <DownloadPluginDockIconItem itemData={data} onClick={() => setShown(true)} />
      ),
    },
    components: {
      isShown: ({ data, isMobile }) => !isMobile && isShownContentExportPlugin(data),
      render: (data) => (
        <DownloadPluginDockComponents
          brandingKey={brandingKey}
          isShown={isShown}
          itemData={data}
          onDrawerToggle={setShown}
        />
      ),
    },
  };
};

const usePrintPlugin = ({ brandingKey }: { brandingKey?: string }): DockItem => {
  const [isShown, setShown] = useState<boolean>(false);

  return {
    iconItem: {
      isShown: ({ data, isMobile }) => !isMobile && isShownContentExportPlugin(data),
      render: (data) => <PrintPluginDockIconItem itemData={data} onClick={() => setShown(true)} />,
    },
    components: {
      isShown: ({ data, isMobile }) => !isMobile && isShownContentExportPlugin(data),
      render: (data) => (
        <PrintPluginDockComponents
          brandingKey={brandingKey}
          isShown={isShown}
          itemData={data}
          onDrawerToggle={setShown}
        />
      ),
    },
  };
};

const useReadingListsPlugin = (): DockItem => {
  const [isShown, setShown] = useState<boolean>(false);
  const [hasDocument, setHasDocument] = useState<boolean>(false);

  return {
    iconItem: {
      isShown: ({ data, canHavePersonalisationFeatures }) =>
        !!canHavePersonalisationFeatures && !!data.swsId && !!data.swsDocumentTitle,
      render: (data) => (
        <ReadingListsPluginDockIconItem
          isFilled={hasDocument}
          itemData={data}
          onClick={() => setShown(true)}
        />
      ),
    },
    components: {
      isShown: ({ data, canHavePersonalisationFeatures }) =>
        !!canHavePersonalisationFeatures && !!data.swsId && !!data.swsDocumentTitle,
      render: (data) => (
        <ReadingListsPluginDockComponents
          isShown={isShown}
          itemData={data}
          onClose={() => setShown(false)}
          onQueryCompleted={setHasDocument}
        />
      ),
    },
  };
};

export { useDownloadPlugin, usePrintPlugin, useReadingListsPlugin };
