import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';

import type { AccessToken } from '../../../types/AccessToken';
import { getSecureHeaders } from '../helpers/getRequestHeaders';

interface IOfficeDocsClient {
  graphqlUrl: string;
  accessToken?: AccessToken;
  fetch?: WindowOrWorkerGlobalScope['fetch'];
}

export const useOfficeDocsClient = ({
  graphqlUrl,
  accessToken,
  fetch,
}: IOfficeDocsClient): ApolloClient<NormalizedCacheObject> => {
  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: graphqlUrl,
        fetch,
      }),
    [graphqlUrl, fetch],
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        if (!accessToken || typeof accessToken === 'string') {
          return headers;
        }
        return accessToken().then((token) => getSecureHeaders(headers, token));
      }),
    [accessToken],
  );

  return useMemo(() => {
    const cache = new InMemoryCache();

    return new ApolloClient({
      ssrMode: false,
      link: authLink.concat(httpLink),
      cache,
    });
  }, [authLink, httpLink]);
};
