import {
  CONTENT_EXPORT_TYPES,
  ContentExportDrawer,
  ContentExportPluginDockIconItem,
  ContentExportPluginDockMenuItem,
} from '@elseu/sdu-titan-content-export-react';
import type { DockItemData } from '@elseu/sdu-titan-content-rendering';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { canUseContentExportPluginResult } from './ContentExportHelper';

const LIMIT = 250;

interface PrintPluginDockItemProps {
  itemData: DockItemData;
  onClick: () => void;
}

const PrintPluginDockMenuItem = ({ itemData, onClick }: PrintPluginDockItemProps) => {
  const { data, isMobile } = itemData;
  const { canExport } = canUseContentExportPluginResult(data);

  if (isMobile || !canExport) return null;

  return (
    <ContentExportPluginDockMenuItem
      labelPluginButton={t`Printen`}
      type={CONTENT_EXPORT_TYPES.PRINT}
      onClick={onClick}
    />
  );
};

const PrintPluginDockIconItem = ({ itemData, onClick }: PrintPluginDockItemProps) => {
  useLingui();
  const { data, isMobile } = itemData;
  const { canExport } = canUseContentExportPluginResult(data);

  if (isMobile || !canExport) return null;

  return (
    <ContentExportPluginDockIconItem
      labelPluginButton={t`Printen`}
      type={CONTENT_EXPORT_TYPES.PRINT}
      onClick={onClick}
    />
  );
};

interface PrintPluginDockComponentsProps {
  itemData: DockItemData;
  isShown: boolean;
  onDrawerToggle: (isShown: boolean) => void;
  brandingKey?: string;
}
const PrintPluginDockComponents = ({
  brandingKey,
  itemData,
  isShown,
  onDrawerToggle,
}: PrintPluginDockComponentsProps) => {
  const { i18n } = useLingui();
  const { data, isMobile } = itemData;
  const { swsId, activeContentPart } = data;
  const { canExport, canExportFullDocument, canExportParts } =
    canUseContentExportPluginResult(data);

  if (isMobile || !canExport || !swsId) return null;

  return (
    <ContentExportDrawer
      activeId={activeContentPart}
      brandingKey={brandingKey}
      canExportFullDocument={canExportFullDocument}
      canExportParts={canExportParts}
      isShown={isShown}
      labelCancelButtonTitle={<Trans>Annuleren</Trans>}
      labelCannotSaveDocument={<Trans>Je kunt dit document helaas niet printen</Trans>}
      labelDrawerErrorButton={<Trans>Opnieuw printen</Trans>}
      labelDrawerErrorDescription={
        <Trans>
          Er is een fout opgetreden bij het ophalen van de printbare onderdelen. Probeer het later
          opnieuw.
        </Trans>
      }
      labelDrawerErrorHeader={<Trans>Er is een fout opgetreden</Trans>}
      labelDrawerHeader={<Trans>Printen</Trans>}
      labelDrawerLoader={<Trans>Printbare onderdelen ophalen</Trans>}
      labelDrawerSubtitle={<Trans>Selecteer de onderdelen die je wilt printen</Trans>}
      labelhasTooManyParts={({ limit }) => (
        <Trans>
          Maximaal {limit} onderdelen mogen in één keer geprint worden. Pas deselectie aan om verder
          te gaan.
        </Trans>
      )}
      labelSaveButtonTitle={<Trans>Printen</Trans>}
      labelSelectAll={t`Volledige document`}
      limit={LIMIT}
      locale={i18n.locale}
      swsId={swsId}
      type={CONTENT_EXPORT_TYPES.PRINT}
      onDrawerToggle={onDrawerToggle}
    />
  );
};

export { PrintPluginDockIconItem, PrintPluginDockMenuItem };
export default PrintPluginDockComponents;
