import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem } from '@elseu/sdu-titan';
import { CONTENT_EXPORT_TYPES } from './types';
const ContentExportPluginDockMenuItem = ({ type = CONTENT_EXPORT_TYPES.PRINT, onClick, labelPluginButton, }) => {
    return (_jsx(MenuItem, { item: {
            value: type,
            label: labelPluginButton,
        }, testId: `dockMenuItem${type.charAt(0).toUpperCase() + type.slice(1)}`, onClick: onClick }));
};
export { ContentExportPluginDockMenuItem };
