const Rechtsorde = (
  <svg height="40px" viewBox="0 0 207 72" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M117.595 72h72.49V0h-72.49z" fill="#29235C" />
      <path
        d="M182.867 44.523c0 4.261.347 11.2 6.979 11.2 6.91 0 6.979-7.077 6.979-12.024V30.506c0-4.123.207-13.261-6.98-13.261-7.599 0-6.978 9-6.978 12.986v14.292zm-9.948-12.162c.413-13.124 1.45-22.881 16.927-22.881s16.513 9.757 16.928 22.881v7.353c0 13.742-1.037 23.775-16.928 23.775-15.892 0-16.927-10.033-16.927-23.775V32.36z"
        fill="#FFF"
      />
      <path
        d="M4.462 21.212H7.97c1.465-.095 2.613-1.046 2.613-3.423 0-2.662-1.116-3.106-2.805-3.138H4.462v6.561zm0 3.487v10.238H0V11.164h8.446c3.857 0 6.788 1.332 6.788 5.706 0 2.599-.669 5.325-3.665 5.737v.063c2.646.349 3.443 2.03 3.443 4.311 0 .983-.128 6.815.956 7.512v.444H11.06c-.543-1.522-.447-4.438-.478-6.022-.033-1.46 0-3.456-1.53-3.9-1.212-.347-2.518-.316-3.793-.316h-.797zM19.412 34.937V11.164H31.97v3.487h-8.096v5.991h7.458v3.486h-7.458v7.322h8.096v3.487zM53.2 34.937V11.164h4.461v9.414h6.247v-9.414h4.462v23.773h-4.462V24.065H57.66v10.872zM71.05 11.164h14.884v3.487h-5.197v20.286h-4.46V14.65h-5.228zM97.727 18.391c.032-2.123-.159-4.215-2.613-4.215-1.499 0-2.454.602-2.454 2.218 0 1.808 1.148 2.504 2.55 3.392 1.466.919 4.175 2.694 5.418 3.899 1.53 1.49 2.008 2.916 2.008 4.976 0 4.5-2.996 6.847-7.362 6.847-5.355 0-7.266-2.98-7.266-7.228v-1.743h4.462v1.395c-.097 2.314.604 3.994 2.804 3.994 1.88 0 2.773-.983 2.773-2.79 0-1.394-.639-2.345-1.785-3.137-2.327-1.807-5.228-3.107-7.043-5.484-.734-1.078-1.149-2.378-1.149-3.645 0-4.057 2.295-6.276 7.012-6.276 7.108 0 6.917 5.452 6.948 7.797h-4.303zM57.44 48.626h3.506c1.467-.095 2.614-1.045 2.614-3.422 0-2.663-1.116-3.106-2.805-3.139H57.44v6.561zm0 3.487v10.238h-4.46V38.579h8.445c3.856 0 6.789 1.331 6.789 5.705 0 2.6-.67 5.325-3.666 5.737v.064c2.646.349 3.442 2.028 3.442 4.31 0 .983-.127 6.815.956 7.512v.444h-4.908c-.542-1.521-.446-4.437-.478-6.022-.032-1.458 0-3.455-1.53-3.899-1.21-.349-2.518-.317-3.793-.317h-.796zM78.348 58.865c3.985 0 4.24-2.093 4.24-8.97 0-5.61-.575-7.83-3.252-7.83h-2.932v16.8h1.944zm-6.406 3.486V38.579h9.307c1.084 0 3.25.475 4.62 2.694 1.02 1.648 1.307 4.28 1.307 8.21 0 4.69 0 10.11-3.664 12.235-.988.57-2.2.633-3.315.633h-8.255zM89.756 62.35V38.579h12.557v3.487h-8.095v5.991h7.457v3.486h-7.457v7.323h8.095v3.486z"
        fill="#29235C"
      />
      <path
        d="M142.376 32.498h7.6c3.178-.206 5.666-2.267 5.666-7.42 0-5.772-2.418-6.734-6.081-6.803h-7.185v14.223zm0 7.56V62.25h-9.672V10.717h18.308c8.361 0 14.717 2.885 14.717 12.368 0 5.634-1.451 11.544-7.946 12.437v.138c5.736.755 7.462 4.398 7.462 9.344 0 2.13-.276 14.773 2.073 16.286v.961h-10.64c-1.174-3.298-.968-9.619-1.036-13.055-.069-3.161 0-7.49-3.317-8.452-2.625-.755-5.458-.686-8.221-.686h-1.728z"
        fill="#FFF"
      />
      <path
        d="M189.846 17.245c-7.6 0-6.978 9-6.978 12.986v14.292c0 4.261.346 11.2 6.978 11.2 6.91 0 6.978-7.077 6.978-12.024V30.505c0-4.122.208-13.26-6.978-13.26"
        fill="#008EDF"
      />
      <path
        d="M45.764 24.065v2.314c0 2.282-.031 5.547-3.219 5.547-3.06 0-3.22-3.202-3.22-5.167v-6.593c0-1.838-.286-5.99 3.22-5.99 3.315 0 3.22 4.215 3.22 6.117v.285h4.57c-.21-5.764-.877-9.984-7.79-9.984-7.14 0-7.617 4.5-7.808 10.555v3.392c0 6.338.478 10.966 7.808 10.966s7.81-4.628 7.81-10.966v-.476h-4.59zM39.34 54.173c0 1.966.16 5.167 3.219 5.167 3.187 0 3.22-3.265 3.22-5.547v-6.085c0-1.903.095-6.118-3.22-6.118-3.506 0-3.219 4.152-3.219 5.99v6.593zm-4.589-5.61c.191-6.054.67-10.555 7.808-10.555 7.14 0 7.618 4.501 7.808 10.555v3.392c0 6.34-.478 10.967-7.808 10.967s-7.808-4.628-7.808-10.967v-3.392z"
        fill="#29235C"
      />
    </g>
  </svg>
);
export { Rechtsorde };
