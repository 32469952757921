import type { InPageSearchProviderProps } from '@elseu/sdu-titan';
import { InPageSearchProvider, useInPageSearch } from '@elseu/sdu-titan';
import { useInPageSearchPlugin } from '@elseu/sdu-titan-content-rendering';
import { useRouter } from 'next/router';
import type * as React from 'react';
import { useEffect } from 'react';

import { useSiteConfig } from '../SiteConfigProvider';

const InPageSearchContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { handleReset } = useInPageSearch();

  /**
   * Reset in page search When navigating
   */
  useEffect(() => {
    handleReset();
  }, [router, handleReset]);

  return <>{children}</>;
};

const InPageSearchProviderWithRouter: React.FC<
  React.PropsWithChildren<Omit<InPageSearchProviderProps, 'plugins'>>
> = ({ children, ...rest }) => {
  const { query } = useRouter();
  const plugin = useInPageSearchPlugin((query.swsId as string) || '');
  const { featureFlags } = useSiteConfig();

  if (!featureFlags.WITH_IN_PAGE_SEARCH) {
    return <>{children}</>;
  }

  return (
    <InPageSearchProvider plugins={[plugin]} {...rest}>
      <InPageSearchContainer>{children}</InPageSearchContainer>
    </InPageSearchProvider>
  );
};

export { InPageSearchProviderWithRouter };
