import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import queryString from 'query-string';
import { useMemo } from 'react';

import type { RedirectQueryParams } from '../../../generated/local/graphql';

interface RedirectClient {
  apiUrl: string;
}

export const useRedirectClient = ({
  apiUrl,
}: RedirectClient): ApolloClient<NormalizedCacheObject> => {
  /**
   * RestLink is used to fetch response for redirecting
   */
  const restLink = useMemo(
    () =>
      new RestLink({
        uri: apiUrl,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        customFetch: global.fetch,
        bodySerializers: {
          string: (data: RedirectQueryParams, headers: Headers) => {
            const body = queryString.stringify({
              path: data.path,
              querystring: data.querystring,
              fragment: '',
            });
            return { body, headers };
          },
        },
        responseTransformer: async (response) => {
          if (!response.ok) {
            throw new Error(`Redirecting failed with error: ${response.statusText}`);
          }

          const { type, ...rest } = await response.json();

          if (type === 'sws') {
            return {
              __typename: 'SwsReference',
              type,
              ...rest,
            };
          }
          return {
            __typename: 'ExternalReference',
            type,
            ...rest,
          };
        },
      }),
    [apiUrl],
  );

  return useMemo(() => {
    const cache = new InMemoryCache();

    return new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: restLink,
      cache,
    });
  }, [restLink]);
};
