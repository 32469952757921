import { useCallback, useEffect } from 'react';
import { create } from 'zustand';

type UseConsentsStore = {
  consents: string[];
  setConsents: (consents: string[]) => void;
};

const useConsentsStore = create<UseConsentsStore>((set) => ({
  consents: [],
  setConsents: (consents) => set({ consents }),
}));

export const Consents = () => {
  const setConsents = useConsentsStore((state) => state.setConsents);

  /**
   * Turns 'vimeo=1,youtube=0,snowplow=1' into ['vimeo','snowplow']
   */
  const getConsentValues = useCallback((consents: string) => {
    const values = consents.split(',');
    return values
      .filter((val: string) => {
        const [, value] = val.split('=');
        return value !== '0';
      })
      .map((val: string) => {
        const [name] = val.split('=');
        return name;
      }) as string[];
  }, []);

  const handleConsentsAvailable = useCallback(
    (e: any) => {
      if (e.data.consents) {
        setConsents(getConsentValues(e.data.consents));
      }
    },
    [getConsentValues, setConsents],
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    document.addEventListener('consentsAvailable', handleConsentsAvailable, false);

    return () => {
      document.removeEventListener('consentsAvailable', handleConsentsAvailable);
    };
  });

  return null;
};

/**
 * @deprecated This hook should no longer be used in its current state.
 * If a new use case presents itself, the implementation must be revised.
 */
export const useConsents = (): string[] => {
  const consents = useConsentsStore((state) => state.consents);
  return consents;
};
