import { useAuthentication } from '@repo/auth';
import { useCallback } from 'react';

enum ErrorStates {
  /** Status 403 and not logged in. */
  UNKNOWN_USER = 0 /** Check */,
  /** Status 403 and logged in. Has claims and roles. */
  NO_SUBSCRIPTION = 1,
  /** Status 403 and logged in. Has claims, but no roles (at least: no roles in scope). */
  NO_ACCESS = 2,
  /** Status 404. */
  NOT_FOUND = 3,
  ACCESS_DENIED = 4,
}

const useErrorState = (statusCode?: number, forcedErrorState?: number) => {
  const { claims } = useAuthentication();

  const getAccessDenied = useCallback(() => {
    const hasClaims = !!claims;
    const hasRoles = !!claims?.role?.length;

    if (hasClaims && hasRoles) {
      return ErrorStates.NO_SUBSCRIPTION;
    }
    if (hasClaims && !hasRoles) {
      return ErrorStates.NO_ACCESS;
    }
    return ErrorStates.UNKNOWN_USER;
  }, [claims]);

  if (forcedErrorState) {
    return forcedErrorState;
  }

  switch (statusCode) {
    case 404:
      return ErrorStates.NOT_FOUND;
    case 403:
      return getAccessDenied();
  }
};

export { ErrorStates, useErrorState };
