import { i18n } from '@lingui/core';

// TODO: use ENV variable for this
export const DEFAULT_LOCALE = 'nl-NL';

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string = DEFAULT_LOCALE) {
  const { messages } = await import(`../locales/${locale}/messages.js`);
  i18n.loadAndActivate({ locale, messages });
}
