import type { ReferenceId } from '@elseu/sdu-alert-react';
import { DefinitionList, spacing } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { useSiteConfig } from '../../../context/SiteConfigProvider';
import type { AlertFacetValue, AlertParameters } from '../types/AlertParameters';
import { AlertType } from '../types/AlertParameters';

const StyledDefinitionList = styled(DefinitionList)`
  text-align: left;

  dt {
    width: 130px !important;
  }
  dd,
  dt {
    padding: 0 !important;
  }
  dl {
    margin-bottom: ${spacing(2)} !important;
  }
`;

interface ParametersDefinitionListProps {
  parameters: AlertParameters;
}
export const ParametersDefinitionList = ({ parameters }: ParametersDefinitionListProps) => {
  const { facetsPreset } = useSiteConfig();
  const { term, facets } = parameters;
  const termColumn = term
    ? [
        {
          term: <Trans>Zoekterm</Trans>,
          definition: term,
        },
      ]
    : [];

  const getDefinition = (name: string, values: AlertFacetValue[]): string => {
    // When facet is the tab and there are no values render 'alles' as a definition
    if (name === facetsPreset.tabsFacet && !values.length) {
      return 'alles';
    }
    // For other facets render the labels of the values comma seperated
    return values.map((value) => value.label).join(', ');
  };

  const getTerm = (name: string): string => {
    // When facet is the tab just render a static label
    if (name === facetsPreset.tabsFacet) {
      return 'Informatiesoort';
    }
    // For other facets render the predefined facet label
    return facetsPreset.facets.find((f) => f.name === name)?.label || '';
  };

  const facetsColumn = Object.entries(facets)
    .filter(([name, values]) => name === facetsPreset.tabsFacet || values.length)
    .map(([name, values]) => ({
      term: getTerm(name),
      definition: getDefinition(name, values),
    }));

  const columns = [...termColumn, ...facetsColumn];

  return (
    <StyledDefinitionList columns={columns} hasDivider={false} testId="parametersDefinitionList" />
  );
};

export const renderParameters = (_: ReferenceId, parameters?: AlertParameters) => {
  if (!parameters || ![AlertType.ALL_TAB, AlertType.SEARCH].includes(parameters.type as AlertType))
    return null;

  return <ParametersDefinitionList parameters={parameters} />;
};
