import * as Sentry from '@sentry/nextjs';
import {
  destroyCookie as destroyNookieCookie,
  parseCookies,
  setCookie as setNookieCookie,
} from 'nookies';

import type { FunctionArgumentsType } from '../types/function-arguments';

type GetCookieCTX = FunctionArgumentsType<typeof parseCookies>[0];

const getCookie = (key: string, ctx: GetCookieCTX = null) => {
  try {
    return parseCookies(ctx)[key];
  } catch (e) {
    // Added own try catch to get more information on what is going on.
    // Link to sentry issue https://sentry.awssdu.nl/organizations/sdu/issues/44893.
    const isBrowser = typeof window !== 'undefined';
    Sentry.captureException(e, {
      extra: {
        isBrowser,
        ...(isBrowser
          ? { typeOfCookiesStringBrowser: typeof document.cookie }
          : { typeOfCookieStringServer: typeof ctx?.req?.headers.cookie }),
        errorType: 'NextJS getCookie error',
      },
    });
    return undefined;
  }
};

type SetCookieCTX = FunctionArgumentsType<typeof setNookieCookie>[0];
const setCookie = (
  key: string,
  value: string,
  ctx: SetCookieCTX = null,
  maxAge = 30 * 24 * 60 * 60,
) =>
  setNookieCookie(ctx, key, value, {
    maxAge,
    path: '/',
  });

type DestroyCookieCTX = FunctionArgumentsType<typeof destroyNookieCookie>[0];
const destroyCookie = (value: string, ctx: DestroyCookieCTX = {}) =>
  destroyNookieCookie(ctx, value, {
    path: '/',
  });

export { destroyCookie, getCookie, setCookie };
