import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type BasicPage = Node & {
  __typename?: 'BasicPage';
  content?: Maybe<Paragraph[]>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageStyle?: Maybe<Scalars['String']['output']>;
  responsiveStyle?: Maybe<ResponsiveImageStyle>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['String']['output']>;
};

export type ImageImageStyleArgs = {
  style?: InputMaybe<ImageStyle>;
};

export type ImageResponsiveStyleArgs = {
  style?: InputMaybe<ResponsiveImagesStyle>;
};

export enum ImageStyle {
  None = 'NONE',
  '312x176Focal' = '_312x176_focal',
  '312xautoScale' = '_312xauto_scale',
  '356x200Focal' = '_356x200_focal',
  '624x352Focal' = '_624x352_focal',
  '624xautoScale' = '_624xauto_scale',
  '665x480Focal' = '_665x480_focal',
  '700xautoScale' = '_700xauto_scale',
  '712x400Focal' = '_712x400_focal',
  '719x405Focal' = '_719x405_focal',
  '800xautoScale' = '_800xauto_scale',
  '936x528Focal' = '_936x528_focal',
  '936xautoScale' = '_936xauto_scale',
  '1068x600Focal' = '_1068x600_focal',
  '1068x714Focal' = '_1068x714_focal',
  '1330x960Focal' = '_1330x960_focal',
  '1400xautoScale' = '_1400xauto_scale',
  '1438x810Focal' = '_1438x810_focal',
  '1600xautoScale' = '_1600xauto_scale',
  '1995x1440Focal' = '_1995x1440_focal',
  '2100xautoScale' = '_2100xauto_scale',
  '2157x1215Focal' = '_2157x1215_focal',
  '2400xautoScale' = '_2400xauto_scale',
  Large = 'large',
  MediaLibrary = 'media_library',
  Medium = 'medium',
  Thumbnail = 'thumbnail',
}

export type Link = {
  __typename?: 'Link';
  href: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MarketingBlock = {
  __typename?: 'MarketingBlock';
  link?: Maybe<Link>;
  shouldShowLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Media = {
  id: Scalars['ID']['output'];
};

export type MediaImage = Media & {
  __typename?: 'MediaImage';
  id: Scalars['ID']['output'];
  image: Image;
};

export type MediaVideoEmbed = Media & {
  __typename?: 'MediaVideoEmbed';
  id: Scalars['ID']['output'];
  video: VideoEmbed;
};

export type MenuPage = {
  __typename?: 'MenuPage';
  children?: Maybe<Array<Maybe<MenuPageBlocks>>>;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MenuPageBlockClnLink = MenuPageBlockLinkItem & {
  __typename?: 'MenuPageBlockCLNLink';
  commentaryLawNavigationId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parent: Scalars['String']['output'];
  publicationIdentifier?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum MenuPageBlockColumn {
  Left = 'left',
  Right = 'right',
}

export type MenuPageBlockCustomLink = MenuPageBlockLinkItem & {
  __typename?: 'MenuPageBlockCustomLink';
  href: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parent: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MenuPageBlockLinkItem = {
  id: Scalars['String']['output'];
  parent: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MenuPageBlockSwsDocumentLink = MenuPageBlockLinkItem & {
  __typename?: 'MenuPageBlockSwsDocumentLink';
  id: Scalars['String']['output'];
  parent: Scalars['String']['output'];
  swsDocumentId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum MenuPageBlockType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type MenuPageBlocks =
  | MenuPageBlockClnLink
  | MenuPageBlockCustomLink
  | MenuPageBlockSwsDocumentLink
  | MenuPageColumnType;

export type MenuPageColumnType = MenuPageBlockLinkItem & {
  __typename?: 'MenuPageColumnType';
  column?: Maybe<MenuPageBlockColumn>;
  id: Scalars['String']['output'];
  navigationId?: Maybe<Scalars['String']['output']>;
  parent: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variant: MenuPageBlockType;
};

export type Navigation = {
  __typename?: 'Navigation';
  children?: Maybe<Array<Maybe<NavigationLinkTopLevel>>>;
  id: Scalars['String']['output'];
};

export type NavigationCustomLink = NavigationLink & {
  __typename?: 'NavigationCustomLink';
  href: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isHighlightedItem: Scalars['Boolean']['output'];
  parent: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NavigationLink = {
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isHighlightedItem: Scalars['Boolean']['output'];
  parent: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NavigationLinkSwsDocumentLink = NavigationLink & {
  __typename?: 'NavigationLinkSwsDocumentLink';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isHighlightedItem: Scalars['Boolean']['output'];
  parent: Scalars['String']['output'];
  swsDocumentId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NavigationLinkTopLevel =
  | NavigationCustomLink
  | NavigationLinkSwsDocumentLink
  | NavigationLinkWithChildren
  | NavigationMenuPageLink;

export type NavigationLinkWithChildren = NavigationLink & {
  __typename?: 'NavigationLinkWithChildren';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  isHighlightedItem: Scalars['Boolean']['output'];
  parent: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NavigationMenuPageLink = NavigationLink & {
  __typename?: 'NavigationMenuPageLink';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isHighlightedItem: Scalars['Boolean']['output'];
  menuPageId: Scalars['String']['output'];
  parent: Scalars['String']['output'];
  publicationIdentifier?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NavigationSubLevel =
  | NavigationCustomLink
  | NavigationLinkSwsDocumentLink
  | NavigationMenuPageLink;

export type NoAccessPaywall = {
  __typename?: 'NoAccessPaywall';
  buttons?: Maybe<ParagraphButton[]>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  upsell?: Maybe<Scalars['String']['output']>;
};

export type Node = {
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type NodeMatch = RouterMatch & {
  __typename?: 'NodeMatch';
  entity: Node;
  id: Scalars['ID']['output'];
};

export type NotWithinSubscriptionUserPaywall = {
  __typename?: 'NotWithinSubscriptionUserPaywall';
  buttons?: Maybe<ParagraphButton[]>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  upsell?: Maybe<Scalars['String']['output']>;
};

export type OnboardingSlide = {
  __typename?: 'OnboardingSlide';
  id: Scalars['String']['output'];
  link?: Maybe<Link>;
  media: MediaImage;
  publishDate: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type OnboardingSlides = {
  __typename?: 'OnboardingSlides';
  results?: Maybe<OnboardingSlide[]>;
  totalCount: Scalars['Int']['output'];
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Paragraph = {
  id: Scalars['ID']['output'];
};

export type ParagraphButton = Paragraph & {
  __typename?: 'ParagraphButton';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  link: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type ParagraphMedia = Paragraph & {
  __typename?: 'ParagraphMedia';
  caption?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media: Media;
};

export type ParagraphMediaGallery = Paragraph & {
  __typename?: 'ParagraphMediaGallery';
  id: Scalars['ID']['output'];
  items?: Maybe<ParagraphMedia[]>;
};

export type ParagraphText = Paragraph & {
  __typename?: 'ParagraphText';
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type ParagraphTitleText = Paragraph & {
  __typename?: 'ParagraphTitleText';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Paywall = {
  __typename?: 'Paywall';
  noAccess?: Maybe<NoAccessPaywall>;
  notWithinSubscription?: Maybe<NotWithinSubscriptionUserPaywall>;
  unknownUser?: Maybe<UnknownUserPaywall>;
};

export type Query = {
  __typename?: 'Query';
  router?: Maybe<RouterMatch>;
  site?: Maybe<Site>;
  siteSettings?: Maybe<SiteSettings>;
};

export type QueryRouterArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySiteArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySiteSettingsArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  id: Scalars['String']['output'];
  publishDate: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type ReleaseNotes = {
  __typename?: 'ReleaseNotes';
  results?: Maybe<ReleaseNote[]>;
  totalCount: Scalars['Int']['output'];
};

export type ResponsiveImageSource = {
  __typename?: 'ResponsiveImageSource';
  media?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Array<Scalars['String']['output']>>;
  srcset: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ResponsiveImageStyle = {
  __typename?: 'ResponsiveImageStyle';
  fallback?: Maybe<Scalars['String']['output']>;
  sources: ResponsiveImageSource[];
};

export enum ResponsiveImagesStyle {
  None = 'NONE',
  FullWidthContentImage = 'full_width_content_image',
  GalleryImage = 'gallery_image',
  OnboardingImage = 'onboarding_image',
}

export type RouterMatch = {
  id: Scalars['ID']['output'];
};

export type Schema = {
  __typename?: 'Schema';
  query?: Maybe<Query>;
};

export type Site = {
  __typename?: 'Site';
  id?: Maybe<Scalars['String']['output']>;
  menuPage?: Maybe<MenuPage>;
  navigation?: Maybe<Navigation>;
  onboardingSlides?: Maybe<OnboardingSlides>;
  releaseNotes?: Maybe<ReleaseNotes>;
  router?: Maybe<RouterMatch>;
  settings?: Maybe<SiteSettings>;
  siteMap?: Maybe<SiteMap>;
  tooltips?: Maybe<Tooltips>;
};

export type SiteMenuPageArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type SiteOnboardingSlidesArgs = {
  firstVisit?: InputMaybe<Scalars['Boolean']['input']>;
  maxVersion?: InputMaybe<Scalars['String']['input']>;
  minVersion?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type SiteReleaseNotesArgs = {
  pagination?: InputMaybe<Pagination>;
  version: Scalars['String']['input'];
};

export type SiteRouterArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type SiteTooltipsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type SiteMap = {
  __typename?: 'SiteMap';
  results?: Maybe<SiteMapResult[]>;
  totalCount: Scalars['Int']['output'];
};

export type SiteMapResult = {
  __typename?: 'SiteMapResult';
  id: Scalars['String']['output'];
  lastUpdated: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SiteSettings = {
  __typename?: 'SiteSettings';
  MarketingBlock?: Maybe<MarketingBlock>;
  id: Scalars['String']['output'];
  paywall?: Maybe<Paywall>;
};

export type Tooltip = {
  __typename?: 'Tooltip';
  id: Scalars['ID']['output'];
  tooltip?: Maybe<Array<Maybe<TooltipItem>>>;
  tooltip_id?: Maybe<Scalars['String']['output']>;
};

export type TooltipItem = {
  __typename?: 'TooltipItem';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Tooltips = {
  __typename?: 'Tooltips';
  results?: Maybe<Tooltip[]>;
  totalCount: Scalars['Int']['output'];
};

export type UnknownUserPaywall = {
  __typename?: 'UnknownUserPaywall';
  buttons?: Maybe<ParagraphButton[]>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  upsell?: Maybe<Scalars['String']['output']>;
};

export type VideoEmbed = {
  __typename?: 'VideoEmbed';
  type: VideoEmbedType;
  url: Scalars['String']['output'];
};

export enum VideoEmbedType {
  Vimeo = 'vimeo',
  Youtube = 'youtube',
}

export type BasicPageQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
}>;

export type BasicPageQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id?: string | null;
    router?: {
      __typename?: 'NodeMatch';
      id: string;
      entity: {
        __typename?: 'BasicPage';
        title: string;
        id: string;
        content?: Array<
          | {
              __typename?: 'ParagraphButton';
              id: string;
              label: string;
              variant: string;
              link: string;
            }
          | {
              __typename?: 'ParagraphMedia';
              id: string;
              caption?: string | null;
              media:
                | {
                    __typename?: 'MediaImage';
                    id: string;
                    image: {
                      __typename?: 'Image';
                      id?: string | null;
                      alt?: string | null;
                      thumbnail?: string | null;
                      responsiveStyle?: {
                        __typename?: 'ResponsiveImageStyle';
                        fallback?: string | null;
                        sources: Array<{
                          __typename?: 'ResponsiveImageSource';
                          media?: string | null;
                          sizes?: string[] | null;
                          srcset: string[];
                          type: string;
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename?: 'MediaVideoEmbed';
                    id: string;
                    video: { __typename?: 'VideoEmbed'; type: VideoEmbedType; url: string };
                  };
            }
          | {
              __typename?: 'ParagraphMediaGallery';
              id: string;
              items?: Array<{
                __typename?: 'ParagraphMedia';
                caption?: string | null;
                id: string;
                media:
                  | {
                      __typename?: 'MediaImage';
                      id: string;
                      image: {
                        __typename?: 'Image';
                        id?: string | null;
                        alt?: string | null;
                        thumbnail?: string | null;
                        responsiveStyle?: {
                          __typename?: 'ResponsiveImageStyle';
                          fallback?: string | null;
                          sources: Array<{
                            __typename?: 'ResponsiveImageSource';
                            media?: string | null;
                            sizes?: string[] | null;
                            srcset: string[];
                            type: string;
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename?: 'MediaVideoEmbed';
                      id: string;
                      video: { __typename?: 'VideoEmbed'; type: VideoEmbedType; url: string };
                    };
              }> | null;
            }
          | { __typename?: 'ParagraphText'; id: string; text: string }
          | {
              __typename?: 'ParagraphTitleText';
              id: string;
              title?: string | null;
              fullText?: string | null;
            }
        > | null;
      };
    } | null;
  } | null;
};

export type ParagraphButtonFragment = {
  __typename?: 'ParagraphButton';
  label: string;
  variant: string;
  link: string;
};

export type ParagraphMediaFragment = {
  __typename?: 'ParagraphMedia';
  caption?: string | null;
  id: string;
  media:
    | {
        __typename?: 'MediaImage';
        id: string;
        image: {
          __typename?: 'Image';
          id?: string | null;
          alt?: string | null;
          thumbnail?: string | null;
          responsiveStyle?: {
            __typename?: 'ResponsiveImageStyle';
            fallback?: string | null;
            sources: Array<{
              __typename?: 'ResponsiveImageSource';
              media?: string | null;
              sizes?: string[] | null;
              srcset: string[];
              type: string;
            }>;
          } | null;
        };
      }
    | {
        __typename?: 'MediaVideoEmbed';
        id: string;
        video: { __typename?: 'VideoEmbed'; type: VideoEmbedType; url: string };
      };
};

export type ParagraphMediaGalleryFragment = {
  __typename?: 'ParagraphMediaGallery';
  items?: Array<{
    __typename?: 'ParagraphMedia';
    caption?: string | null;
    id: string;
    media:
      | {
          __typename?: 'MediaImage';
          id: string;
          image: {
            __typename?: 'Image';
            id?: string | null;
            alt?: string | null;
            thumbnail?: string | null;
            responsiveStyle?: {
              __typename?: 'ResponsiveImageStyle';
              fallback?: string | null;
              sources: Array<{
                __typename?: 'ResponsiveImageSource';
                media?: string | null;
                sizes?: string[] | null;
                srcset: string[];
                type: string;
              }>;
            } | null;
          };
        }
      | {
          __typename?: 'MediaVideoEmbed';
          id: string;
          video: { __typename?: 'VideoEmbed'; type: VideoEmbedType; url: string };
        };
  }> | null;
};

export type ParagraphTextFragment = { __typename?: 'ParagraphText'; text: string };

export type ParagraphTitleTextFragment = {
  __typename?: 'ParagraphTitleText';
  title?: string | null;
  fullText?: string | null;
};

export type MarketingBlockQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
}>;

export type MarketingBlockQuery = {
  __typename?: 'Query';
  siteSettings?: {
    __typename?: 'SiteSettings';
    MarketingBlock?: {
      __typename?: 'MarketingBlock';
      title?: string | null;
      text?: string | null;
      shouldShowLoggedIn?: boolean | null;
      link?: { __typename?: 'Link'; title: string; href: string } | null;
    } | null;
  } | null;
};

export type MediaImageFragment = {
  __typename?: 'MediaImage';
  image: {
    __typename?: 'Image';
    id?: string | null;
    alt?: string | null;
    thumbnail?: string | null;
  };
};

export type MediaImageContentFragment = {
  __typename?: 'MediaImage';
  image: {
    __typename?: 'Image';
    responsiveStyle?: {
      __typename?: 'ResponsiveImageStyle';
      fallback?: string | null;
      sources: Array<{
        __typename?: 'ResponsiveImageSource';
        media?: string | null;
        sizes?: string[] | null;
        srcset: string[];
        type: string;
      }>;
    } | null;
  };
};

export type MediaImageGalleryFragment = {
  __typename?: 'MediaImage';
  image: {
    __typename?: 'Image';
    responsiveStyle?: {
      __typename?: 'ResponsiveImageStyle';
      fallback?: string | null;
      sources: Array<{
        __typename?: 'ResponsiveImageSource';
        media?: string | null;
        sizes?: string[] | null;
        srcset: string[];
        type: string;
      }>;
    } | null;
  };
};

export type MediaImageOnboardingFragment = {
  __typename?: 'MediaImage';
  image: {
    __typename?: 'Image';
    responsiveStyle?: {
      __typename?: 'ResponsiveImageStyle';
      fallback?: string | null;
      sources: Array<{
        __typename?: 'ResponsiveImageSource';
        media?: string | null;
        sizes?: string[] | null;
        srcset: string[];
        type: string;
      }>;
    } | null;
  };
};

export type MediaVideoFragment = {
  __typename?: 'MediaVideoEmbed';
  video: { __typename?: 'VideoEmbed'; type: VideoEmbedType; url: string };
};

export type MenuPageQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type MenuPageQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id?: string | null;
    menuPage?: {
      __typename?: 'MenuPage';
      id: string;
      title: string;
      children?: Array<
        | {
            __typename?: 'MenuPageBlockCLNLink';
            id: string;
            commentaryLawNavigationId: string;
            parent: string;
            title: string;
            publicationIdentifier?: string | null;
          }
        | {
            __typename?: 'MenuPageBlockCustomLink';
            id: string;
            href: string;
            parent: string;
            title: string;
          }
        | {
            __typename?: 'MenuPageBlockSwsDocumentLink';
            id: string;
            swsDocumentId: string;
            parent: string;
            title: string;
          }
        | {
            __typename?: 'MenuPageColumnType';
            id: string;
            column?: MenuPageBlockColumn | null;
            parent: string;
            navigationId?: string | null;
            title: string;
            type: string;
            variant: MenuPageBlockType;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export type MenuPageTitleQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type MenuPageTitleQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id?: string | null;
    menuPage?: { __typename?: 'MenuPage'; id: string; title: string } | null;
  } | null;
};

export type PaywallButtonFragment = {
  __typename?: 'ParagraphButton';
  id: string;
  label: string;
  link: string;
  variant: string;
};

export type PaywallNoAccessQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
}>;

export type PaywallNoAccessQuery = {
  __typename?: 'Query';
  siteSettings?: {
    __typename?: 'SiteSettings';
    paywall?: {
      __typename?: 'Paywall';
      noAccess?: {
        __typename?: 'NoAccessPaywall';
        id: string;
        title: string;
        text: string;
        upsell?: string | null;
        buttons?: Array<{
          __typename?: 'ParagraphButton';
          id: string;
          label: string;
          link: string;
          variant: string;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type PaywallNoSubscriptionQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
}>;

export type PaywallNoSubscriptionQuery = {
  __typename?: 'Query';
  siteSettings?: {
    __typename?: 'SiteSettings';
    paywall?: {
      __typename?: 'Paywall';
      notWithinSubscription?: {
        __typename?: 'NotWithinSubscriptionUserPaywall';
        id: string;
        title: string;
        text: string;
        upsell?: string | null;
        buttons?: Array<{
          __typename?: 'ParagraphButton';
          id: string;
          label: string;
          link: string;
          variant: string;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type PaywallUnknownUserQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
}>;

export type PaywallUnknownUserQuery = {
  __typename?: 'Query';
  siteSettings?: {
    __typename?: 'SiteSettings';
    paywall?: {
      __typename?: 'Paywall';
      unknownUser?: {
        __typename?: 'UnknownUserPaywall';
        id: string;
        title: string;
        text: string;
        upsell?: string | null;
        buttons?: Array<{
          __typename?: 'ParagraphButton';
          id: string;
          label: string;
          link: string;
          variant: string;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type WhatsNewQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
}>;

export type WhatsNewQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id?: string | null;
    releaseNotes?: {
      __typename?: 'ReleaseNotes';
      totalCount: number;
      results?: Array<{
        __typename?: 'ReleaseNote';
        id: string;
        title: string;
        version: string;
        text: string;
        publishDate: string;
      }> | null;
    } | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
}>;

export type NavigationQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id?: string | null;
    navigation?: {
      __typename?: 'Navigation';
      children?: Array<
        | {
            __typename?: 'NavigationCustomLink';
            id: string;
            href: string;
            icon?: string | null;
            isHighlightedItem: boolean;
            parent: string;
            title: string;
          }
        | {
            __typename?: 'NavigationLinkSwsDocumentLink';
            id: string;
            icon?: string | null;
            isHighlightedItem: boolean;
            parent: string;
            swsDocumentId: string;
            title: string;
          }
        | {
            __typename?: 'NavigationLinkWithChildren';
            id: string;
            icon?: string | null;
            isHighlighted: boolean;
            isHighlightedItem: boolean;
            parent: string;
            title: string;
          }
        | {
            __typename?: 'NavigationMenuPageLink';
            id: string;
            icon?: string | null;
            isHighlightedItem: boolean;
            menuPageId: string;
            parent: string;
            publicationIdentifier?: string | null;
            slug: string;
            title: string;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export const ParagraphButtonFragmentDoc = gql`
  fragment ParagraphButton on ParagraphButton {
    label
    variant
    link
  }
`;
export const MediaImageFragmentDoc = gql`
  fragment MediaImage on MediaImage {
    image {
      id
      alt
      thumbnail: imageStyle(style: thumbnail)
    }
  }
`;
export const MediaImageContentFragmentDoc = gql`
  fragment MediaImageContent on MediaImage {
    image {
      responsiveStyle(style: full_width_content_image) {
        fallback
        sources {
          media
          sizes
          srcset
          type
        }
      }
    }
  }
`;
export const MediaVideoFragmentDoc = gql`
  fragment MediaVideo on MediaVideoEmbed {
    video {
      type
      url
    }
  }
`;
export const ParagraphMediaFragmentDoc = gql`
  fragment ParagraphMedia on ParagraphMedia {
    caption
    id
    media {
      id
      ...MediaImage
      ...MediaImageContent
      ...MediaVideo
    }
  }
  ${MediaImageFragmentDoc}
  ${MediaImageContentFragmentDoc}
  ${MediaVideoFragmentDoc}
`;
export const MediaImageGalleryFragmentDoc = gql`
  fragment MediaImageGallery on MediaImage {
    image {
      responsiveStyle(style: gallery_image) {
        fallback
        sources {
          media
          sizes
          srcset
          type
        }
      }
    }
  }
`;
export const ParagraphMediaGalleryFragmentDoc = gql`
  fragment ParagraphMediaGallery on ParagraphMediaGallery {
    items {
      caption
      id
      media {
        id
        ...MediaImage
        ...MediaImageGallery
        ...MediaVideo
      }
    }
  }
  ${MediaImageFragmentDoc}
  ${MediaImageGalleryFragmentDoc}
  ${MediaVideoFragmentDoc}
`;
export const ParagraphTextFragmentDoc = gql`
  fragment ParagraphText on ParagraphText {
    text
  }
`;
export const ParagraphTitleTextFragmentDoc = gql`
  fragment ParagraphTitleText on ParagraphTitleText {
    title
    fullText: text
  }
`;
export const MediaImageOnboardingFragmentDoc = gql`
  fragment MediaImageOnboarding on MediaImage {
    image {
      responsiveStyle(style: onboarding_image) {
        fallback
        sources {
          media
          sizes
          srcset
          type
        }
      }
    }
  }
`;
export const PaywallButtonFragmentDoc = gql`
  fragment PaywallButton on ParagraphButton {
    id
    label
    link
    variant
  }
`;
export const BasicPageDocument = gql`
  query BasicPage($key: String, $path: String) {
    site(key: $key) {
      id
      router(path: $path) {
        id
        ... on NodeMatch {
          entity {
            ... on BasicPage {
              title
              id
              content {
                id
                ...ParagraphButton
                ...ParagraphTitleText
                ...ParagraphText
                ...ParagraphMediaGallery
                ...ParagraphMedia
              }
            }
          }
        }
      }
    }
  }
  ${ParagraphButtonFragmentDoc}
  ${ParagraphTitleTextFragmentDoc}
  ${ParagraphTextFragmentDoc}
  ${ParagraphMediaGalleryFragmentDoc}
  ${ParagraphMediaFragmentDoc}
`;
export type BasicPageQueryResult = Apollo.QueryResult<BasicPageQuery, BasicPageQueryVariables>;
export const MarketingBlockDocument = gql`
  query MarketingBlock($key: String) {
    siteSettings(key: $key) {
      MarketingBlock {
        title
        text
        shouldShowLoggedIn
        link {
          title
          href
        }
      }
    }
  }
`;
export type MarketingBlockQueryResult = Apollo.QueryResult<
  MarketingBlockQuery,
  MarketingBlockQueryVariables
>;
export const MenuPageDocument = gql`
  query MenuPage($key: String, $slug: String) {
    site(key: $key) {
      id
      menuPage(path: $slug) {
        id
        title
        children {
          ... on MenuPageColumnType {
            id
            column
            parent
            navigationId
            title
            type
            variant
          }
          ... on MenuPageBlockCustomLink {
            id
            href
            parent
            title
          }
          ... on MenuPageBlockSwsDocumentLink {
            id
            swsDocumentId
            parent
            title
          }
          ... on MenuPageBlockCLNLink {
            id
            commentaryLawNavigationId
            parent
            title
            publicationIdentifier
          }
        }
      }
    }
  }
`;
export type MenuPageQueryResult = Apollo.QueryResult<MenuPageQuery, MenuPageQueryVariables>;
export const MenuPageTitleDocument = gql`
  query MenuPageTitle($key: String, $slug: String) {
    site(key: $key) {
      id
      menuPage(path: $slug) {
        id
        title
      }
    }
  }
`;
export type MenuPageTitleQueryResult = Apollo.QueryResult<
  MenuPageTitleQuery,
  MenuPageTitleQueryVariables
>;
export const PaywallNoAccessDocument = gql`
  query PaywallNoAccess($key: String) {
    siteSettings(key: $key) {
      paywall {
        noAccess {
          id
          title
          text
          buttons {
            ...PaywallButton
          }
          upsell
        }
      }
    }
  }
  ${PaywallButtonFragmentDoc}
`;
export type PaywallNoAccessQueryResult = Apollo.QueryResult<
  PaywallNoAccessQuery,
  PaywallNoAccessQueryVariables
>;
export const PaywallNoSubscriptionDocument = gql`
  query PaywallNoSubscription($key: String) {
    siteSettings(key: $key) {
      paywall {
        notWithinSubscription {
          id
          title
          text
          buttons {
            ...PaywallButton
          }
          upsell
        }
      }
    }
  }
  ${PaywallButtonFragmentDoc}
`;
export type PaywallNoSubscriptionQueryResult = Apollo.QueryResult<
  PaywallNoSubscriptionQuery,
  PaywallNoSubscriptionQueryVariables
>;
export const PaywallUnknownUserDocument = gql`
  query PaywallUnknownUser($key: String) {
    siteSettings(key: $key) {
      paywall {
        unknownUser {
          id
          title
          text
          buttons {
            ...PaywallButton
          }
          upsell
        }
      }
    }
  }
  ${PaywallButtonFragmentDoc}
`;
export type PaywallUnknownUserQueryResult = Apollo.QueryResult<
  PaywallUnknownUserQuery,
  PaywallUnknownUserQueryVariables
>;
export const WhatsNewDocument = gql`
  query WhatsNew($key: String, $version: String!) {
    site(key: $key) {
      id
      releaseNotes(version: $version) {
        totalCount
        results {
          id
          title
          version
          text
          publishDate
        }
      }
    }
  }
`;
export type WhatsNewQueryResult = Apollo.QueryResult<WhatsNewQuery, WhatsNewQueryVariables>;
export const NavigationDocument = gql`
  query Navigation($key: String) {
    site(key: $key) {
      id
      navigation {
        children {
          ... on NavigationLinkWithChildren {
            id
            icon
            isHighlighted
            isHighlightedItem
            parent
            title
          }
          ... on NavigationLinkSwsDocumentLink {
            id
            icon
            isHighlightedItem
            parent
            swsDocumentId
            title
          }
          ... on NavigationCustomLink {
            id
            href
            icon
            isHighlightedItem
            parent
            title
          }
          ... on NavigationMenuPageLink {
            id
            icon
            isHighlightedItem
            menuPageId
            parent
            publicationIdentifier
            slug
            title
          }
        }
      }
    }
  }
`;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
