import { Button, Link, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import type * as React from 'react';

import { ErrorComponent } from '../ErrorComponent/ErrorComponent';

interface IErrorBoundaryPageFallback {
  isFullScreen?: boolean;
  brandName?: string;
  logo?: string;
  button?: React.ReactElement;
}

const ErrorBoundaryPageFallback = (props: IErrorBoundaryPageFallback) => {
  useLingui();
  return (
    <ErrorComponent
      button={
        <Button href="/">
          <Trans>Terug naar home</Trans>
        </Button>
      }
      content={
        <Text type="paragraph">
          <Trans>
            Het lijkt erop dat er onverwacht iets fout is gegaan. Probeer de pagina{' '}
            <Link onClick={() => window.location.reload()}>opnieuw te laden</Link> of probeer later
            de pagina opnieuw te openen. Je kan ook contact met ons opnemen via de feedback knop
            rechts in het scherm, of telefonisch via <Link to="tel:0703789313">070-378 9313</Link>
          </Trans>
        </Text>
      }
      description={t`Het lijkt erop dat er onverwacht iets fout is gegaan.`}
      title={t`Helaas ging er iets fout`}
      {...props}
    />
  );
};

export { ErrorBoundaryPageFallback };
