import analytics from 'analytics';
import { useMemo } from 'react';
import { AnalyticsProvider as AnalyticsProviderComponent } from 'use-analytics';

import { addToDataLayerPlugin } from '../../helpers/analytics/addToDataLayerPlugin';
import { searchDataLayerPlugin } from '../../helpers/analytics/searchDataLayerPlugin';
import { swsDataLayerPlugin } from '../../helpers/analytics/swsDataLayerPlugin';
import { useSiteConfig } from '../SiteConfigProvider';
interface AnalyticsProviderProps {
  plugins?: any[];
}
const AnalyticsProvider = ({
  children,
  plugins,
}: React.PropsWithChildren<AnalyticsProviderProps>) => {
  const { brandName } = useSiteConfig();

  const analyticsInstance = useMemo(
    () =>
      analytics({
        app: brandName,
        plugins: [
          addToDataLayerPlugin(),
          swsDataLayerPlugin(),
          searchDataLayerPlugin(),
          ...(plugins || []),
        ],
      }),
    [brandName, plugins],
  );
  return (
    <AnalyticsProviderComponent instance={analyticsInstance}>{children}</AnalyticsProviderComponent>
  );
};

export { AnalyticsProvider };
