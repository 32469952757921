import { CONTENT_EXPORT_TYPES } from '../ContentExportPlugin/types';
export const defaultInitialState = {
    isLoading: false,
    isError: false,
    treeOptions: [],
    treeIds: [],
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING': {
            return Object.assign(Object.assign({}, state), { isLoading: true, isError: false });
        }
        case 'SET_ERROR': {
            return Object.assign(Object.assign({}, state), { isLoading: false, isError: true });
        }
        case 'SET_MUTATION_DATA': {
            return Object.assign(Object.assign({}, state), { jobId: action.payload.jobId, token: action.payload.token });
        }
        case 'RESET': {
            return Object.assign(Object.assign({}, defaultInitialState), { limit: state.limit, defaultCanExportFullDocument: state.defaultCanExportFullDocument, canExportFullDocument: state.defaultCanExportFullDocument, canExportParts: state.canExportParts, treeOptions: state.treeOptions, treeIds: state.treeIds });
        }
        case 'SET_CONTENT_PARTS': {
            return Object.assign(Object.assign({}, state), { contentParts: action.payload.contentParts });
        }
        case 'RESET_CONTENT_PARTS': {
            return Object.assign(Object.assign({}, state), { contentParts: state.isAllChecked ? state.treeIds : [] });
        }
        case 'TOGGLE_SELECT_ALL': {
            return Object.assign(Object.assign({}, state), { isAllChecked: action.payload.isChecked });
        }
        case 'SET_TREE_OPTIONS': {
            return Object.assign(Object.assign({}, state), { treeOptions: action.payload.treeOptions });
        }
        case 'SET_TREE_IDS': {
            const hasTooManyParts = Boolean(state.limit && action.payload.treeIds.length > state.limit);
            return Object.assign(Object.assign({}, state), { treeIds: action.payload.treeIds, canExportFullDocument: state.type === CONTENT_EXPORT_TYPES.SHARE_TO_TAXVICE
                    ? state.defaultCanExportFullDocument
                    : state.defaultCanExportFullDocument && !hasTooManyParts });
        }
        case 'SET_DOCUMENT_TITLE': {
            return Object.assign(Object.assign({}, state), { documentTitle: action.payload.title });
        }
        default:
            return state;
    }
};
export { reducer };
