import { create } from 'zustand';

import type { CheckboxOptionPropsByValue } from '../helpers/getCheckboxOptionsByValue';

/**
 * Type representing a facet value with its corresponding label.
 */
type FacetValueWithLabel = {
  label: string;
  value: string;
};

/**
 * Type representing a dictionary of facet values with their corresponding labels.
 */
type FacetValuesWithLabel = {
  [key: string]: FacetValueWithLabel;
};

/**
 * Type representing a dictionary of facets with their corresponding labeled values.
 */
type FacetsWithLabels = {
  [key: string]: FacetValuesWithLabel;
};

/**
 * Type representing the structure of the alert store.
 */
export type AlertStore = {
  facetsWithLabels?: FacetsWithLabels;
  setFacetsWithLabels: (facet: string, facetValuesWithLabel: CheckboxOptionPropsByValue) => void;
  resetFacetsWithLabels: () => void;
};

/**
 * Zustand store to manage the state of alert facets with labels.
 */
const useAlertStore = create<AlertStore>((set) => ({
  facetsWithLabels: undefined,

  /**
   * Sets the facet values with their corresponding labels in the store.
   *
   * @param {string} facet - The name of the facet.
   * @param {CheckboxOptionPropsByValue} facetValuesWithLabel - The facet values with their corresponding labels.
   */
  setFacetsWithLabels: (facet, facetValuesWithLabel) =>
    set((state) => ({
      facetsWithLabels: {
        ...(state.facetsWithLabels || {}),
        [facet]: facetValuesWithLabel,
      },
    })),

  /**
   * Resets the facets with labels in the store to undefined.
   */
  resetFacetsWithLabels: () =>
    set(() => ({
      facetsWithLabels: undefined,
    })),
}));

export { useAlertStore };
