import {
  CONTENT_EXPORT_TYPES,
  ContentExportDrawer,
  ContentExportPluginDockIconItem,
  ContentExportPluginDockMenuItem,
} from '@elseu/sdu-titan-content-export-react';
import type { DockItemData } from '@elseu/sdu-titan-content-rendering';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { canUseContentExportPluginResult } from './ContentExportHelper';

const LIMIT = 250;

interface DownloadPluginDockItemProps {
  itemData: DockItemData;
  onClick: () => void;
}

const DownloadPluginDockMenuItem = ({ itemData, onClick }: DownloadPluginDockItemProps) => {
  useLingui();
  const { data, isMobile } = itemData;
  const { canExport } = canUseContentExportPluginResult(data);

  if (isMobile || !canExport) return null;

  return (
    <ContentExportPluginDockMenuItem
      labelPluginButton={t`Downloaden`}
      type={CONTENT_EXPORT_TYPES.DOWNLOAD}
      onClick={onClick}
    />
  );
};

const DownloadPluginDockIconItem = ({ itemData, onClick }: DownloadPluginDockItemProps) => {
  const { data, isMobile } = itemData;
  const { canExport } = canUseContentExportPluginResult(data);

  if (isMobile || !canExport) return null;

  return (
    <ContentExportPluginDockIconItem
      labelPluginButton={t`Downloaden`}
      type={CONTENT_EXPORT_TYPES.DOWNLOAD}
      onClick={onClick}
    />
  );
};

interface DownloadPluginDockComponentsProps {
  itemData: DockItemData;
  isShown: boolean;
  onDrawerToggle: (isShown: boolean) => void;
  brandingKey?: string;
}
const DownloadPluginDockComponents = ({
  brandingKey,
  itemData,
  isShown,
  onDrawerToggle,
}: DownloadPluginDockComponentsProps) => {
  const { i18n } = useLingui();
  const { data, isMobile } = itemData;
  const { swsId, activeContentPart } = data;
  const { canExport, canExportFullDocument, canExportParts } = canUseContentExportPluginResult(
    itemData.data,
  );

  if (isMobile || !canExport || !swsId) return null;

  return (
    <ContentExportDrawer
      activeId={activeContentPart}
      brandingKey={brandingKey}
      canExportFullDocument={canExportFullDocument}
      canExportParts={canExportParts}
      isShown={isShown}
      labelCancelButtonTitle={<Trans>Annuleren</Trans>}
      labelCannotSaveDocument={<Trans>Je kunt dit document helaas niet downloaden</Trans>}
      labelDrawerErrorButton={<Trans>Opnieuw downloaden</Trans>}
      labelDrawerErrorDescription={
        <Trans>
          Er is een fout opgetreden bij het ophalen van de downloadbare onderdelen. Probeer het
          later opnieuw.
        </Trans>
      }
      labelDrawerErrorHeader={<Trans>Er is een fout opgetreden</Trans>}
      labelDrawerHeader={<Trans>Downloaden</Trans>}
      labelDrawerLoader={<Trans>Downloadbare onderdelen ophalen</Trans>}
      labelDrawerSubtitle={<Trans>Selecteer de onderdelen die je wilt downloaden</Trans>}
      labelhasTooManyParts={({ limit }) => (
        <Trans>
          Maximaal {limit} onderdelen mogen in één keer gedownload worden. Pas deselectie aan om
          verder te gaan.
        </Trans>
      )}
      labelSaveButtonTitle={<Trans>Downloaden</Trans>}
      labelSelectAll={t`Volledige document`}
      limit={LIMIT}
      locale={i18n.locale}
      swsId={swsId}
      type={CONTENT_EXPORT_TYPES.DOWNLOAD}
      onDrawerToggle={onDrawerToggle}
    />
  );
};

export { DownloadPluginDockIconItem, DownloadPluginDockMenuItem };
export default DownloadPluginDockComponents;
