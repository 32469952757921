import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var AlertStatus;
(function (AlertStatus) {
    AlertStatus["DataProviderError"] = "DATA_PROVIDER_ERROR";
    AlertStatus["EmailSendingFailed"] = "EMAIL_SENDING_FAILED";
    AlertStatus["NoData"] = "NO_DATA";
    AlertStatus["Success"] = "SUCCESS";
    AlertStatus["UserServiceError"] = "USER_SERVICE_ERROR";
})(AlertStatus || (AlertStatus = {}));
export var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek["Friday"] = "FRIDAY";
    DayOfWeek["Monday"] = "MONDAY";
    DayOfWeek["Saturday"] = "SATURDAY";
    DayOfWeek["Sunday"] = "SUNDAY";
    DayOfWeek["Thursday"] = "THURSDAY";
    DayOfWeek["Tuesday"] = "TUESDAY";
    DayOfWeek["Wednesday"] = "WEDNESDAY";
})(DayOfWeek || (DayOfWeek = {}));
export const AlertBaseFragmentDoc = gql `
  fragment AlertBase on Alert {
    id
    name
    enabled
    grouped
    schedule
  }
`;
export const AlertExtendedFragmentDoc = gql `
  fragment AlertExtended on Alert {
    parameters
    referenceId
    created
    lastModified
  }
`;
export const AlertFragmentDoc = gql `
  fragment Alert on Alert {
    ...AlertBase
    ...AlertExtended
  }
  ${AlertBaseFragmentDoc}
  ${AlertExtendedFragmentDoc}
`;
export const CreateAlertDocument = gql `
  mutation CreateAlert($profile: String!, $data: AlertInput!) {
    createAlert(profile: $profile, data: $data) {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;
/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAlertMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateAlertDocument, options);
}
export const DeleteAlertDocument = gql `
  mutation DeleteAlert($profile: String!, $alertId: ID!) {
    deleteAlert(profile: $profile, alertId: $alertId) {
      ...AlertBase
    }
  }
  ${AlertBaseFragmentDoc}
`;
/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useDeleteAlertMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteAlertDocument, options);
}
export const ToggleAlertDocument = gql `
  mutation ToggleAlert($profile: String!, $alertId: ID!, $enabled: Boolean!) {
    toggleAlert(profile: $profile, alertId: $alertId, enabled: $enabled) {
      ...AlertBase
    }
  }
  ${AlertBaseFragmentDoc}
`;
/**
 * __useToggleAlertMutation__
 *
 * To run a mutation, you first call `useToggleAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAlertMutation, { data, loading, error }] = useToggleAlertMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      alertId: // value for 'alertId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleAlertMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ToggleAlertDocument, options);
}
export const UpdateAlertDocument = gql `
  mutation UpdateAlert($profile: String!, $alertId: ID!, $data: AlertInput!) {
    updateAlert(profile: $profile, alertId: $alertId, data: $data) {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;
/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      alertId: // value for 'alertId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAlertMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateAlertDocument, options);
}
export const AlertDocument = gql `
  query Alert($profile: String!, $alertId: ID!) {
    alert(profile: $profile, alertId: $alertId) {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;
/**
 * __useAlertQuery__
 *
 * To run a query within a React component, call `useAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useAlertQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AlertDocument, options);
}
export function useAlertLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AlertDocument, options);
}
export const AlertsDocument = gql `
  query Alerts($profile: String!) {
    alerts(profile: $profile) {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;
/**
 * __useAlertsQuery__
 *
 * To run a query within a React component, call `useAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useAlertsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AlertsDocument, options);
}
export function useAlertsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AlertsDocument, options);
}
export const QuickAlertDocument = gql `
  query QuickAlert($profile: String!, $referenceId: ID!) {
    alert(profile: $profile, referenceId: $referenceId) {
      id
      name
      enabled
    }
  }
`;
/**
 * __useQuickAlertQuery__
 *
 * To run a query within a React component, call `useQuickAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickAlertQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useQuickAlertQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(QuickAlertDocument, options);
}
export function useQuickAlertLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(QuickAlertDocument, options);
}
