import type { DockItemData } from '@elseu/sdu-titan-content-rendering';
import {
  ReadingListsPluginComponents,
  ReadingListsPluginDockIconItem as ReadingListsPluginDockIconItemComponent,
} from '@elseu/sdu-titan-reading-lists-react';

interface ReadingListsPluginDockMenuItemProps {
  itemData: DockItemData;
  isFilled: boolean;
  onClick: () => void;
}

const ReadingListsPluginDockIconItem = ({
  itemData,
  isFilled,
  onClick,
}: ReadingListsPluginDockMenuItemProps) => {
  const { data, canHavePersonalisationFeatures } = itemData;
  const { swsId, swsDocumentTitle } = data;

  if (!canHavePersonalisationFeatures || !swsId || !swsDocumentTitle) return null;

  return <ReadingListsPluginDockIconItemComponent isFilled={isFilled} onClick={onClick} />;
};

interface ReadingListsPluginDockComponentsProps {
  itemData: DockItemData;
  isShown?: boolean;
  onClose?: () => void;
  onQueryCompleted?: (hasDocument: boolean) => void;
}
const ReadingListsPluginDockComponents = ({
  itemData,
  isShown,
  onClose,
  onQueryCompleted,
}: ReadingListsPluginDockComponentsProps) => {
  const { data, canHavePersonalisationFeatures } = itemData;
  const { swsId, swsDocumentTitle } = data;

  if (!canHavePersonalisationFeatures || !swsId || !swsDocumentTitle) return null;

  return (
    <ReadingListsPluginComponents
      isShown={isShown}
      itemReference={swsId}
      title={swsDocumentTitle}
      onClose={onClose}
      onQueryCompleted={onQueryCompleted}
    />
  );
};

export { ReadingListsPluginDockIconItem };
export default ReadingListsPluginDockComponents;
