import type { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import type { Locale } from '@lingui/core';
import { i18n } from '@lingui/core';
import { I18nProvider, useLingui } from '@lingui/react';
import React, { useEffect, useMemo } from 'react';

import type { AccessToken } from '../hooks/useReadingListsClient';
import { useReadingListsClient } from '../hooks/useReadingListsClient';
import { dynamicActivate } from '../translations/utils';

interface ReadingListsContextProps {
  /** Site name key */
  siteId: string;
  /** Application  key */
  applicationKey: string;
  /* Base url for to the reading lists overview */
  baseUrl: string;
  /* Base url for  the documents inside the reading list */
  documentUrl: string | ((id: string) => string);
  /* Whether the reading list has user search within the sharing panel */
  hasUserSearch?: boolean;
  /** UserId associated with the logged in account */
  userId?: string;
  /* A custom apollo client */
  client?: ApolloClient<NormalizedCacheObject>;
  /* A custom error component */
  errorComponent?: React.FC<React.PropsWithChildren<{ statusCode?: number }>>;
  /** i18n config */
  locale?: Locale;
  /** Date format function */
  dateFormatFn: (value: string | Date, format?: Intl.DateTimeFormatOptions) => string;
  /** Number format function */
  numberFormatFn: (value: number, format?: Intl.NumberFormatOptions) => string;
}

export interface ReadingListsProviderProps {
  /** Site name key */
  siteId: string;
  /** Application  key */
  applicationKey: string;
  /** GraphQL Endpoint url */
  graphqlUrl: string;
  /* Base url for to the reading lists overview */
  baseUrl: string;
  /* Base url for  the documents inside the reading list */
  documentUrl: string | ((id: string) => string);
  /* Whether the reading list has user search within the sharing panel */
  hasUserSearch?: boolean;
  /* The bearer accessToken */
  accessToken?: AccessToken;
  /* A custom error component */
  errorComponent?: React.FC<{ statusCode?: number }>;
  /* Apollo initial state from the cache */
  apolloInitialState?: NormalizedCacheObject;
  /* Register cache function from apollo */
  apolloRegisterCache?: (cache: InMemoryCache) => void;
  /* Custom fetch implementation, like node-fetch or cross-fetch */
  fetch?: WindowOrWorkerGlobalScope['fetch'];
  /* A custom apollo client */
  client?: ApolloClient<NormalizedCacheObject>;
  /** i18n config */
  locale?: Locale;
  /** UserId associated with the logged in account */
  userId?: string;
  /** Whether to use an external i18n provider */
  hasExternalI18nProvider?: boolean;
}

const ReadingListsContext = React.createContext<ReadingListsContextProps | undefined>(
  {} as ReadingListsContextProps,
);

const useReadingLists = () => {
  const context = React.useContext(ReadingListsContext);
  if (context === undefined) {
    throw new Error('useReadingLists must be used within a ReadingListsProvider');
  }
  return context;
};

const I18nProviderWrapper: React.FC<
  React.PropsWithChildren<Pick<ReadingListsProviderProps, 'hasExternalI18nProvider' | 'locale'>>
> = ({ locale, hasExternalI18nProvider, children }) => {
  useEffect(() => {
    if (!hasExternalI18nProvider) dynamicActivate(locale);
  }, [hasExternalI18nProvider, locale]);
  if (hasExternalI18nProvider) return children;
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

const ReadingListsProvider: React.FC<React.PropsWithChildren<ReadingListsProviderProps>> = ({
  children,
  ...props
}) => {
  return (
    <I18nProviderWrapper {...props}>
      <ReadingListsProviderContext {...props}>{children}</ReadingListsProviderContext>
    </I18nProviderWrapper>
  );
};

const ReadingListsProviderContext: React.FC<React.PropsWithChildren<ReadingListsProviderProps>> = ({
  siteId,
  applicationKey,
  baseUrl,
  documentUrl,
  graphqlUrl,
  accessToken,
  hasUserSearch,
  errorComponent,
  apolloInitialState,
  apolloRegisterCache,
  fetch,
  client: readingListsClient,
  userId,
  locale,
  children,
}) => {
  const { i18n } = useLingui();
  const client = useReadingListsClient({
    graphqlUrl,
    accessToken,
    initialState: apolloInitialState,
    registerCache: apolloRegisterCache,
    fetch,
  });

  const context = useMemo<ReadingListsContextProps>(
    () => ({
      siteId,
      applicationKey,
      client: !process.env.JEST_WORKER_ID ? client : readingListsClient,
      baseUrl,
      documentUrl,
      errorComponent,
      hasUserSearch,
      userId,
      locale,
      dateFormatFn: (value, options) => i18n.date(value, options),
      numberFormatFn: (value, options) => i18n.number(value, options),
    }),
    [
      siteId,
      applicationKey,
      client,
      readingListsClient,
      baseUrl,
      documentUrl,
      errorComponent,
      hasUserSearch,
      userId,
      locale,
      i18n,
    ],
  );

  return <ReadingListsContext.Provider value={context}>{children}</ReadingListsContext.Provider>;
};

export { ReadingListsProvider, useReadingLists };
