import { withTitan } from '@elseu/sdu-titan';
import styled from 'styled-components';

import { ErrorBoundary } from '../components/Error/ErrorBoundary/ErrorBoundary';
import { ErrorBoundaryPageFallback } from '../components/Error/ErrorBoundaryPageFallback/ErrorBoundaryPageFallback';
import { sentryDialogOptions } from '../helpers/sentryDialogOptions';

const Main = styled.main`
  display: block;
`;

export const BaseLayoutSimple = withTitan(
  ({ children, testId = 'baseLayoutSimple' }) => {
    return (
      <div data-test-id={testId}>
        <Main id="content">
          <ErrorBoundary
            showDialog
            dialogOptions={sentryDialogOptions()}
            fallback={<ErrorBoundaryPageFallback />}
          >
            {children}
          </ErrorBoundary>
        </Main>
      </div>
    );
  },
  { name: 'BaseLayoutSimple' },
);
