const defaultConfig = {
    idField: 'id',
    childrenField: 'children',
};
export function deptFirstSearch(id, data = [], config = defaultConfig) {
    const { idField, childrenField } = config;
    let found;
    for (const [i] of data.entries()) {
        // @ts-expect-error ignore this error
        if (data[i][idField] === id)
            return data[i];
        // @ts-expect-error ignore this error
        else if (data[i][childrenField].length) {
            // @ts-expect-error ignore this error
            found = deptFirstSearch(id, data[i][childrenField], config);
            if (found)
                return found;
        }
    }
}
