import type { CheckboxOptionProps } from '@elseu/sdu-titan/dist/types/components/CheckboxGroup/CheckboxGroup';
import type { Bucket, Buckets } from '@elseu/sdu-titan-search';
import { useFacetContext } from '@elseu/sdu-titan-search';
import { useEffect, useMemo } from 'react';

import type { CheckboxOptionPropsByValue } from '../../helpers/getCheckboxOptionsByValue';
import { getCheckboxOptionsByValue } from '../../helpers/getCheckboxOptionsByValue';
import { useAlertStore } from '../../stores/AlertStore';

/**
 * Interface for the return value of useFacetField.
 */
interface UseFacetFieldProps {
  options: CheckboxOptionProps[];
  optionsByValue: CheckboxOptionPropsByValue;
}

/**
 * Transform an array of buckets into an array of CheckboxOptionProps.
 *
 * @param {Bucket[]} items - The buckets to transform.
 * @returns {CheckboxOptionProps[]} The transformed checkbox options.
 */
const getCheckboxOptions = (items: Bucket[]): CheckboxOptionProps[] =>
  items.map(({ label, compositeKey, selected, id, buckets }) => ({
    label,
    value: compositeKey,
    id,
    checked: selected,
    ...((buckets as Buckets | undefined) ? { children: getCheckboxOptions(buckets.values) } : {}),
  }));

/**
 * Custom hook to manage facet field options and their corresponding context.
 *
 * @param {string} name - The name of the facet field.
 * @returns {UseFacetFieldProps} The facet field options, options mapped by value, and the facet context.
 */
const useFacetField = (name: string): UseFacetFieldProps => {
  const facet = useFacetContext(name);
  const setFacetsWithLabels = useAlertStore((state) => state.setFacetsWithLabels);

  const options = useMemo(() => getCheckboxOptions(facet?.items || []), [facet?.items]);
  const optionsByValue = useMemo(() => getCheckboxOptionsByValue(options), [options]);

  /**
   * Save the labels and values of the options in a store,
   * so we can later append the labels to the parameters when a form field changes.
   */
  useEffect(() => {
    if (!Object.keys(optionsByValue).length) return;
    setFacetsWithLabels(name, optionsByValue);
  }, [name, optionsByValue, setFacetsWithLabels]);

  return useMemo(
    () => ({
      options,
      optionsByValue,
    }),
    [options, optionsByValue],
  );
};

export { useFacetField };
