import { markStyle, scrollbarStyle } from '@elseu/sdu-titan';
import { createGlobalStyle } from 'styled-components';

const GlobalSiteStyling = createGlobalStyle`
  @page { 
    size: A4;
  }
  
  html {
    height: 100%;
  }
  
  body {
    margin: 0;
    // Save some space for Safari toolbar
    // removing this causes bugs with links on bottom of the page opening modals or drawers
    padding-bottom: 80px; 
    -webkit-overflow-scrolling: touch
    ${scrollbarStyle.pageScrollbar};
    overflow-y: auto;
    
    .has-scroll-y & {
      overflow-y: scroll;
    }
  }

  #gdpr-toggle {
    display: none !important;
  }
  // Styles for mark.js
  ${markStyle.highlight};
`;

export { GlobalSiteStyling };
