import type { SearchFacetProps, TabWithKeys, TabWithOptionalLabels } from '@elseu/sdu-titan-search';
import type { I18n } from '@lingui/core';

import type { LabelMap, LabelMapTranslated } from './getTranslatedLabelDict';
import { facetLabelMap, getTranslatedLabelDict, tabLabelMap } from './getTranslatedLabelDict';

/**
 * Get the corresponding label for a tab
 * @param {TabWithOptionalLabels} tab Tab object
 * @param {LabelMapTranslated} labels Dictionary of ids and corresponding labels
 * @returns {string} The corresponding tab label
 */
export const getTranslatedTabLabel = (
  tab: TabWithOptionalLabels,
  labels: LabelMapTranslated,
): string => {
  // Look up corresponding translation in provided dictionary
  // or else fall back to label in original tabs preset, if preset.
  // Use tab id if everything else fails.
  return tab.id in labels ? (labels[tab.id] as string) : tab.label || tab.id;
};

/**
 * Get the corresponding label for a facet
 * @param {SearchFacetProps} facet Facet preset
 * @param {LabelMapTranslated} labels Dictionary of ids and corresponding labels
 * @returns {string} The corresponding facet label
 */
export const getTranslatedFacetLabel = (
  facet: SearchFacetProps,
  labels: LabelMapTranslated,
): string => {
  // Look up corresponding translation in provided dictionary
  // or else fall back to original label
  return facet.label in labels ? (labels[facet.label] as string) : facet.label;
};

/**
 * Enriches an array of presets with labels following the current locale.
 * @param {I18n} i18n An active i18n instance
 * @param {TabWithOptionalLabels[]} tabs Tabs configuration to be translated
 * @param {LabelMap} labelMap Dictionary of keys and corresponding MessageDescriptors
 * @param {Function} labelGetter A function that returns the corresponding label
 * @returns An array of objects with (translated) labels
 */
export function translateLabels<T>(
  i18n: I18n,
  items: T[],
  labelMap: LabelMap,
  labelGetter: (item: T, labels: LabelMapTranslated) => string,
) {
  const labels = getTranslatedLabelDict(i18n, labelMap);

  return items.map((item) => ({
    ...item,
    label: labelGetter(item, labels),
  }));
}

export const translateTabs = (
  i18n: I18n,
  tabs: TabWithOptionalLabels[],
  customTabLabelMap?: LabelMap,
): TabWithKeys[] =>
  translateLabels(i18n, tabs, { ...tabLabelMap, ...customTabLabelMap }, getTranslatedTabLabel);

export const translateFacets = (
  i18n: I18n,
  facets: SearchFacetProps[],
  customFacetLabelMap?: LabelMap,
): SearchFacetProps[] =>
  translateLabels(
    i18n,
    facets,
    { ...facetLabelMap, ...customFacetLabelMap },
    getTranslatedFacetLabel,
  );
