interface ICustomEventListener<T> {
  (evt: CustomEvent<T>): void;
}
function on<T>(type: string, listener: ICustomEventListener<T>) {
  document.addEventListener(type, listener as EventListener);
}

function off<T>(type: string, listener: ICustomEventListener<T>) {
  document.removeEventListener(type, listener as EventListener);
}

function trigger<T>(type: string, data: T) {
  const event = new CustomEvent(type, { detail: data });
  document.dispatchEvent(event);
}

export { off, on, trigger };
