import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Button, Flex, Loader, StarFilledIcon, StarOutlineIcon } from '@elseu/sdu-titan';
import { useCallback, useState } from 'react';
import { useCreateAlertMutation, useDeleteAlertMutation, useQuickAlertQuery, useToggleAlertMutation, } from '../../generated/graphql';
import { toSchedule } from '../../helpers/schedule';
import { useAlerts } from '../../hooks/useAlerts';
import { PopoutButton } from '../PopoutButton/PopoutButton';
export var RemoveMode;
(function (RemoveMode) {
    RemoveMode["Delete"] = "delete";
    RemoveMode["Deactivate"] = "deactivate";
})(RemoveMode || (RemoveMode = {}));
export const QuickAlert = ({ defaultValues, alertId, referenceId, onSuccess, onError, isEnabled, children, removeMode = RemoveMode.Deactivate, }) => {
    const { client, generateName, isLoggedIn, isAnonymousUser, profile } = useAlerts();
    const [createAlert, { loading: isCreateLoading }] = useCreateAlertMutation({
        client,
        onCompleted(data) {
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data.createAlert);
        },
        onError,
    });
    const [toggleAlert, { loading: isToggleLoading }] = useToggleAlertMutation({
        client,
        onCompleted(data) {
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data.toggleAlert);
        },
        onError,
    });
    const [deleteAlert, { loading: isDeleteLoading }] = useDeleteAlertMutation({
        client,
        onCompleted(data) {
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data.deleteAlert);
        },
        onError,
    });
    const handleClick = useCallback(() => {
        if (alertId) {
            if (isEnabled && removeMode === RemoveMode.Delete) {
                return deleteAlert({
                    variables: {
                        profile,
                        alertId,
                    },
                }).then((res) => { var _a; return (_a = res.data) === null || _a === void 0 ? void 0 : _a.deleteAlert; });
            }
            return toggleAlert({
                variables: {
                    profile,
                    alertId,
                    enabled: !isEnabled,
                },
            }).then((res) => { var _a; return (_a = res.data) === null || _a === void 0 ? void 0 : _a.toggleAlert; });
        }
        const { isGrouped, parameters, frequency, day } = defaultValues;
        const name = generateName(referenceId, parameters);
        return createAlert({
            variables: {
                profile,
                data: {
                    enabled: true,
                    grouped: !!isGrouped,
                    name,
                    parameters,
                    referenceId,
                    schedule: toSchedule(frequency, day),
                },
            },
        }).then((res) => { var _a; return (_a = res.data) === null || _a === void 0 ? void 0 : _a.createAlert; });
    }, [
        alertId,
        defaultValues,
        generateName,
        referenceId,
        createAlert,
        profile,
        removeMode,
        toggleAlert,
        isEnabled,
        deleteAlert,
    ]);
    if (!isLoggedIn || isAnonymousUser)
        return null;
    return (_jsx(_Fragment, { children: children({
            onClick: handleClick,
            isDisabled: isCreateLoading || isToggleLoading || isDeleteLoading,
        }) }));
};
export const QuickAlertButton = ({ defaultValues, labelFollow = 'Volgen', labelUnfollow = 'Ontvolgen', labelLoading = 'Laden...', referenceId, onSuccess, onError, removeMode, }) => {
    const { client, isLoggedIn, isAnonymousUser, profile } = useAlerts();
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const { data, loading: isLoading, error, refetch, } = useQuickAlertQuery({
        client,
        variables: {
            profile,
            referenceId,
        },
        skip: !isLoggedIn || isAnonymousUser,
    });
    const alert = !error ? data === null || data === void 0 ? void 0 : data.alert : null;
    const isAlertEnabled = !!(alert === null || alert === void 0 ? void 0 : alert.enabled);
    if (!isLoggedIn || isAnonymousUser)
        return null;
    if (isLoading) {
        return (_jsx(Button, { isDisabled: true, iconLeft: _jsx(Flex, { children: _jsx(Loader, { height: 18, variant: "spinner" }) }), size: "S", variant: "primary", children: labelLoading }));
    }
    return (_jsx(QuickAlert, { alertId: alert === null || alert === void 0 ? void 0 : alert.id, defaultValues: defaultValues, isEnabled: isAlertEnabled, referenceId: referenceId, removeMode: removeMode, onError: onError, onSuccess: (data) => {
            setShouldAnimate(data.enabled);
            refetch();
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data);
        }, children: (props) => {
            return (_jsx(PopoutButton, Object.assign({ animate: shouldAnimate, iconLeft: isAlertEnabled ? _jsx(StarFilledIcon, {}) : _jsx(StarOutlineIcon, {}), size: "S", testId: "quickAlertButton", variant: "primary" }, props, { children: isAlertEnabled ? labelUnfollow : labelFollow })));
        } }));
};
