import type { ContentPart } from '@elseu/sdu-titan-content-rendering';
import type { DockItemContentData } from '@elseu/sdu-titan-content-rendering';

interface CanExportContent {
  canExport: boolean;
  canExportParts: boolean;
  canExportFullDocument: boolean;
}

export const isShownContentExportPlugin = (data: DockItemContentData): boolean => {
  return canUseContentExportPluginResult(data).canExport;
};

export const canUseContentExportPluginResult = ({
  type,
  activeTab,
  contentParts,
  swsId,
  swsDocumentTitle,
}: DockItemContentData): CanExportContent => {
  const isCommentaryTab = type === 'Commentary' && activeTab === 'commentaar';
  const isPracticeNoteTab = type === 'PracticeNote' && (!activeTab || activeTab === 'pn');
  const isCommentaryTabOrPracticeNoteTab = isCommentaryTab || isPracticeNoteTab;
  const isCommentaryOrPracticeNote = type === 'Commentary' || type === 'PracticeNote';

  const canExport =
    !!(swsId && swsDocumentTitle) &&
    (!isCommentaryOrPracticeNote || isCommentaryTabOrPracticeNoteTab) &&
    type !== 'Tool';

  return {
    canExport,
    canExportParts: true,
    canExportFullDocument: canContentExportFullDocument(contentParts),
  };
};

// const canContentExportParts = (contentParts: IContentPart[] | undefined): boolean => {
//   return !contentParts?.some((part) => part.depth === -1);
// };

const canContentExportFullDocument = (contentParts: ContentPart[] | undefined): boolean => {
  return (contentParts?.length || 0) <= 250;
};
