import type { CheckboxDropdownProps } from '@elseu/sdu-titan';
import { CheckboxDropdown } from '@elseu/sdu-titan';
import type { CheckboxOptionProps } from '@elseu/sdu-titan/dist/types/components/CheckboxGroup/CheckboxGroup';
import type { SearchFacetProps } from '@elseu/sdu-titan-search';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirstLetter';
import type { AlertFormValues } from '../../AlertFormSchema';
import { getCheckboxOptionsByLevel } from '../../helpers/getCheckboxOptionsByLevel';
import type { CheckboxOptionPropsByValue } from '../../helpers/getCheckboxOptionsByValue';
import { useFacetField } from './useFacetField';
import { useShouldShowFacetField } from './useShouldShowFacetField';

/**
 * Props for the FacetField component, extending CheckboxDropdownProps.
 */
interface FacetFieldProps
  extends Omit<CheckboxDropdownProps, 'options' | 'optionsByValue' | 'name'> {
  name: string;
  options: SearchFacetProps['options'];
}

/**
 * Get the button text based on the selected values.
 *
 * @param {CheckboxOptionPropsByValue} optionsByValue - Options mapped by their values.
 * @param {ReactNode} fallback - Fallback text if no value is selected.
 * @param {string[]} [value=[]] - Selected values.
 * @returns {ReactNode} The concatenated labels of the selected values or the fallback text.
 */
const getButtonText = (
  optionsByValue: CheckboxOptionPropsByValue,
  fallback: ReactNode,
  value: string[] = [],
): ReactNode =>
  value.length
    ? value
        .filter((val) => optionsByValue[val] as CheckboxOptionProps | undefined)
        .map((val) => optionsByValue[val]?.label)
        .join(', ')
    : fallback;

/**
 * FacetField component used to display and manage a checkbox dropdown for facet filtering.
 *
 * @param {FacetFieldProps} props - Props for the FacetField component.
 * @returns {JSX.Element | null} The CheckboxDropdown component or null if the field should not be shown.
 */
const FacetField = ({ name, options: config, buttonText, ...props }: FacetFieldProps) => {
  const { setValue } = useFormContext<AlertFormValues>();
  const { options, optionsByValue } = useFacetField(name);
  const shouldShow = useShouldShowFacetField(config.display);
  const { control } = useFormContext<AlertFormValues>();
  const {
    field: { onChange, value, ...field },
    formState: { errors },
  } = useController({
    defaultValue: [],
    name: `facets.${name}`,
    control,
  });

  /**
   * Get the options based on the selected level.
   *
   * @returns {CheckboxOptionProps[]} The filtered checkbox options.
   */
  const checkboxOptions: CheckboxOptionProps[] = useMemo(() => {
    return getCheckboxOptionsByLevel(config.fromLevel, options);
  }, [options, config.fromLevel]);

  /**
   * Handle the change of the selected values.
   *
   * @param {string[]} values - The selected values.
   */
  const handleChange = useCallback(
    (values: string[]) => {
      onChange(values);
      setValue(`facets.${name}`, values);
    },
    [onChange, setValue, name],
  );

  /**
   * Check if the options contain children.
   *
   * @returns {boolean} True if options contain children, false otherwise.
   */
  const isTree = useMemo(() => options.some((item) => item.children?.length), [options]);

  /**
   * Return null if the field should not be shown or if data is loading.
   */
  if (!shouldShow || checkboxOptions.length === 0) return null;

  return (
    <CheckboxDropdown
      {...props}
      {...field}
      badge={value.length}
      buttonText={getButtonText(optionsByValue, buttonText, value)}
      buttonVariant="border"
      buttonWidth="100%"
      errorText={errors.facets?.[name]?.message}
      hasError={!!errors.facets?.[name]}
      options={checkboxOptions}
      popoverWidth={isTree ? 400 : undefined}
      testId={`alertForm${capitalizeFirstLetter(name)}`}
      value={value}
      zIndex={1040}
      onChange={handleChange}
    />
  );
};

export { FacetField };
