import { LoggedInCookieHandler } from './LoggedInCookieHandler';
import { TrackLoginEvents } from './TrackLoginEvents';

const AuthComponents = () => (
  <>
    <TrackLoginEvents />
    <LoggedInCookieHandler />
  </>
);

export { AuthComponents };
