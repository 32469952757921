import { Frequency, QuickAlert } from '@elseu/sdu-alert-react';
import { BellFilledIcon, BellOutlineIcon, Button } from '@elseu/sdu-titan';
import type { SearchRootProviderProps } from '@elseu/sdu-titan-search';
import type { SourceAlertFragmentFragment } from '@elseu/sdu-titan-search/dist/types/generated/sources/graphql';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

const AlertButton = styled(Button)`
  padding: 0 0.5rem;
`;

function getAction(sourceAlert: SourceAlertFragmentFragment | null | undefined) {
  return sourceAlert?.isActive ? 'active' : 'unset';
}

const icons: Record<ReturnType<typeof getAction>, any> = {
  active: <BellFilledIcon />,
  unset: <BellOutlineIcon />,
};

const label: Record<ReturnType<typeof getAction>, any> = {
  active: <Trans>Ingesteld</Trans>,
  unset: <Trans>Instellen</Trans>,
};

export const renderSourceOverviewAlertColumn: SearchRootProviderProps['renderSourceOverviewAlertColumn'] =
  (source, refetch) => {
    const sourceAlert = source.sourceAlert;

    const action = getAction(sourceAlert);

    const roSource = source.title;

    const type = source.hasEditions ? 'magazine' : 'source';

    const isInvalid = !roSource || !source.title?.value;

    if (isInvalid) return null;

    const referenceId = roSource.key;

    return (
      <QuickAlert
        alertId={sourceAlert?.alertId}
        defaultValues={{
          day: undefined,
          frequency: Frequency.DAILY,
          parameters: {
            type,
            term: '',
            facets: {
              ro_source: [{ label: roSource.value, value: referenceId }],
            },
            title: source.title!.value,
          },
        }}
        isEnabled={sourceAlert?.isActive || false}
        referenceId={referenceId}
        onSuccess={() => refetch()}
      >
        {(props) => {
          return (
            <AlertButton iconLeft={icons[action]} size="S" variant="clear" {...props}>
              {label[action]}
            </AlertButton>
          );
        }}
      </QuickAlert>
    );
  };
