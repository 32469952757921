import type { ContentRenderingDocumentLoadedPayload } from '@elseu/sdu-titan-content-rendering';
import { getDataLayer, pushToDataLayer } from '@repo/analytics';

import type { CombinedAnalyticsPlugin } from './types';

interface SwsDataLayerDocumentEventPayload {
  event: 'SwsContentView';
  eventName: string;
  swsId: string;
  swsAltKeys: string[];
  swsSectionId?: string;
  swsPartId?: string;
  swsPartTitle?: string;
  swsTypes: string[];
  swsAuthorIds: string[];
  swsPublicationId: string;
  swsPublicationTitle: string;
  swsPublicationYear: string;
  swsAudience: string[];
  swsDocumentTitle: string;
  swsLegalAreaIds: string[];
  areaOfExpertise: string[];
}

const getSwsAuthorIds = (doc: ContentRenderingDocumentLoadedPayload): string[] => {
  const filteredCreator = doc.metaData?.creator.filter((res) => res.identifier !== null);
  if (filteredCreator?.length) {
    return filteredCreator.map((res) => res.identifier) as string[];
  }
  return [];
};

interface GenerateSwsContentViewEventProps {
  doc: ContentRenderingDocumentLoadedPayload;
  eventName: string;
  swsPartId?: string;
  swsSectionId?: string;
  swsPartTitle?: string;
}
const generateSwsContentViewEvent = ({
  doc,
  eventName,
  swsPartId,
  swsSectionId,
  swsPartTitle,
}: GenerateSwsContentViewEventProps): SwsDataLayerDocumentEventPayload => {
  return {
    event: 'SwsContentView',
    eventName,
    swsId: doc.swsId,
    swsPartId: swsPartId ?? '',
    swsPartTitle: swsPartTitle ?? '',
    swsSectionId: swsSectionId ?? '',
    swsAltKeys: doc.metaData?.altKey ?? [],
    swsTypes: doc.metaData?.type ?? [],
    swsAudience: doc.metaData?.audience ?? [],
    swsDocumentTitle: doc.title || '',
    swsLegalAreaIds: doc.metaData?.legalArea.map((res) => res.identifier) ?? [],
    swsPublicationId: doc.metaData?.published[0]?.publicationName?.identifier ?? '',
    swsPublicationTitle: doc.metaData?.published[0]?.publicationName?.title ?? '',
    swsPublicationYear: doc.metaData?.published[0]?.publicationYear ?? '',
    swsAuthorIds: getSwsAuthorIds(doc),
    areaOfExpertise: [],
  };
};

export const swsDataLayerPlugin = (): CombinedAnalyticsPlugin => {
  let doc: any | null = null;

  return {
    name: 'swsDataLayerAnalytics',
    initializeStart: ({ abort }) => {
      if (typeof window === 'undefined') {
        return abort('Cancel because server side rendering');
      }
    },
    initialize: () => {
      getDataLayer();
    },
    page: () => {
      doc = null;
    },
    track: ({ payload }) => {
      let event;
      switch (payload.event) {
        case 'ContentRenderingDocumentLoaded':
          doc = payload.properties;
          event = generateSwsContentViewEvent({ doc, eventName: payload.properties.eventName });
          pushToDataLayer(event);
          break;
        case 'TocActiveItemInView':
          if (doc !== null && doc.swsId === payload.properties.swsId) {
            const { eventName, activeId, activeTitle } = payload.properties;
            event = generateSwsContentViewEvent({
              doc,
              eventName,
              swsPartId: activeId,
              swsPartTitle: activeTitle,
            });
            pushToDataLayer(event);
          }
          break;
        case 'ChangeTab':
          if (doc !== null) {
            const { eventName, contentPartAnchor, label } = payload.properties;
            event = generateSwsContentViewEvent({
              doc,
              eventName,
              swsPartId: contentPartAnchor,
              swsSectionId: label,
            });
            pushToDataLayer(event);
          }
          break;
      }
    },
  };
};
