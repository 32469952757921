import { Input } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import type { AlertFormValues } from '../../AlertFormSchema';

/**
 * CustomNameField component used to display and manage an input field for the custom name.
 *
 * @returns {JSX.Element} The Input component for the custom name field.
 */
const CustomNameField = () => {
  const { control } = useFormContext<AlertFormValues>();
  const {
    field: { onChange, onBlur, value, ...field },
    formState: { errors },
  } = useController({
    name: 'customName',
    control,
  });

  /**
   * Handle the input change and call the onChange function.
   *
   * @param {string} value - The new value of the input field.
   */
  const handleInputChange = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Input
      autoComplete="off"
      errorText={errors.customName?.message as string | undefined}
      hasError={!!errors.customName}
      label={t`Naam`}
      spaceAfter={4}
      testId="customNameField"
      value={value}
      onBlur={onBlur}
      onInputChange={handleInputChange}
      {...field}
    />
  );
};

export { CustomNameField };
