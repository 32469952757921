import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import React, { useMemo } from 'react';

import type { AccessToken } from '../../../types/AccessToken';
import { useOfficeDocsClient } from '../hooks/useOfficeDocsClient';

interface IOfficeDocsContext {
  client?: ApolloClient<NormalizedCacheObject>;
  errorComponent?: React.FC<{ statusCode?: number }>;
}

interface IOfficeDocsProvider {
  /** GraphQL Endpoint url */
  graphqlUrl: string;
  /* The bearer accessToken */
  accessToken?: AccessToken;
  /* A custom error component */
  errorComponent?: React.FC<{ statusCode?: number }>;
  /* Custom fetch implementation, like node-fetch or cross-fetch */
  fetch?: WindowOrWorkerGlobalScope['fetch'];
}

const OfficeDocsContext = React.createContext<IOfficeDocsContext | undefined>(
  {} as IOfficeDocsContext,
);

const useOfficeDocs = () => {
  const context = React.useContext(OfficeDocsContext);
  if (context === undefined) {
    throw new Error('useOfficeDocs must be used within a OfficeDocsProvider');
  }
  return context;
};

const OfficeDocsProvider: React.FC<React.PropsWithChildren<IOfficeDocsProvider>> = ({
  graphqlUrl,
  accessToken,
  errorComponent,
  fetch,
  children,
}) => {
  const client = useOfficeDocsClient({
    graphqlUrl,
    accessToken,
    fetch,
  });

  const context = useMemo(
    () => ({
      client: !process.env.JEST_WORKER_ID ? client : undefined,
      errorComponent,
    }),
    [client, errorComponent],
  );

  return <OfficeDocsContext.Provider value={context}>{children}</OfficeDocsContext.Provider>;
};

export { OfficeDocsProvider, useOfficeDocs };
