var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Drawer, useBinding } from '@elseu/sdu-titan';
import { CONTENT_EXPORT_TYPES } from '../ContentExportPlugin/types';
import { ContentExportDrawerContainer } from './ContentExportDrawerContainer';
import { ContentExportDrawerProvider } from './ContentExportDrawerProvider';
const ContentExportDrawer = (_a) => {
    var { defaultShown, isShown, onDrawerToggle, type = CONTENT_EXPORT_TYPES.PRINT, labelCannotSaveDocument = 'Je kunt dit document helaas niet printen', labelhasTooManyParts = 'Maximaal {limit} onderdelen mogen in één keer gedownload worden. Pas deselectie aan om verder te gaan.', labelDrawerHeader = 'Printen', labelDrawerErrorDescription = 'Er is een fout opgetreden bij het ophalen van de printbare onderdelen. Probeer het later opnieuw.', labelDrawerErrorHeader = 'Er is een fout opgetreden', labelDrawerErrorButton = 'Opnieuw printen', labelDrawerLoader = 'Printbare onderdelen ophalen', labelDrawerSubtitle = 'Selecteer de onderdelen die je wilt printen', labelSaveButtonTitle = 'Printen', labelCancelButtonTitle = 'Annuleren', labelSelectAll = 'Volledige document', locale = 'nl-NL', brandingKey = 'titan' } = _a, rest = __rest(_a, ["defaultShown", "isShown", "onDrawerToggle", "type", "labelCannotSaveDocument", "labelhasTooManyParts", "labelDrawerHeader", "labelDrawerErrorDescription", "labelDrawerErrorHeader", "labelDrawerErrorButton", "labelDrawerLoader", "labelDrawerSubtitle", "labelSaveButtonTitle", "labelCancelButtonTitle", "labelSelectAll", "locale", "brandingKey"]);
    const [isDrawerOpen, setDrawerOpen] = useBinding(defaultShown, isShown, onDrawerToggle, false);
    return (_jsx(Drawer, { isShown: isDrawerOpen, position: "right", testId: "contentExportDrawer", width: 480, onClose: () => setDrawerOpen(false), children: isDrawerOpen && (_jsx(ContentExportDrawerProvider, Object.assign({ brandingKey: brandingKey, labelCancelButtonTitle: labelCancelButtonTitle, labelCannotSaveDocument: labelCannotSaveDocument, labelDrawerErrorButton: labelDrawerErrorButton, labelDrawerErrorDescription: labelDrawerErrorDescription, labelDrawerErrorHeader: labelDrawerErrorHeader, labelDrawerHeader: labelDrawerHeader, labelDrawerLoader: labelDrawerLoader, labelDrawerSubtitle: labelDrawerSubtitle, labelhasTooManyParts: labelhasTooManyParts, labelSaveButtonTitle: labelSaveButtonTitle, labelSelectAll: labelSelectAll, locale: locale, type: type, onDrawerClose: () => setDrawerOpen(false) }, rest, { children: _jsx(ContentExportDrawerContainer, {}) }))) }));
};
export { ContentExportDrawer };
