import type { ReferenceId } from '@elseu/sdu-alert-react';
import { t } from '@lingui/macro';

import type { AlertParameters } from '../types/AlertParameters';
import { AlertType } from '../types/AlertParameters';

export const generateName = (referenceId: ReferenceId, parameters: AlertParameters) => {
  const { type, title, term } = parameters;

  // If alerts is a quick alert
  if (referenceId) {
    if (type === AlertType.MAGAZINE) {
      return t`Tijdschriften: Nieuwe edities voor ${title}`;
    }

    // Safety fallback
    return title || t`Attendering zonder titel`;
  }

  // If alerts is a search alert
  if (type === AlertType.SEARCH) {
    return t`Opgeslagen zoekresultaat: ${title || term || t`alles`}`;
  }

  // Safety fallback
  return title || term || t`Attendering zonder titel`;
};
