import type { NextPage, NextPageContext } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import { parseCookies } from 'nookies';

import { getDisplayName } from '../helpers/getDisplayName';
import type { AppWithProps } from './types';

type WithCookiesContext = AppContext & NextPageContext;

export interface CookiesProps {
  cookies: {
    [key: string]: string;
  };
}

function withCookies<T>(PageComponent: NextPage<any> | AppWithProps<T>) {
  const WithCookies = (props: AppProps & T) => <PageComponent {...props} />;

  WithCookies.displayName = `WithCookies(${getDisplayName(PageComponent)})`;
  WithCookies.getInitialProps = async (ctx: WithCookiesContext) => {
    const isInAppContext = Boolean(ctx.ctx);

    let pageProps = {};
    if (PageComponent.getInitialProps) {
      pageProps = { ...pageProps, ...(await PageComponent.getInitialProps(ctx)) };
    } else if (isInAppContext) {
      pageProps = { ...pageProps, ...(await App.getInitialProps(ctx)) };
    }

    const cookies = parseCookies(ctx.ctx);

    return {
      ...pageProps,
      cookies,
    };
  };

  return WithCookies;
}

export { withCookies };
