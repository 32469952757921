import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import type { ReactNode } from 'react';
import { createContext, memo, useMemo } from 'react';

import fragmentTypes from '../../generated/cms/fragmentTypes.json';
import { useSiteConfig } from '../SiteConfigProvider';

interface TitanCmsContextProps {
  client: ApolloClient<NormalizedCacheObject>;
}

interface TitanCmsProviderProps {
  apolloRegisterCache?: (cache: InMemoryCache) => void;
  apolloInitialState?: NormalizedCacheObject;
  children: ReactNode;
}

export const TitanCmsContext = createContext<TitanCmsContextProps | undefined>(
  {} as TitanCmsContextProps,
);

// For building static pages on build time (otherwise apollo gives an error)
const CMS_GRAPHQL_URL_FALLBACK =
  process.env.CMS_GRAPHQL_URL || 'https://cms.ota.titan2.awssdu.nl/api/v1/graphql';

export const TitanCmsProvider = memo<TitanCmsProviderProps>(
  ({ children, apolloInitialState, apolloRegisterCache }) => {
    const { siteUrl, cmsGraphqlUrl } = useSiteConfig();

    const contextValue = useMemo<TitanCmsContextProps>(() => {
      const cache = new InMemoryCache({
        possibleTypes: fragmentTypes.possibleTypes,
      }).restore(apolloInitialState || {});

      if (apolloRegisterCache) {
        apolloRegisterCache(cache);
      }

      const localPort = process.env.PORT ?? '3000';
      // Switch uri for server side rendering otherwise it's
      // pointing to itself and cause issues when loadbalancer blocks traffic.
      const localUri =
        typeof window === 'undefined' ? `http://127.0.0.1:${localPort}/api/cms` : '/api/cms';

      // We will use the CMS graphql url directly at build time (`BUILD=true next build`)
      const url = siteUrl || '';
      const isStorybook = url.includes('.storybook') || url.includes('localhost:6006');
      const uri =
        process.env.BUILD === 'true' || isStorybook
          ? cmsGraphqlUrl || CMS_GRAPHQL_URL_FALLBACK
          : localUri;

      const httpLink = createHttpLink({
        uri,
      });

      const retryLink = new RetryLink({
        attempts: {
          max: 2,
        },
        delay: {
          initial: 300,
          jitter: true,
        },
      });

      const client = new ApolloClient({
        ssrMode: typeof window === 'undefined',
        link: from([retryLink, httpLink]),
        queryDeduplication: true,
        cache,
      });

      return { client };
    }, [apolloInitialState, apolloRegisterCache, cmsGraphqlUrl, siteUrl]);

    return <TitanCmsContext.Provider value={contextValue}>{children}</TitanCmsContext.Provider>;
  },
);
