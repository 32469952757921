import { useRouter } from 'next/router';
import queryString from 'query-string';
import type * as React from 'react';
import { useMemo } from 'react';

import type { Urls } from '../../context/SiteConfigProvider';
import { useSiteConfig } from '../../context/SiteConfigProvider';
import { useRedirectQuery } from '../../generated/local/graphql';
import type { ExternalReference, SwsReference } from '../../generated/redirectService';
import useServerContext from '../../libs/next-server-context/useServerContext';
import { useRedirectClient } from './hooks/useRedirectClient';

interface RedirectMapping {
  external: (data: ExternalReference) => string;
  sws: (data: SwsReference) => string;
}
const redirectMapping = (urls: Urls): RedirectMapping => ({
  external: (data: ExternalReference) => {
    return data.url;
  },
  sws: (data: SwsReference) => {
    return queryString.stringifyUrl({
      url: `${urls.contentUrl}/${data.swsId}`,
      query: data.tab ? { tab: data.tab } : {},
      fragmentIdentifier: data.articleLabelId ? `${data.swsId}_${data.articleLabelId}` : undefined,
    });
  },
});

const RedirectChecker: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { redirectEndpoint, urls } = useSiteConfig();
  const client = useRedirectClient({ apiUrl: redirectEndpoint || '' });
  const router = useRouter();
  const ctx = useServerContext();

  const input = useMemo(() => {
    const querystring = Object.keys(router.query).length
      ? `?${queryString.stringify(router.query)}`
      : '';
    const { url: path } = queryString.parseUrl(router.asPath);
    return {
      path,
      querystring,
      fragment: '',
    };
  }, [router.asPath, router.query]);

  const shouldSkip =
    // no need to check redirect for some specific paths
    ['favicon', '_next', 'static'].some((path) => router.asPath.includes(path)) ||
    // or when the redirectEndpoint is undefined
    !redirectEndpoint;

  const {
    data: response,
    error,
    loading: isLoading,
  } = useRedirectQuery({
    variables: { input },
    client,
    ssr: true,
    skip: shouldSkip,
  });

  // If endpoint is not defined just return 404
  if (shouldSkip) return <>{children}</>;

  // Wait until redirect check is done before rendering anything
  if (isLoading) return <>{children}</>;

  const data = response?.redirect;

  // Return default 404 when no data or no SWS ID
  if (!data || !Object.keys(data).length || error) return <>{children}</>;

  const map = redirectMapping(urls);
  const mappedObj = map[data.type as keyof RedirectMapping] as
    | RedirectMapping['external']
    | RedirectMapping['sws']
    | undefined;
  const location = mappedObj?.(data as any);

  if (!location) return <>{children}</>;

  if (ctx?.response) {
    try {
      // Redirect server side
      // eslint-disable-next-line no-console
      console.log(
        `RedirectChecker::Redirecting Server Side success: From: ${router.asPath} - To: ${location}`,
      );

      ctx.response.statusCode = 301;
      ctx.response.setHeader('Location', location);
    } catch (e) {
      console.error(`RedirectChecker::Redirecting Server Side failed with error: ${e}`);
      return <>{children}</>;
    }
  } else {
    // Redirect client side
    router.push(location);
  }

  return <>{children}</>;
};

export { RedirectChecker };
