import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DrawerContainer, DrawerContent, DrawerError, DrawerFooter, DrawerLoader, ErrorIcon, spacing, StatusMessage, t, Text, } from '@elseu/sdu-titan';
import styled from 'styled-components';
import { CONTENT_EXPORT_TYPES } from '../ContentExportPlugin/types';
import { useContentExportDrawer } from './ContentExportDrawerProvider';
import { ContentExportDrawerSelectAll } from './ContentExportDrawerSelectAll';
import { ContentExportDrawerTree } from './ContentExportDrawerTree';
const StyledFooter = styled.div `
  width: 100%;
  text-align: right;
`;
const StyledButton = styled(Button) `
  margin-left: ${spacing(4)};
`;
const ContentExportDrawerNotAllowed = () => {
    const { labelCannotSaveDocument } = useContentExportDrawer();
    return (_jsx(DrawerContent, { children: _jsx(Text, { type: "paragraph", children: labelCannotSaveDocument }) }));
};
const ContentExportDrawerContent = ({ children }) => {
    const { isLoading, isError, onReset, canExportFullDocument, canExportParts, drawerHeaderRef, treeDataError, labelDrawerErrorButton, labelDrawerErrorDescription, labelDrawerErrorHeader, labelDrawerHeader, labelDrawerLoader, labelDrawerSubtitle, } = useContentExportDrawer();
    if (isLoading) {
        return _jsx(DrawerLoader, { loadingText: labelDrawerLoader });
    }
    if (isError) {
        return (_jsx(DrawerError, { buttonText: labelDrawerErrorButton, description: labelDrawerErrorDescription, title: labelDrawerErrorHeader, onReset: onReset }));
    }
    const isNotAllowed = (!canExportFullDocument && !canExportParts) || treeDataError;
    return (_jsx(DrawerContainer, { hasCloseButton: true, header: labelDrawerHeader, headerRef: drawerHeaderRef, subtitle: labelDrawerSubtitle, testId: "contentExportDrawerContainer", children: isNotAllowed ? _jsx(ContentExportDrawerNotAllowed, {}) : children }));
};
const ContentExportDrawerContainer = () => {
    const { type = 'print', limit, onClose, onSubmit, isAllChecked, canExportFullDocument, canExportParts, contentParts, labelhasTooManyParts, labelSaveButtonTitle, labelCancelButtonTitle, } = useContentExportDrawer();
    const hasTooManyPartsSelected = type === CONTENT_EXPORT_TYPES.SHARE_TO_TAXVICE
        ? Boolean(contentParts && limit && !isAllChecked && contentParts.length > limit)
        : Boolean(contentParts && limit && contentParts.length > limit);
    const hasNotMadeSelection = !(contentParts === null || contentParts === void 0 ? void 0 : contentParts.length) && !isAllChecked;
    const isDisabled = hasNotMadeSelection || hasTooManyPartsSelected;
    return (_jsxs(ContentExportDrawerContent, { children: [_jsxs(DrawerContent, { children: [_jsx(StatusMessage, { isRounded: true, alignment: "center", icon: _jsx(ErrorIcon, {}), isShown: hasTooManyPartsSelected, spaceAfter: 6, type: "warning", children: t(labelhasTooManyParts, { limit: limit || 0 }) }), canExportFullDocument && _jsx(ContentExportDrawerSelectAll, {}), canExportParts && _jsx(ContentExportDrawerTree, {})] }), _jsx(DrawerFooter, { children: _jsxs(StyledFooter, { "data-test-id": "contentExportDrawerActions", children: [_jsx(StyledButton, { testId: "contentExportDrawerClose", variant: "clear", onClick: onClose, children: labelCancelButtonTitle }), _jsx(StyledButton, { isDisabled: isDisabled, testId: "contentExportDrawerSave", onClick: onSubmit, children: labelSaveButtonTitle })] }) })] }));
};
export { ContentExportDrawerContainer };
