import type { NormalizedCacheObject, TypePolicies } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { mergeDeep } from '@apollo/client/utilities';
import { RestLink } from 'apollo-link-rest';
import { useMemo } from 'react';

import type { ContentRenderingProviderProps } from '../context';
import introspectionQueryResultData from '../generated/content/fragmentTypes.json';
import { getHeaders } from '../helpers/getRequestHeaders';

interface ContentClient
  extends Pick<ContentRenderingProviderProps, 'roles' | 'sub' | 'accessToken'> {
  graphqlUrl: string;
  initialState?: NormalizedCacheObject;
  registerCache?: (cache: InMemoryCache) => void;
  fetch?: WindowOrWorkerGlobalScope['fetch'];
}

export const useContentClient = ({
  graphqlUrl,
  roles,
  sub,
  accessToken,
  initialState,
  registerCache,
  fetch,
}: ContentClient): ApolloClient<NormalizedCacheObject> => {
  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: graphqlUrl,
        fetch,
      }),
    [graphqlUrl, fetch],
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        if (!accessToken || typeof accessToken === 'string') {
          return getHeaders(headers, accessToken, roles, sub);
        }
        return accessToken().then((token) => getHeaders(headers, token));
      }),
    [accessToken, roles, sub],
  );

  /**
   * RestLink is used to fetch html content for content parts and footnotes
   */
  const restLink = useMemo(
    () =>
      new RestLink({
        uri: graphqlUrl.replace('/graphql', ''),
        customFetch: fetch,
        responseTransformer: async (response, typeName) => {
          const html = await response.text();

          if (!response.ok) {
            return null;
          }

          return {
            id: response.url,
            html,
            __typename: typeName,
          };
        },
      }),
    [fetch, graphqlUrl],
  );

  return useMemo(() => {
    const cacheTypePolicies: TypePolicies = {};
    [
      ...introspectionQueryResultData.possibleTypes.Tab,
      ...introspectionQueryResultData.possibleTypes.LawTocEntry,
      'TocEntry',
      'BookTocEntry',
      'LawTocEntry',
      'FootNote',
    ].forEach((value) => {
      cacheTypePolicies[value] = {
        keyFields: false,
      };
    });

    const queryCacheTypePolicies: TypePolicies = {
      PNNavigationDrawerInfo: {
        keyFields: ['id', 'subjectId'],
      },
      Query: {
        fields: {
          latestEditions: {
            merge: true,
          },
        },
      },
    };

    introspectionQueryResultData.possibleTypes.Content.forEach((key) => {
      cacheTypePolicies[key] = {
        fields: {
          tabs: {
            merge(existing = [], incoming: any[]) {
              return mergeDeep(existing, incoming);
            },
          },
        },
      };
    });

    introspectionQueryResultData.possibleTypes.ContentPart.forEach((key) => {
      cacheTypePolicies[key] = {
        keyFields: false,
        fields: {
          tabs: {
            merge(existing = [], incoming: any[]) {
              return mergeDeep(existing, incoming);
            },
          },
        },
      };
    });

    const cache = new InMemoryCache({
      possibleTypes: introspectionQueryResultData.possibleTypes,
      typePolicies: {
        ...cacheTypePolicies,
        ...queryCacheTypePolicies,
      },
    }).restore(initialState || {});

    if (registerCache) {
      registerCache(cache);
    }
    return new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: authLink.concat(restLink).concat(httpLink),
      cache,
    });
  }, [authLink, httpLink, restLink, initialState, registerCache]);
};
