import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';
import { getHeaders } from '../helpers/getRequestHeaders';
export const useAlertsClient = ({ accessToken, graphqlUrl, }) => {
    const httpLink = useMemo(() => new HttpLink({
        uri: graphqlUrl,
    }), [graphqlUrl]);
    const authLink = useMemo(() => setContext((_, { headers }) => {
        if (!accessToken || typeof accessToken === 'string') {
            return getHeaders(headers, accessToken);
        }
        return accessToken().then((token) => getHeaders(headers, token));
    }), [accessToken]);
    const mapToDate = (value) => new Date(value);
    return useMemo(() => {
        const cache = new InMemoryCache({
            typePolicies: {
                Alert: {
                    fields: {
                        created: mapToDate,
                        lastModified: mapToDate,
                    },
                },
                Query: {
                    fields: {
                        Alert: {
                            merge(_ = [], incoming) {
                                return incoming;
                            },
                        },
                    },
                },
            },
        });
        const link = ApolloLink.from([authLink.concat(httpLink)]);
        return new ApolloClient({
            defaultOptions: {
                // The useQuery hook uses Apollo Client's watchQuery function.
                // When we lazily call the alerts query, we want to use the cache
                // but also immediately fetch the latest list of alerts.
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                    nextFetchPolicy: 'cache-first',
                },
            },
            link,
            cache,
        });
    }, [authLink, httpLink]);
};
