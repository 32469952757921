import { Box, Flex, SkipLink } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

const LinksContainer = styled(Box)<{ hasFocus: boolean }>`
  left: -999px;
  position: absolute;
  overflow: hidden;
  z-index: -1001;

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      left: auto;
      position: relative;
      z-index: 1001;
    `}
`;

const SkipLinks = () => {
  useLingui();
  const { query } = useRouter();
  const [hasFocus, setFocus] = useState(false);

  const handleFocusElement = useCallback((name: string) => {
    const el = document.getElementById(name);
    if (el) {
      el.focus();
    }
  }, []);

  return (
    <LinksContainer hasFocus={hasFocus} px={4} py={3}>
      <Flex alignItems="flex-start" flexDirection="column">
        <SkipLink
          label={t`Ga verder naar content`}
          target="#content"
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
        />
        {query.swsId && (
          <SkipLink
            label={t`Ga verder naar content en skip ook inhoudsopgave`}
            target="#main-content"
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
          />
        )}
        {query.swsId && (
          <SkipLink
            label={t`Ga naar acties`}
            onBlur={() => setFocus(false)}
            onClick={() => handleFocusElement('actions')}
            onFocus={() => setFocus(true)}
          />
        )}
      </Flex>
    </LinksContainer>
  );
};

export { SkipLinks };
