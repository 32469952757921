/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { elementRootOffset, withTitan } from '@elseu/sdu-titan';
import type { MutableRefObject } from 'react';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { observe, unobserve } from '../../hooks/useInView/helpers/intersection';

export interface InViewItem {
  id: string;
  weight?: number;
  element: HTMLElement;
  inView: boolean;
}

interface ActiveItemInViewProviderContextProps {
  inViewItems: MutableRefObject<InViewItem[]> | null;
  activeInViewItem: InViewItem | null;
}

interface ActiveItemInViewRegisterProviderProps {
  register: ((item: InViewItem) => InViewItem) | null;
  unregister: ((item: InViewItem) => void) | null;
}

interface ActiveItemInViewProviderProps {
  scrollWindow: Window | HTMLElement | null;
  debug?: boolean;
}

const ActiveItemInViewRegisterContext = createContext<ActiveItemInViewRegisterProviderProps>({
  register: null,
  unregister: null,
});

const ActiveItemInViewContext = createContext<ActiveItemInViewProviderContextProps>({
  inViewItems: null,
  activeInViewItem: null,
});
const ActiveItemInViewConsumer = ActiveItemInViewContext.Consumer;

const ActiveItemInViewProvider: React.FC<React.PropsWithChildren<ActiveItemInViewProviderProps>> =
  withTitan(
    ({ children, scrollWindow, debug }) => {
      const inViewItems = useRef<InViewItem[]>([]);
      const [activeInViewItem, setActiveInViewItem] = useState<InViewItem | null>(null);

      const scroll = useRef<{ viewPointPercentage: number }>({
        viewPointPercentage: 0,
      });

      const debugElement = useRef<HTMLElement | null>(null);

      const calculateActiveItem = useCallback(() => {
        if (!scrollWindow) {
          return;
        }
        if (debug && debugElement.current === null) {
          document.querySelectorAll('[data-debug-element]').forEach((item) => item.remove());
          debugElement.current = document.createElement('div');
          debugElement.current?.setAttribute('data-debug-element', '1');
          document.body.append(debugElement.current);
        }

        let innerHeight = 0;
        let scrollPosition = 0;
        let scrollHeight = 0;
        // Make sure it is a window
        if ('scrollY' in scrollWindow && document.body) {
          scrollPosition = scrollWindow.scrollY;
          innerHeight = scrollWindow.innerHeight;
          scrollHeight = document.body.offsetHeight - scrollWindow.innerHeight;
          // if scroll window is a html element.
        } else if ('scrollHeight' in scrollWindow) {
          scrollHeight = scrollWindow.scrollHeight;
          scrollPosition = scrollWindow.scrollTop;
          innerHeight = scrollWindow.getBoundingClientRect().height;
        }

        // Reading line percentage of top of the page, under this line that element will be active.
        const readLine = 24;
        // Reading line max percentage when you scroll fully to the bottom of the page.
        const maxReadLine = 50;
        // Scroll distance up in pixels.
        const scrollDistanceUp = (innerHeight / 100) * readLine;
        // Scroll distance down in pixels.
        const scrollDistanceDown = (innerHeight / 100) * (maxReadLine - readLine);

        if (scrollPosition > scrollHeight - scrollDistanceDown) {
          const calculatedPositionBottom =
            ((scrollPosition - (scrollHeight - scrollDistanceDown)) / scrollDistanceDown) *
            100 *
            ((maxReadLine - readLine) / 100);
          scroll.current.viewPointPercentage =
            calculatedPositionBottom > maxReadLine
              ? maxReadLine
              : calculatedPositionBottom + readLine;
        } else {
          const calculatedPositionTop =
            (scrollPosition / scrollDistanceUp) * 100 * (readLine / 100);
          scroll.current.viewPointPercentage =
            calculatedPositionTop > readLine ? readLine : calculatedPositionTop;
        }

        if (debugElement.current !== null) {
          debugElement.current.style.top = `${scroll.current.viewPointPercentage}%`;
          debugElement.current.style.height = '10px';
          debugElement.current.style.width = '100%';
          debugElement.current.style.position = 'fixed';
          debugElement.current.style.zIndex = '99999';
          debugElement.current.style.background = 'red';
        }

        const activeItems = inViewItems.current!.filter(({ inView: itemInView }) => itemInView);
        const scores: number[] = [];

        for (const activeItem of activeItems) {
          const elementOffset = elementRootOffset(activeItem.element);
          const top = ((elementOffset.top - scrollPosition) / innerHeight) * 100;
          const bottom =
            ((elementOffset.top + activeItem.element.clientHeight - scrollPosition) / innerHeight) *
            100;

          if (
            top < scroll.current.viewPointPercentage &&
            bottom > scroll.current.viewPointPercentage
          ) {
            // Found 100% match
            setActiveInViewItem(activeItem);
            return;
          }

          // This makes sure that we only select things that have the highest score potential
          const score = Math.min(
            top - scroll.current.viewPointPercentage,
            bottom - scroll.current.viewPointPercentage,
          );
          scores.push(score);
        }

        // find the closest score by zero
        const closest =
          scores.length > 0
            ? scores.reduce((prev, curr) => (Math.abs(curr) < Math.abs(prev) ? curr : prev))
            : null;

        if (closest) {
          const activeItem = activeItems[scores.indexOf(closest)];
          if (activeItem) {
            setActiveInViewItem(activeItem);
          }
        }
      }, [debug, scrollWindow]);

      const debounceCalculateActiveItem = useMemo(
        () => debounce(200, () => calculateActiveItem()),
        [calculateActiveItem],
      );

      useEffect(() => {
        if (scrollWindow) {
          scrollWindow.addEventListener('scroll', debounceCalculateActiveItem);
          return () => scrollWindow.removeEventListener('scroll', debounceCalculateActiveItem);
        }
      }, [debounceCalculateActiveItem, scrollWindow]);

      const unregister = useCallback(
        (item: InViewItem) => {
          const index = inViewItems.current.indexOf(item);
          inViewItems.current.splice(index, 1);
          setActiveInViewItem(null);
          unobserve(item.element);
        },
        [setActiveInViewItem],
      );

      const register = useCallback(
        (item: InViewItem) => {
          inViewItems.current = [...inViewItems.current, item];

          observe(
            item.element,
            (inView) => {
              item.inView = inView;
              debounceCalculateActiveItem();
            },
            { threshold: 0 },
          );

          return item;
        },
        [debounceCalculateActiveItem],
      );

      const registerContext = useMemo(() => ({ register, unregister }), [register, unregister]);
      const activeItemInViewContext = useMemo(
        () => ({
          activeInViewItem,
          inViewItems,
        }),
        [activeInViewItem, inViewItems],
      );

      return (
        <ActiveItemInViewRegisterContext.Provider value={registerContext}>
          <ActiveItemInViewContext.Provider value={activeItemInViewContext}>
            {children}
          </ActiveItemInViewContext.Provider>
        </ActiveItemInViewRegisterContext.Provider>
      );
    },
    { name: 'ActiveItemInViewProvider', memo: true },
  );

export {
  ActiveItemInViewConsumer,
  ActiveItemInViewContext,
  ActiveItemInViewProvider,
  ActiveItemInViewRegisterContext,
};
