import { useAuthentication } from '@repo/auth';
import { useEffect } from 'react';

import { destroyCookie, setCookie } from '../../helpers/cookies';

const LoggedInCookieHandler = () => {
  const { isInitialized, isSessionExpired, isLoggedIn } = useAuthentication();

  useEffect(() => {
    if (!isInitialized) return;

    if (isLoggedIn) {
      setCookie('isLoggedIn', '1');
      return;
    }
    destroyCookie('isLoggedIn');
  }, [isInitialized, isLoggedIn]);

  useEffect(() => {
    if (!isSessionExpired) return;

    destroyCookie('isLoggedIn');
  }, [isSessionExpired]);

  return null;
};

export { LoggedInCookieHandler };
