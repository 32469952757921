import queryString from 'query-string';

import type { AlertFormValues } from '../components/AlertForm/AlertFormSchema';

export const transformAlertFieldsToSearchUrl = (
  { term, facets }: Pick<AlertFormValues, 'term' | 'facets'>,
  siteUrl = '',
  searchUrl = '',
) => {
  const transformedFields: { [key: string]: string | string[] } = {
    ...facets,
    query: term,
  };

  // Transform array values to a string concatted by a comma and remove empty values
  const query = Object.fromEntries(
    Object.entries(transformedFields)
      .filter(([_, v]) => v && v !== '' && v.length)
      .map(([k, v]) => [k, typeof v === 'string' ? v : v.join(',')]),
  );
  return queryString.stringifyUrl({ url: `${siteUrl}${searchUrl}`, query });
};
