// Customise the Sentry feedback widget -

import { t } from '@lingui/macro';

// docs: https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/#customizing-the-widget
const sentryDialogOptions = () => ({
  lang: t`NL`,
  title: t`Helaas ging er iets fout`,
  subtitle: t`Ons team is op de hoogte gebracht van deze fout. Als u ons wil helpen deze fout in de toekomst te voorkomen, kunt u hier informatie voor ons achterlaten.`,
  subtitle2: t`Als u ervoor kiest om uw e-mailadres achter te laten, brengen wij u op de hoogte wanneer de fout verholpen is.`,
  labelName: t`Uw naam`,
  labelEmail: t`Uw e-mail`,
  labelComments: t`Wat gebeurde er voordat de foutmelding verscheen?`,
  labelClose: t`Sluiten`,
  labelSubmit: t`Versturen`,
  errorGeneric: t`Er ging iets mis met het versturen van het formulier. Probeer het alstublieft opnieuw.`,
  errorFormEntry: t`Niet alle velden zijn goed ingevuld. Probeer het alstublieft opnieuw.`,
  successMessage: t`Wij hebben uw feedback ontvangen, bedankt!`,
});
export { sentryDialogOptions };
