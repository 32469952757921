/**
 * Create a simple popup window with specific with and height and center it.
 * @param url
 */
export function openWindow(url, w, h) {
    const title = '_blank';
    // Fixes dual-screen position                             Most browsers      Firefox
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    // eslint-disable-next-line no-nested-ternary
    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : screen.width;
    // eslint-disable-next-line no-nested-ternary
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : screen.height;
    // const systemZoom = width / window.screen.availWidth;
    const systemZoom = 1.0;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const popupWindow = window.open(url, title, `
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `);
    if (popupWindow)
        popupWindow.focus();
}
