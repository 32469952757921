import { t } from '@elseu/sdu-titan';
import { DayOfWeek as Day } from '../generated/graphql';
export { Day };
export var Frequency;
(function (Frequency) {
    Frequency["DAILY"] = "DAILY";
    Frequency["WEEKLY"] = "WEEKLY";
})(Frequency || (Frequency = {}));
export const frequencyOptions = ({ labelFrequencyDailyOption = 'Elke dag', labelFrequencyDailyDescription = 'Nieuwe resultaten ontvang je elke dag vanaf 7:00', labelFrequencyWeeklyOption = 'Elke week', labelFrequencyWeeklyDescription = 'Elke week ontvang je nieuwe resultaten', }) => [
    {
        value: Frequency.DAILY,
        label: labelFrequencyDailyOption,
        description: labelFrequencyDailyDescription,
    },
    {
        value: Frequency.WEEKLY,
        label: labelFrequencyWeeklyOption,
        description: labelFrequencyWeeklyDescription,
    },
];
export const daysOfWeek = [
    { value: Day.Monday, label: 'M', labelAria: 'Maandag' },
    { value: Day.Tuesday, label: 'D', labelAria: 'Dinsdag' },
    { value: Day.Wednesday, label: 'W', labelAria: 'Woensdag' },
    { value: Day.Thursday, label: 'D', labelAria: 'Donderdag' },
    { value: Day.Friday, label: 'V', labelAria: 'Vrijdag' },
    { value: Day.Saturday, label: 'Z', labelAria: 'Zaterdag' },
    { value: Day.Sunday, label: 'Z', labelAria: 'Zondag' },
];
export const toSchedule = (frequency, day) => {
    if (frequency === Frequency.WEEKLY && day) {
        return [day];
    }
    // TODO: Does daily mean only working days or 7 days a week?
    return [
        Day.Monday,
        Day.Tuesday,
        Day.Wednesday,
        Day.Thursday,
        Day.Friday,
        Day.Saturday,
        Day.Sunday,
    ];
};
export const fromSchedule = (schedule) => {
    if (schedule.length === 7) {
        return {
            frequency: Frequency.DAILY,
        };
    }
    return {
        frequency: Frequency.WEEKLY,
        day: schedule[0],
    };
};
const weekdays = {
    [Day.Monday]: 'maandag',
    [Day.Tuesday]: 'dinsdag',
    [Day.Wednesday]: 'woensdag',
    [Day.Thursday]: 'donderdag',
    [Day.Friday]: 'vrijdag',
    [Day.Saturday]: 'zaterdag',
    [Day.Sunday]: 'zondag',
};
export const formatSchedule = (schedule, labels) => {
    const { frequency, day } = fromSchedule(schedule);
    const { labelFrequencyDailySelected = 'Dagelijks', labelFrequencyWeeklySelected = 'Wekelijks op {weekDay}', } = labels;
    if (frequency === Frequency.WEEKLY && day) {
        return t(labelFrequencyWeeklySelected, { weekDay: weekdays[day] });
    }
    return labelFrequencyDailySelected;
};
