import type { CustomDesignTokens } from '@elseu/sdu-titan';
import type { ContentRenderingUrls } from '@elseu/sdu-titan-content-rendering';
import { type TabWithKeys } from '@elseu/sdu-titan-search';
import { useLingui } from '@lingui/react';
import * as React from 'react';
import { useMemo } from 'react';

import type { LabelMap } from '../helpers/search/getTranslatedLabelDict';
import { translateFacets, translateTabs } from '../helpers/search/translateLabels';
import type { StaticSiteConfig } from '../helpers/staticSiteConfig';
import { staticSiteConfig } from '../helpers/staticSiteConfig';
import type { CookiesProps } from '../hocs';
import type { FeatureFlags } from '../types/FeatureFlags';
import type { FacetsPreset, SearchConfig } from '../types/SearchConfig';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface Urls extends ContentRenderingUrls {
  sourcesOverviewUrl: string;
  bluetickUrl: string;
  genialUrl: string;
}
interface SiteConfigContextProps {
  alertsGraphqlUrl: string;
  allowRobots: string;
  applicationKey: string;
  authUrl: string;
  brandName: string;
  shouldHideBrandName?: boolean;
  cmsGraphqlUrl: string;
  facetsPreset: FacetsPreset;
  featureFlags: FeatureFlags;
  graphqlUrl: string;
  isBot: boolean;
  isLoggedIn: boolean;
  isMultiLang: boolean;
  officeDocsGraphqlUrl: string;
  pmtToolBaseUrl: string;
  printGraphqlUrl: string;
  redirectEndpoint?: string;
  searchFeedbackUrl?: string;
  searchGraphqlUrl: string;
  searchTabs: TabWithKeys[];
  siteKey: string;
  supportUrl: string;
  designTokens: CustomDesignTokens;
  userPreferencesGraphqlUrl: string;
  userSourcesGraphqlUrl: string;
  urls: Urls;
  visibleSearchTabsAmount?: number;

  authorization?: string;
  autocompleteList?: string;
  bluetickGraphqlUrl?: string;
  genialApiUrl?: string;
  bluetickPreviewUrl?: string;
  completemeGraphqlUrl: string;
  frontOfficeUrl?: string;
  footerBrandName?: string;
  intelligentSearchCore?: string;
  logo?: string;
  slogan?: string;
  siteUrl?: string;
  version?: string;
  taxviceShareBaseUrl?: string;
  xposiApiUrl?: string;
  xposiUserContextUrl?: string;
}

export interface SiteConfig {
  alertsGraphqlUrl: string;
  allowRobots: string;
  applicationKey: string;
  authUrl: string;
  brandName: string;
  shouldHideBrandName?: boolean;
  cmsGraphqlUrl: string;
  featureFlags: string;
  graphqlUrl: string;
  isBot: boolean;
  officeDocsGraphqlUrl: string;
  pmtToolBaseUrl: string;
  printGraphqlUrl: string;
  searchConfig: SearchConfig;
  searchGraphqlUrl: string;
  siteKey: string;
  supportUrl: string;
  designTokens: CustomDesignTokens;
  userPreferencesGraphqlUrl: string;
  userSourcesGraphqlUrl: string;
  urls: Urls;

  autocompleteList?: string;
  bluetickGraphqlUrl?: string;
  genialApiUrl?: string;
  bluetickPreviewUrl?: string;
  completemeGraphqlUrl?: string;
  frontOfficeUrl?: string;
  intelligentSearchCore?: string;
  logo?: string;
  searchFeedbackUrl?: string;
  slogan?: string;
  redirectEndpoint?: string;
  siteUrl?: string;
  version?: string;
  taxviceShareBaseUrl?: string;
  xposiApiUrl?: string;
  xposiUserContextUrl?: string;
  cookies?: CookiesProps['cookies'];
  customTabLabelMap?: LabelMap;
  customFacetLabelMap?: LabelMap;
}

interface SiteConfigProviderProps {
  config: SiteConfig;
  cookies?: CookiesProps['cookies'];
}

export const SiteConfigContext = React.createContext<SiteConfigContextProps | undefined>(
  {} as SiteConfigContextProps,
);

export const useSiteConfig = () => {
  const context = React.useContext(SiteConfigContext);
  if (context === undefined) {
    throw new Error('useSiteConfig must be used within a SiteConfigProvider');
  }
  return context;
};

interface GetProtectedConfigItemProps {
  cookies?: CookiesProps['cookies'];
  config: SiteConfig;
  featureFlags: FeatureFlags;
}

type SiteConfigFiltered = Omit<
  SiteConfig,
  | 'sentryOptions'
  | 'searchConfig'
  | 'designTokens'
  | 'urls'
  | 'cookies'
  | 'isBot'
  | 'isLoggedIn'
  | 'isMultiLang'
>;

// Curried function to construct a helper-function, so we don't need to pass cookies, featureFlags and config every time
const makeGetConfigItem =
  ({ cookies, featureFlags, config }: GetProtectedConfigItemProps) =>
  (name: keyof SiteConfigFiltered | keyof StaticSiteConfig) => {
    if (featureFlags.WITH_FEATURE_FLAGS) {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        cookies?.[staticSiteConfig[name as keyof StaticSiteConfig]?.key] ||
        config[name as keyof SiteConfigFiltered] ||
        ''
      );
    }
    return config[name as keyof SiteConfigFiltered] || '';
  };

interface GetFeatureFlagsProps {
  config: SiteConfig;
  cookies?: CookiesProps['cookies'];
}

const getFeatureFlags = ({ cookies, config }: GetFeatureFlagsProps): FeatureFlags => {
  const flags = config.featureFlags.includes('WITH_FEATURE_FLAGS')
    ? cookies?.[staticSiteConfig.featureFlags.key] || config.featureFlags
    : config.featureFlags;

  return flags
    ? flags.split(',').reduce((all: FeatureFlags, flag: string) => ({ ...all, [flag]: true }), {})
    : {};
};

export const SiteConfigProvider: React.FC<React.PropsWithChildren<SiteConfigProviderProps>> = ({
  config,
  cookies,
  ...rest
}) => {
  const { i18n } = useLingui();
  const { searchConfig, customTabLabelMap, customFacetLabelMap, ...settings } = config;

  const state: SiteConfigContextProps = useMemo(() => {
    const { searchTabs, ...facetsConfiguration } = searchConfig;

    const featureFlags = getFeatureFlags({ cookies, config });
    const getConfigItem = makeGetConfigItem({ cookies, featureFlags, config });

    return {
      ...settings,
      isLoggedIn: !!cookies?.isLoggedIn,
      featureFlags,
      authUrl: getConfigItem('authUrl'),
      alertsGraphqlUrl: getConfigItem('alertsGraphqlUrl'),
      cmsGraphqlUrl: getConfigItem('cmsGraphqlUrl'),
      graphqlUrl: getConfigItem('graphqlUrl'),
      searchFeedbackUrl: getConfigItem('searchFeedbackUrl'),
      searchGraphqlUrl: getConfigItem('searchGraphqlUrl'),
      officeDocsGraphqlUrl: getConfigItem('officeDocsGraphqlUrl'),
      printGraphqlUrl: getConfigItem('printGraphqlUrl'),
      userPreferencesGraphqlUrl: getConfigItem('userPreferencesGraphqlUrl'),
      userSourcesGraphqlUrl: getConfigItem('userSourcesGraphqlUrl'),
      autocompleteList: getConfigItem('autocompleteList'),
      completemeGraphqlUrl: getConfigItem('completemeGraphqlUrl'),
      intelligentSearchCore: getConfigItem('intelligentSearchCore'),
      searchTabs: translateTabs(i18n, searchTabs, customTabLabelMap),
      facetsPreset: {
        ...facetsConfiguration,
        bluetickFacets:
          facetsConfiguration.bluetickFacets &&
          translateFacets(i18n, facetsConfiguration.facets, customFacetLabelMap),
        facets: translateFacets(i18n, facetsConfiguration.facets, customFacetLabelMap),
      },
    } as SiteConfigContextProps;
  }, [config, cookies, searchConfig, settings, i18n, customTabLabelMap, customFacetLabelMap]);

  return <SiteConfigContext.Provider {...rest} value={state} />;
};
