import { useScrollToAnchor } from '@elseu/sdu-titan';
import type { ContentRenderingProviderProps } from '@elseu/sdu-titan-content-rendering';
import { ContentRenderingProvider } from '@elseu/sdu-titan-content-rendering';
import { useRouter } from 'next/router';
import type * as React from 'react';
import { useCallback, useEffect } from 'react';
import { create } from 'zustand';

import type { NextHistoryStateProps } from '../../components/PopStateHandler/PopStateHandler';
import { off, on } from '../../helpers/events';
import { updateHash } from '../../helpers/updateHash';
import { useDefaultHash } from '../../hooks/useDefaultHash';

type UseContentRenderingStore = {
  hash?: string;
  setHash: (hash?: string) => void;
};

export const useContentRenderingStore = create<UseContentRenderingStore>((set) => ({
  hash: undefined,
  setHash: (hash) => {
    set({ hash });
  },
}));

type ContentRenderingProviderWRouterProps = Omit<ContentRenderingProviderProps, 'defaultHash'>;
const ContentRenderingProviderWithRouter: React.FC<
  React.PropsWithChildren<ContentRenderingProviderWRouterProps>
> = ({ children, ...rest }) => {
  const defaultHash = useDefaultHash();
  const scrollToAnchor = useScrollToAnchor();
  const router = useRouter();
  const setHash = useContentRenderingStore((state) => state.setHash);

  const handleContentPopState = useCallback(
    (e: CustomEvent<NextHistoryStateProps>) => {
      const [, newHash] = e.detail.as.split('#');

      updateHash(newHash, false);

      newHash && scrollToAnchor(newHash, window);
    },
    [scrollToAnchor],
  );

  const handleMagazinePopState = useCallback(
    (e: CustomEvent<NextHistoryStateProps>) => {
      const [, newHash] = e.detail.as.split('#');
      setHash(newHash);
    },
    [setHash],
  );

  /**
   * Catch browser back button and scroll to the correct element
   */
  useEffect(() => {
    on<NextHistoryStateProps>('popstate:content', handleContentPopState);
    on<NextHistoryStateProps>('popstate:magazines', handleMagazinePopState);

    return () => {
      off<NextHistoryStateProps>('popstate:content', handleContentPopState);
      off<NextHistoryStateProps>('popstate:magazines', handleMagazinePopState);
    };
  }, [handleContentPopState, handleMagazinePopState, router]);

  return (
    <ContentRenderingProvider defaultHash={defaultHash} {...rest}>
      {children}
    </ContentRenderingProvider>
  );
};
export { ContentRenderingProviderWithRouter };
