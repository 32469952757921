import type { ContentRenderingProviderProps } from '../context';
import type { AccessToken } from '../types/AccessToken';

/** Returns an object with the user's headers
 * @param headers: the user's headers from the apollo context
 * @param token: the token from the content client
 * @param roles: user subscription roles from the authentication provider
 * @param sub: user subscription x-auth-sub from the authentication provider
 */
const getHeaders = (
  headers: any,
  token?: AccessToken | null,
  roles?: ContentRenderingProviderProps['roles'],
  sub?: string,
) => ({
  headers: {
    ...(typeof headers === 'object' ? headers : {}),
    ...(token ? { authorization: `Bearer ${token}` } : {}),
    ...(!token && Array.isArray(roles) ? { 'X-Auth-Role': roles.join(',') } : {}),
    ...(!token && Array.isArray(roles) && sub ? { 'X-Auth-Sub': sub } : {}),
  },
});

const getAuthSubHeaders = (headers: any, sub?: string, token?: string | null) => ({
  headers: {
    ...headers,
    ...(!token && sub ? { 'X-Auth-Sub': sub } : {}),
    ...(token ? { authorization: `Bearer ${token}` } : {}),
  },
});

export { getAuthSubHeaders, getHeaders };
