import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type ExternalReference = {
  __typename?: 'ExternalReference';
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  redirect: RedirectResponse;
};

export type QueryRedirectArgs = {
  input: RedirectQueryParams;
};

export type RedirectQueryParams = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  querystring?: InputMaybe<Scalars['String']['input']>;
};

export type RedirectResponse = ExternalReference | SwsReference;

export type SwsReference = {
  __typename?: 'SwsReference';
  articleLabelId?: Maybe<Scalars['String']['output']>;
  swsId: Scalars['String']['output'];
  tab?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type RedirectQueryVariables = Exact<{
  input: RedirectQueryParams;
}>;

export type RedirectQuery = {
  __typename?: 'Query';
  redirect:
    | { __typename?: 'ExternalReference'; url: string; type: string }
    | {
        __typename?: 'SwsReference';
        swsId: string;
        articleLabelId?: string | null;
        tab?: string | null;
        type: string;
      };
};

export const RedirectDocument = gql`
  query Redirect($input: RedirectQueryParams!) {
    redirect(input: $input)
      @rest(type: "Redirect", method: "POST", path: "/translate", bodySerializer: "string") {
      ... on SwsReference {
        swsId
        articleLabelId
        tab
        type
      }
      ... on ExternalReference {
        url
        type
      }
    }
  }
`;

/**
 * __useRedirectQuery__
 *
 * To run a query within a React component, call `useRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedirectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedirectQuery(
  baseOptions: Apollo.QueryHookOptions<RedirectQuery, RedirectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RedirectQuery, RedirectQueryVariables>(RedirectDocument, options);
}
export function useRedirectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RedirectQuery, RedirectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RedirectQuery, RedirectQueryVariables>(RedirectDocument, options);
}
export type RedirectQueryHookResult = ReturnType<typeof useRedirectQuery>;
export type RedirectLazyQueryHookResult = ReturnType<typeof useRedirectLazyQuery>;
export type RedirectQueryResult = Apollo.QueryResult<RedirectQuery, RedirectQueryVariables>;
