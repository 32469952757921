import {
  BookmarkAddFilledIcon,
  BookmarkAddOutlineIcon,
  FloatingActionButtonMenuItem,
} from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useCallback, useState } from 'react';

import { useReadingLists } from '../../context/ReadingListsProvider';
import { useReadingListsPluginQuery } from '../../generated/userPreferences/graphql';
import { AddToReadingListDrawer } from '../AddToReadingListDrawer/AddToReadingListDrawer';

interface ReadingListPluginRenderProps {
  hasDocument: boolean;
  openDrawer: () => void;
}
export interface ReadingListsPluginProps {
  itemReference: string;
  title: string;
  children?: (props: ReadingListPluginRenderProps) => React.ReactNode;
}

function defaultChildren({ hasDocument, openDrawer }: ReadingListPluginRenderProps) {
  return (
    <FloatingActionButtonMenuItem
      icon={hasDocument ? <BookmarkAddFilledIcon color="primary60" /> : <BookmarkAddOutlineIcon />}
      label={t`Opslaan`}
      testId="floatingActionButtonMenuItemReadingLists"
      onClick={openDrawer}
    />
  );
}

const ReadingListsPlugin = ({
  itemReference,
  title,
  children = defaultChildren,
}: ReadingListsPluginProps) => {
  const { client, siteId, applicationKey } = useReadingLists();

  const [isShown, setShown] = useState<boolean>(false);

  const { data, refetch } = useReadingListsPluginQuery({
    variables: {
      siteId,
      applicationKey,
      itemReference,
    },
    client,
  });

  const handleDrawerToggle = useCallback(
    (isShown: boolean) => {
      setShown(isShown);
      if (!isShown) refetch();
    },
    [refetch],
  );

  return (
    <>
      {children({
        hasDocument: !!data?.readingList.length,
        openDrawer: () => setShown(true),
      })}
      <AddToReadingListDrawer
        isShown={isShown}
        itemReference={itemReference}
        itemTitle={title}
        onDrawerToggle={handleDrawerToggle}
      />
    </>
  );
};

export { ReadingListsPlugin };
