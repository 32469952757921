import { i18n, type Messages } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

function useTranslationsInit(messages: Messages) {
  const router = useRouter();
  const locale = router.locale || router.defaultLocale!;

  const isClient = typeof window !== 'undefined';

  if (isClient && !i18n.locale) {
    i18n.loadAndActivate({ locale, messages });
  }

  /**
   * Need to see if we want to just active here or use the HOC. check this when checking Apollo getDataFromTree + ssr
   * @see https://github.com/lingui/js-lingui/blob/main/examples/nextjs-swc/src/utils.ts
   */
  if (!isClient && locale !== i18n.locale) {
    // there is single instance of i18n on the server
    // note: on the server, we could have an instance of i18n per supported locale
    // to avoid calling loadAndActivate for (worst case) each request, but right now that's what we do
    i18n.loadAndActivate({ locale, messages });
  }

  useEffect(() => {
    const didLocaleChange = locale !== i18n.locale;
    if (didLocaleChange) {
      i18n.loadAndActivate({ locale, messages });
    }
  }, [locale, messages]);

  return i18n;
}

interface TranslationsProviderProps {
  messages: Messages;
}
const TranslationsProvider = ({
  children,
  messages,
}: React.PropsWithChildren<TranslationsProviderProps>) => {
  const initializedI18n = useTranslationsInit(messages);

  return <I18nProvider i18n={initializedI18n}>{children}</I18nProvider>;
};

export { TranslationsProvider, useTranslationsInit };
