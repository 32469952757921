import type { CheckboxOptionProps } from '@elseu/sdu-titan/dist/types/components/CheckboxGroup/CheckboxGroup';

/**
 * Retrieve checkbox options by a specified level.
 *
 * @param {number} fromLevel - The level from which to retrieve options.
 * @param {CheckboxOptionProps[]} [items=[]] - The initial list of checkbox options.
 * @returns {CheckboxOptionProps[]} The list of checkbox options at the specified level.
 */
export const getCheckboxOptionsByLevel = (
  fromLevel: number,
  items: CheckboxOptionProps[] = [],
): CheckboxOptionProps[] => {
  let i = 0;

  /**
   * Recursively get items from the specified level.
   *
   * @param {CheckboxOptionProps[]} items - The current list of items.
   * @returns {CheckboxOptionProps[]} The list of items at the specified level.
   */
  const getItems = (items: CheckboxOptionProps[]): CheckboxOptionProps[] => {
    i++;

    /** Flatten children of all items into a single array */
    const children = items.reduce(
      (all: CheckboxOptionProps[], item: CheckboxOptionProps) => [...all, ...(item.children || [])],
      [],
    );

    /**  Continue recursion until the specified level is reached */
    if (fromLevel > i) {
      return getItems(children);
    }

    /**  Return children when the specified level is reached */
    return children;
  };

  /**  Start the recursion if fromLevel is greater than 0, otherwise return initial items */
  return fromLevel ? getItems(items) : items;
};
