export const Stollfuss = (
  <svg
    id="Ebene_2_00000048481454500258961580000011690210037831140516_"
    version="1.1"
    viewBox="0 0 300 72"
    width="100"
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0"
  >
    <path
      className="st0"
      d="M32.5 20.5c-2.2-6.6-5.2-9.4-9.8-9.4s-7.4 2.9-7.4 7.7c0 10.6 29.9 11.3 29.9 33.3 0 12-8.2 20-22.3 20C12 72 3.6 66 0 52.3l13.2-2.8c1.5 8.4 6.4 11.4 10.4 11.4 4.7 0 8.2-3.1 8.2-8.3 0-13.2-29.9-13.4-29.9-33C1.9 7.6 9.1 0 23 0c11.9 0 19.2 7.1 21.5 17l-12 3.5zM51.4 30.5v-9.9h4.9V6.2h12.2v14.4h7.9v9.9h-7.9v27c0 2.6 2.2 3.9 5.5 3.9.9 0 1.6 0 2.4-.1v10.3c-2.3.4-4.7.5-7 .5-8 0-13.2-2.9-13.2-10.5v-31h-4.8v-.1zM82.6 37.3c0-14 7.2-20 19.4-20s19.4 6 19.4 20V52c0 14-7.2 20-19.4 20s-19.4-6-19.4-20V37.3zm12.2 17.8c0 4.1 2.9 6.2 7.2 6.2s7.2-2.1 7.2-6.2v-21c0-4.1-2.9-6.2-7.2-6.2s-7.2 2.1-7.2 6.2v21zM133.6 1h12.2v70h-12.2V1zM160.6 1h12.2v70h-12.2V1zM206.4 10.7h-3.5c-2.9 0-4.2 1.8-4.2 4.5v5.4h7.3v9.9h-7.3V71h-12.2V30.5H181v-9.9h5.5v-5.1c0-12.2 5.2-15.5 14.1-15.5 1.7 0 3.8.3 5.8.6v10.1zM249.8 18.2V71H238v-6c-4.5 3.9-8.6 7-13.7 7-7.5 0-11.3-3.8-11.3-13.6V18.2h12.2V57c0 3.5 1.2 5.8 4.5 5.8 2.5 0 5.3-2.4 8-4.8V18.2h12.1zM261.5 71V20.3c0-11.4 4.1-19.3 18.3-19.3 11.1 0 17.4 6.9 17.4 19.5 0 4.7-2 10.1-6.3 12.4 7.1 3.8 9.1 9.8 9.1 19.5 0 12.9-6.1 19.6-14.4 19.6-2.4 0-4.9-.5-7.1-1.4v-11c1.2.6 2.5.9 3.9.9 3.7 0 5.3-2.8 5.3-9.6 0-7.4-1.4-12.1-9.1-12.1v-9.9c4.4-.2 6.9-3.3 6.9-8.5 0-5-1.6-8.5-6.4-8.5-3.8 0-5.4 2.7-5.4 5.7V71h-12.2z"
      fill="#004b92"
    />
  </svg>
);
