/** Push event to datalayer */
export const pushToDataLayer = (payload) => {
    if (payload !== null) {
        getDataLayer().push(payload);
    }
};
/** Return dataLayer and if it doesn't exist, assign it */
export function getDataLayer() {
    if (!window.dataLayer) {
        window.dataLayer = [];
    }
    return window.dataLayer;
}
