import { Select } from '@elseu/sdu-titan';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { useSiteConfig } from '../../../../../../context/SiteConfigProvider';
import { getAlertFormDefaultValues } from '../../../../helpers/getAlertFormDefaultValues';
import type { AlertFormValues } from '../../AlertFormSchema';
import { useTypeField } from './useTypeField';

/**
 * Props for the TypeField component.
 */
interface TypeFieldProps {
  isLoading: boolean;
}

/**
 * TypeField component used to display and manage a select field for the information type.
 *
 * @param {boolean} isLoading - Flag to indicate if the options are still loading.
 * @returns {JSX.Element | null} The Select component for the information type field, or null if options are not available.
 */
const TypeField = ({ isLoading }: TypeFieldProps) => {
  const { facetsPreset } = useSiteConfig();
  const { options, transform } = useTypeField();

  const { control, reset, getValues } = useFormContext<AlertFormValues>();
  const {
    field: { value, onChange, ...field },
    formState: { errors },
  } = useController({
    name: `facets.${facetsPreset.tabsFacet}`,
    control,
  });

  /**
   * Reset form values with the default values based on the selected type.
   *
   * @param {string[]} values - The selected values.
   */
  const resetFormValues = useCallback(
    (values: string[]) => {
      const defaultValues = getAlertFormDefaultValues(
        facetsPreset.tabsFacet,
        facetsPreset.facets,
        values,
      );

      reset({
        ...defaultValues,
        customName: getValues('customName'),
        term: getValues('term'),
      });
    },
    [facetsPreset.facets, facetsPreset.tabsFacet, getValues, reset],
  );

  /**
   * Handle the change of the selected value.
   *
   * @param {string} value - The new selected value.
   */
  const handleChange = useCallback(
    (value: string) => {
      const values = transform.output(value);

      onChange(values);
      resetFormValues(values);
    },
    [transform, onChange, resetFormValues],
  );

  if (!options) return null;

  return (
    <Select
      {...field}
      disabled={options.length === 0 || isLoading}
      errorText={errors.facets?.[facetsPreset.tabsFacet]?.message}
      hasError={!!errors.facets?.[facetsPreset.tabsFacet]}
      label="Informatiesoort"
      options={options}
      popoverZIndex={1040}
      spaceAfter={4}
      testId="alertFormType"
      value={transform.input(value)}
      onChange={handleChange}
    />
  );
};

export { TypeField };
