import { type Messages } from '@lingui/core';
import type { NextPage, NextPageContext } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';

import { getDisplayName } from '../helpers/getDisplayName';
import type { AppWithProps } from './types';

type WithLinguiContext = AppContext & NextPageContext;

export interface LinguiProps {
  messages: Messages;
}

function withLingui<T>(
  PageComponent: NextPage<any> | AppWithProps<T>,
  loadMessagesFn: (locale?: string) => Promise<void>,
) {
  const WithLingui = (props: AppProps & T) => <PageComponent {...props} />;

  WithLingui.displayName = `WithLingui(${getDisplayName(PageComponent)})`;
  WithLingui.getInitialProps = async (ctx: WithLinguiContext) => {
    const isInAppContext = Boolean(ctx.ctx);

    let pageProps = {};
    if (PageComponent.getInitialProps) {
      pageProps = { ...pageProps, ...(await PageComponent.getInitialProps(ctx)) };
    } else if (isInAppContext) {
      pageProps = { ...pageProps, ...(await App.getInitialProps(ctx)) };
    }

    const locale = ctx.ctx.locale;
    const messages = await loadMessagesFn(locale);

    return {
      ...pageProps,
      messages,
    };
  };

  return WithLingui;
}

export { withLingui };
