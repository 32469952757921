/* eslint-disable max-len */
import type {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { useQuery } from '@apollo/client';

import { useSiteConfig } from '../context/SiteConfigProvider';
import { useTitanCmsApolloClient } from '../context/TitanCmsProvider/hooks/useTitanCmsApolloClient';
import type { QuerySiteArgs } from '../generated/cms/graphql';
import { errorHandling } from '../helpers/errorHandling';

/* Omit client option and key from variables */
type CMSQueryOptions<TData, TVariables> = Omit<
  QueryHookOptions<TData, Omit<TVariables, 'key'>>,
  'client'
>;

/** Omit key from query */
type CMSQuery<TData, TVariables> = DocumentNode | TypedDocumentNode<TData, Omit<TVariables, 'key'>>;

export function useCMSQuery<TData = any, TVariables = OperationVariables>(
  query: CMSQuery<TData, TVariables>,
  options?: CMSQueryOptions<TData, TVariables>,
) {
  const { siteKey } = useSiteConfig();
  const client = useTitanCmsApolloClient();

  /** We pass the client and key variable here since we use that in every cms request */
  const queryOptions = {
    client,
    ...(options || {}),
    variables: {
      ...options?.variables,
      key: siteKey,
    },
  } as QueryHookOptions<TData, TVariables & QuerySiteArgs>;

  const {
    data: response,
    loading: isLoading,
    ...rest
  } = useQuery<TData, TVariables & QuerySiteArgs>(query, queryOptions);

  errorHandling(rest.error);

  return { response, isLoading, ...rest };
}
