import { mergeDeep } from '@apollo/client/utilities';
import introspectionQueryResultData from '../generated/content-api/fragmentTypes.json';
const getContentCacheTypePolicies = () => {
    const cacheTypePolicies = {};
    [
        ...introspectionQueryResultData.possibleTypes.Tab,
        ...introspectionQueryResultData.possibleTypes.LawTocEntry,
        'TocEntry',
        'BookTocEntry',
        'LawTocEntry',
        'FootNote',
    ].forEach((value) => {
        cacheTypePolicies[value] = {
            keyFields: false,
        };
    });
    introspectionQueryResultData.possibleTypes.Content.forEach((key) => {
        cacheTypePolicies[key] = {
            fields: {
                tabs: {
                    merge(existing = [], incoming) {
                        return mergeDeep(existing, incoming);
                    },
                },
            },
        };
    });
    introspectionQueryResultData.possibleTypes.ContentPart.forEach((key) => {
        cacheTypePolicies[key] = {
            keyFields: false,
            fields: {
                tabs: {
                    merge(existing = [], incoming) {
                        return mergeDeep(existing, incoming);
                    },
                },
            },
        };
    });
    return cacheTypePolicies;
};
export { getContentCacheTypePolicies };
