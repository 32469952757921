import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useContext } from 'react';

import { TitanCmsContext } from '../TitanCmsProvider';

export const useTitanCmsApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const context = useContext(TitanCmsContext);
  if (!context?.client) {
    throw new Error('Cannot use useTitanCmsApolloClient without TitanCmsProvider.');
  }
  return context.client;
};
