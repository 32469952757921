import { Box, Button, ContentWrapper, Header, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { NextSeo } from 'next-seo';

import { RedirectChecker } from '../../RedirectChecker/RedirectChecker';

const NotFoundComponent = () => {
  useLingui();
  return (
    <>
      <NextSeo
        nofollow
        noindex
        description={t`Wij hebben ons best gedaan maar het lijkt erop dat deze
        pagina niet (meer) bestaat of de pagina verhuisd is of dat je een typefoutje hebt gemaakt.`}
        title={t`Helaas konden wij de pagina niet vinden`}
      />
      <ContentWrapper id="main-content" maxWidth={640} spaceAfter={20} testId="notFound">
        <Header
          hasContentWrapper={false}
          labelBackLink={<Trans>Terug</Trans>}
          title={<Trans>Helaas konden wij de pagina niet vinden</Trans>}
        />
        <Box spaceAfter={4}>
          <Text type="paragraph">
            <Trans>
              Wij hebben ons best gedaan maar het lijkt erop dat deze pagina niet (meer) bestaat of
              de pagina verhuisd is of dat je een typefoutje hebt gemaakt. Wij helpen je graag de
              weg terug te vinden. Je kan altijd naar de homepage of de zoekfunctie gebruiken.
            </Trans>
          </Text>
        </Box>
        <Button href="/" testId="notFoundHomeButton">
          <Trans>Terug naar home</Trans>
        </Button>
      </ContentWrapper>
    </>
  );
};

const NotFound = () => {
  return (
    <RedirectChecker>
      <NotFoundComponent />
    </RedirectChecker>
  );
};

export { NotFound, NotFoundComponent };
