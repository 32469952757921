import * as Sentry from '@sentry/nextjs';
import type { ScopeContext } from '@sentry/types';

export const errorHandling = (error?: Error, captureContext: Partial<ScopeContext> = {}) => {
  if (!error) {
    return;
  }
  // If ssr we should also send to Sentry because error boundaries doesn't work serverside.
  if (typeof window === 'undefined') {
    Sentry.captureException(error, {
      ...captureContext,
      extra: {
        ...captureContext.extra,
        errorType: 'NextJS helpers/errorHandling',
      },
    });
  }

  throw error;
};
