import React, { useContext, useMemo } from 'react';

type InteractionType =
  | 'button_click'
  | 'navigation_link_click'
  | 'menu_click'
  | 'icon_click'
  | 'form_submission'
  | 'text_input_in_field'
  | 'drop_down_selection'
  | 'multiple_choice_selection'
  | 'single_choice_selection'
  | 'date_picker_selection'
  | 'hover_over_element'
  | 'mouse_enter'
  | 'mouse_leave'
  | 'drag_and_drop_action'
  | 'page_scroll'
  | 'click_scrollable_element'
  | 'swipe_left'
  | 'swipe_right'
  | 'swipe_up'
  | 'swipe_down'
  | 'pinch_to_zoom'
  | 'tap'
  | 'modal_window_open'
  | 'modal_window_close'
  | 'tab_change'
  | 'video_play'
  | 'video_pause'
  | 'video_stop'
  | 'sound_on'
  | 'sound_off'
  | 'adjusting_volume'
  | 'adjusting_playback_speed'
  | 'forward_scrub'
  | 'backward_scrub'
  | 'add_to_cart'
  | 'button_click'
  | 'remove_from_cart'
  | 'checkout_process_step'
  | 'share_click'
  | 'like_click'
  | 'comment_click'
  | 'file_upload'
  | 'file_download'
  | 'opening_document'
  | 'closing_document'
  | 'display_error_message'
  | 'loading_more_data';

/**
 * The event object that is pushed to the dataLayer.
 * @typedef {Object} InteractionEvent
 * @property {string} event The event name.
 * @property {string} [element_id] The id of the element that was interacted with.
 * @property {string} [element_type] The type of element that was interacted with.
 * @property {string} [interaction_type] The type of interaction that occurred.
 * @property {string} [content_type] The type of content that was interacted with.
 * @property {string} [feature_layer] The name of the feature layer that was interacted with.
 * @property {number} [feature_layer_index] The index of the feature layer that was interacted with.
 * @property {string} [domain_sessionid] The domain session id.
 * @property {string} page_view_id The page view id.
 * @property {string} additional_info Any additional information.
 */
type InteractionEvent = {
  event: string;
  element_id?: string;
  element_type?: string;
  interaction_type?: InteractionType;
  content_type?: string;
  feature_layer?: string;
  feature_layer_index?: number;
  domain_sessionid?: string;
  page_view_id?: string;
  additional_info?: string;
};

/**
 * The event object that is pushed to the dataLayer for element visibility.
 * @typedef {Object} VisibilityEvent
 * @property {string} event The event name.
 * @property {string} id The id of the element.
 * @property {string} element_type The type of element.
 * @property {string} feature_layer The name of the feature layer.
 * @property {number} feature_layer_index The index of the feature layer.
 * @property {string} element_visible Whether the element is visible or not.
 * @property {string} domain_sessionid The domain session id.
 * @property {string} page_view_id The page view id.
 * @property {string} [variant_id] The variant id for A/B tests.
 */
type VisibilityEvent = {
  event: string;
  id?: string;
  element_type?: string;
  feature_layer?: string;
  feature_layer_index?: number;
  element_visible?: string;
  domain_sessionid?: string;
  page_view_id?: string;
  variant_id?: string;
};

/**
 * Pushes an event to the dataLayer.
 * @param event The event object to push to the dataLayer.
 */
const pushToDataLayer = (event: InteractionEvent | VisibilityEvent) => {
  const dataLayer = (window as any)?.dataLayer;

  if (dataLayer) {
    dataLayer.push(event);
  } else {
    /* eslint-disable-next-line no-console */
    console.log('%c dataLayer push: %o', 'color: #008000; font-weight: bold;', event);
  }
};

interface TrackFeatureInteractionOptions {
  id: string;
  elementType: string;
  interactionType: InteractionType;
  featureLayer?: string;
  featureLayerIndex?: number;
}

/**
 * Tracks a feature interaction event.
 * @param params The parameters for the interaction event.
 */
const trackFeatureInteraction = ({
  id,
  elementType,
  interactionType,
  featureLayer,
  featureLayerIndex,
}: TrackFeatureInteractionOptions) => {
  const interactionEvent: InteractionEvent = {
    event: 'feature_interaction',
    element_id: id,
    element_type: elementType,
    interaction_type: interactionType,
    feature_layer: featureLayer,
    feature_layer_index: featureLayerIndex,
  };

  pushToDataLayer(interactionEvent);
};

interface TrackElementVisibilityOptions {
  elementType: string;
  id: string;
  isVisible: boolean;
  featureLayer?: string;
  featureLayerIndex?: number;
}

/**
 * Tracks an element visibility event.
 * @param params The parameters for the visibility event.
 */
const trackElementVisibility = ({
  elementType,
  id,
  isVisible,
  featureLayer,
  featureLayerIndex,
}: TrackElementVisibilityOptions) => {
  const visibilityEvent: VisibilityEvent = {
    event: 'element_visibility',
    id,
    element_type: elementType,
    element_visible: isVisible.toString(),
    feature_layer: featureLayer,
    feature_layer_index: featureLayerIndex,
  };

  pushToDataLayer(visibilityEvent);
};

interface AnalyticsContextValue {
  featureLayer?: string;
  featureLayerIndex?: number;
}

const AnalyticsContext = React.createContext({} as AnalyticsContextValue);

export function useAnalytics() {
  const context = useContext(AnalyticsContext);

  return {
    trackElementVisibility: (options: TrackElementVisibilityOptions) =>
      trackElementVisibility({
        ...options,
        ...context,
      }),
    trackFeatureInteraction: (options: TrackFeatureInteractionOptions) =>
      trackFeatureInteraction({
        ...options,
        ...context,
      }),
  };
}

export function AnalyticsProvider({
  children,
  featureLayer,
  featureLayerIndex,
}: React.PropsWithChildren<AnalyticsContextValue>) {
  const value = useMemo(() => {
    return {
      featureLayer,
      featureLayerIndex,
    };
  }, [featureLayer, featureLayerIndex]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
}
