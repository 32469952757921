import queryString from 'query-string';

/**
 * Replaces hash outside of the useRouter/NextRouter context in order to silently replace a hash without
 * enforced scrolling or causing unecessary rerenders
 * @param hash - new hash
 * @param replace - boolean to push or replace state
 */
export function updateHash(hash?: string, replace = true) {
  const state = window.history.state;
  if (typeof window === 'undefined' || !state?.as || state?.as?.includes(hash)) return;

  /** parse state.as to an url object to easily parse order all params without splitting */
  const { url: path, query } = queryString.parseUrl(state?.as);

  /** We manipulate the state object to not mess with next.js their router relying on these properties being correct */
  const newUrl = queryString.stringifyUrl({
    url: `${path}`,
    query,
    fragmentIdentifier: hash,
  });
  state.as = newUrl;
  window.history[replace ? 'replaceState' : 'pushState'](state, '', state.as);
}
