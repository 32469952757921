import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useContentClient } from '../hooks/useContentClient';
import { useContentExportClient } from '../hooks/useContentExportClient';
const ContentExportContext = React.createContext({});
const useContentExport = () => {
    const context = React.useContext(ContentExportContext);
    if (context === undefined) {
        throw new Error('useContentExport must be used within a ContentExportProvider');
    }
    return context;
};
const ContentExportProvider = ({ applicationKey, siteName, exportGraphqlUrl, contentGraphqlUrl, accessToken, urls, fetch, onError, children, }) => {
    const exportClient = useContentExportClient({
        graphqlUrl: exportGraphqlUrl,
        accessToken,
        fetch,
    });
    const contentClient = useContentClient({
        graphqlUrl: contentGraphqlUrl,
        accessToken,
        fetch,
    });
    const context = useMemo(() => ({
        siteName,
        exportClient: !process.env.JEST_WORKER_ID ? exportClient : undefined,
        contentClient: !process.env.JEST_WORKER_ID ? contentClient : undefined,
        applicationKey,
        urls,
        onError,
    }), [siteName, exportClient, contentClient, applicationKey, urls, onError]);
    return _jsx(ContentExportContext.Provider, { value: context, children: children });
};
export { ContentExportProvider, useContentExport };
