import type { FetchResult } from '@apollo/client';
import { Button, Flex, spacing } from '@elseu/sdu-titan';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useReadingLists } from '../../context/ReadingListsProvider';
import type { ReadingListFormValues } from '../../form-schemas/readingListForm';
import { readingListFormSchema } from '../../form-schemas/readingListForm';
import {
  CreateReadingListDocument,
  type Mutation,
  type MutationCreateReadingListArgs,
} from '../../generated/userPreferences/graphql';
import { useAnalytics } from '../../helpers/analyticsTracker';
import { useApiFieldError } from '../../helpers/useApiFieldError';
import { useResettableMutation } from '../../hooks/useResettableMutation';
import { NewReadingListForm } from '../NewReadingListForm';

interface NewReadingListProps {
  /** The reference of the item to add to the reading list */
  itemReference: string;
  /** The title of the item to add to the reading list */
  itemTitle: string;
  /** The callback to call when the reading list is created */
  onSuccess?: (data: Array<FetchResult<Mutation>>) => void;
}

const StyledButton = styled(Button).withConfig({
  componentId: 'NewReadingListButton',
} as any)`
  flex-shrink: 0;
  margin-top: ${spacing(2)};
`;

const NewReadingListContainer = styled(Flex).withConfig({
  componentId: 'NewReadingListContainer',
} as any)`
  width: 100%;
  flex-direction: column;
`;

const NewReadingListFormContainer = styled.div`
  flex-basis: 100%;
  width: 100%;
`;

const NewReadingList = ({ itemReference, itemTitle, onSuccess }: NewReadingListProps) => {
  const { client, siteId } = useReadingLists();
  const { i18n } = useLingui();
  const { trackFeatureInteraction } = useAnalytics();

  const methods = useForm<ReadingListFormValues>({
    defaultValues: { name: '', description: '' },
    resolver: yupResolver(readingListFormSchema()),
    mode: 'all',
  });

  const [createReadingList, { loading: isLoading, error, reset }] = useResettableMutation<
    Mutation,
    MutationCreateReadingListArgs
  >(CreateReadingListDocument, {
    client,
    refetchQueries: ['ReadingLists'],
    update: (_cache, result) => {
      if (!result.data?.createReadingList) return;
      onSuccess?.([result]);
      reset();
    },
  });

  const onSubmit = useCallback(
    (data: ReadingListFormValues) => {
      createReadingList({
        variables: {
          ...data,
          siteId,
          documents: [
            {
              itemReference,
              title: itemTitle,
            },
          ],
        },
      });
      trackFeatureInteraction({
        id: 'newReadingListAddButton',
        elementType: 'button',
        interactionType: 'form_submission',
      });
    },
    [createReadingList, siteId, itemReference, itemTitle, trackFeatureInteraction],
  );

  const getFieldError = useApiFieldError<ReadingListFormValues>();

  useEffect(() => {
    if (!error) return;
    getFieldError(error, (field, message) => {
      return methods.setError(field, {
        type: 'manual',
        message: typeof message === 'string' ? message : i18n._(message),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, getFieldError, methods.setError, i18n]);

  return (
    <FormProvider {...methods}>
      <form data-test-id="newReadingList" onSubmit={methods.handleSubmit(onSubmit)}>
        <NewReadingListContainer
          alignItems="flex-end"
          justifyContent="space-between"
          spaceAfter={{ xs: 6, s: 8, md: 2 }}
        >
          <NewReadingListFormContainer>
            <NewReadingListForm />
          </NewReadingListFormContainer>
          {methods.formState.isValid && (
            <StyledButton
              data-test-id="newReadingListAddButton"
              isDisabled={isLoading}
              type="submit"
            >
              <Trans>Toevoegen</Trans>
            </StyledButton>
          )}
        </NewReadingListContainer>
      </form>
    </FormProvider>
  );
};

export { NewReadingList };
