import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';

import introspectionQueryResultData from '../generated/userPreferences/fragmentTypes.json';
import { getHeaders } from '../helpers/getRequestHeaders';

export type AccessToken = (() => Promise<string | null>) | string;

interface ReadingListsClient {
  /** The url of the graphql endpoint */
  graphqlUrl: string;
  /** The access token */
  accessToken?: AccessToken;
  /** The initial state of the cache */
  initialState?: NormalizedCacheObject;
  /** The callback to register the cache */
  registerCache?: (cache: InMemoryCache) => void;
  /** The fetch function */
  fetch?: WindowOrWorkerGlobalScope['fetch'];
}

export const useReadingListsClient = ({
  graphqlUrl,
  accessToken,
  initialState,
  registerCache,
  fetch,
}: ReadingListsClient): ApolloClient<NormalizedCacheObject> => {
  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: graphqlUrl,
        fetch,
      }),
    [graphqlUrl, fetch],
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        if (!accessToken || typeof accessToken === 'string') {
          return getHeaders(headers, accessToken);
        }
        return accessToken().then((token) => getHeaders(headers, token));
      }),
    [accessToken],
  );

  return useMemo(() => {
    const cache = new InMemoryCache({
      possibleTypes: introspectionQueryResultData.possibleTypes,
      typePolicies: {},
    }).restore(initialState || {});

    if (registerCache) {
      registerCache(cache);
    }

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache,
    });
  }, [authLink, httpLink, initialState, registerCache]);
};
