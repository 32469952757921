import { getDataLayer, pushToDataLayer } from '@repo/analytics';

import type { SearchPayload, SearchResultPayload } from './SearchAnalyticsEventPayloads';
import { SearchEvent } from './SearchAnalyticsEventPayloads';
import type { CombinedAnalyticsPlugin } from './types';

const getSearchEvent = (event: SearchEvent, props: SearchPayload) => ({
  event,
  search_interaction_type: event,
  ...props,
});

const getSearchResultClickEvent = (event: SearchEvent, props: SearchResultPayload) => ({
  event,
  search_interaction_type: event,
  ...props,
});

export const searchDataLayerPlugin = (): CombinedAnalyticsPlugin => {
  return {
    name: 'searchDataLayerAnalytics',
    initializeStart: ({ abort }) => {
      if (typeof window === 'undefined') {
        return abort('Cancel because server side rendering');
      }
    },
    initialize: () => {
      getDataLayer();
    },
    track: ({ payload }) => {
      switch (payload.event) {
        case SearchEvent.SEARCH:
        case SearchEvent.SEARCH_FILTER:
        case SearchEvent.SEARCH_FILTERS_CLEARED:
        case SearchEvent.SEARCH_RESULTS:
        case SearchEvent.SEARCH_SORT:
          pushToDataLayer(getSearchEvent(payload.event, payload.properties));
          break;
        case SearchEvent.SEARCH_RESULT_CLICK:
          pushToDataLayer(getSearchResultClickEvent(payload.event, payload.properties));
          break;
      }
    },
  };
};
