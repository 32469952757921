import type { ContentRenderingProviderProps, DockItem } from '@elseu/sdu-titan-content-rendering';
import { useAuthentication } from '@repo/auth';
import { useMemo } from 'react';

import { Error } from '../../components/Error/Error';
import { ErrorBoundary } from '../../components/Error/ErrorBoundary/ErrorBoundary';
import {
  useDownloadPlugin,
  usePrintPlugin,
  useReadingListsPlugin,
} from '../../components/Plugins/dockPlugins';
import { renderReadingListButtonFunction } from '../../helpers';
import { useIsAnonymousUser } from '../../hooks';
import { useSiteConfig } from '../SiteConfigProvider';
import { ContentRenderingProviderWithRouter } from './ContentRenderingProviderWithRouter';

interface ContentRenderingProviderWithPluginsProps extends Partial<ContentRenderingProviderProps> {
  brandingKey?: string;
}
const ContentRenderingProviderWithPlugins = ({
  children,
  dockItems: customDockItems,
  brandingKey,
  ...rest
}: React.PropsWithChildren<ContentRenderingProviderWithPluginsProps>) => {
  const {
    isLoggedIn: isCookiesLoggedIn,
    featureFlags,
    pmtToolBaseUrl,
    applicationKey,
    graphqlUrl,
    brandName,
  } = useSiteConfig();
  const { isLoggedIn, getAccessToken } = useAuthentication();
  const isAnonymousUser = useIsAnonymousUser();

  const contentSsrConfig = useMemo(
    () => ({
      main: !isCookiesLoggedIn,
      contentParts: isCookiesLoggedIn ? false : 5,
      footNotes: false,
    }),
    [isCookiesLoggedIn],
  );

  const downloadPlugin = useDownloadPlugin({ brandingKey });
  const printPlugin = usePrintPlugin({ brandingKey });
  const readingListsPlugin = useReadingListsPlugin();

  const dockItems: DockItem[] = useMemo(() => {
    return [...[downloadPlugin, printPlugin, readingListsPlugin], ...(customDockItems || [])];
  }, [downloadPlugin, printPlugin, readingListsPlugin, customDockItems]);

  return (
    <ContentRenderingProviderWithRouter
      hasExternalI18nProvider
      accessToken={getAccessToken}
      applicationKey={applicationKey}
      canHavePersonalisationFeatures={isLoggedIn && !isAnonymousUser}
      contentPartPlugins={[]}
      dockItems={dockItems}
      errorBoundary={ErrorBoundary as ContentRenderingProviderProps['errorBoundary']}
      errorComponent={Error}
      featureFlags={featureFlags as Record<string, boolean>}
      graphqlUrl={graphqlUrl}
      hasMagazineEditionPdfDownload={!featureFlags.WITHOUT_MAGAZINE_EDITION_PDF}
      hasOriginalSourceLinks={featureFlags.WITH_ORIGINAL_SOURCE_LINKS}
      pmtToolBaseUrl={pmtToolBaseUrl}
      renderReadingListButton={
        isLoggedIn && !isAnonymousUser ? renderReadingListButtonFunction : undefined
      }
      siteName={brandName}
      ssrConfig={contentSsrConfig}
      {...rest}
    >
      {children}
    </ContentRenderingProviderWithRouter>
  );
};

export { ContentRenderingProviderWithPlugins };
