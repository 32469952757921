import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckboxTreeListItem, Divider } from '@elseu/sdu-titan';
import { useContentExportDrawer } from './ContentExportDrawerProvider';
const ContentExportDrawerSelectAll = () => {
    const { isTreeDataLoading, isAllChecked, onChangeSelectAll, labelSelectAll } = useContentExportDrawer();
    if (isTreeDataLoading)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(CheckboxTreeListItem, { depth: 0, hasExpander: false, isChecked: !!isAllChecked, isExpanded: false, label: labelSelectAll, testId: "contentExportDrawerSelectAll", value: "all", variant: "plus", onChange: ({ isChecked }) => onChangeSelectAll(isChecked) }), _jsx(Divider, { direction: "horizontal", size: 1, spaceAfter: 2, spaceBefore: 2 })] }));
};
export { ContentExportDrawerSelectAll };
