import type { UserRole } from '../components/ShareReadingListDrawer/UserRoles';
import type {
  AcceptSharingLinkMutation,
  ReadingListsQuery,
} from '../generated/userPreferences/graphql';

export const getAccessLevel = ({
  readingList,
  userId,
}: {
  readingList:
    | ReadingListsQuery['readingList'][0]
    | AcceptSharingLinkMutation['acceptSharingLink']
    | undefined;
  userId: string | undefined;
}): UserRole | undefined => {
  if (!readingList || !userId) return undefined;

  const currentUserAccess = readingList.userAccess.find((access) => access.userId === userId);

  return currentUserAccess?.userRole as UserRole | undefined;
};
