const crawlerUserAgents = [
  'googlebot',
  'yahoo',
  'bingbot',
  'baiduspider',
  'facebookexternalhit',
  'twitterbot',
  'rogerbot',
  'linkedinbot',
  'embedly',
  'quora link preview',
  'showyoubot',
  'outbrain',
  'pinterest',
  'developers.google.com/+/web/snippet',
  'slackbot',
  'vkShare',
  'W3C_Validator',
  'redditbot',
  'Applebot',
];

const isBotSSR = (userAgent: any) => {
  if (typeof window !== 'undefined' || !userAgent || typeof userAgent !== 'string') return false;

  return crawlerUserAgents.some((crawlerUserAgent) => {
    return userAgent.toLowerCase().includes(crawlerUserAgent.toLowerCase());
  });
};

const isBotClient =
  typeof window !== 'undefined' &&
  /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

export { isBotClient, isBotSSR };
