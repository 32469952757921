import type { NextPage, NextPageContext } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';

import { getDisplayName } from '../helpers/getDisplayName';
import { isBotClient, isBotSSR } from '../helpers/isBot';
import type { AppWithProps } from './types';

type WithBotContext = AppContext & NextPageContext;

export interface BotProps {
  isBot: boolean;
}

function withBot<T>(PageComponent: NextPage<any> | AppWithProps<T>) {
  const WithBot = (props: AppProps & T) => <PageComponent {...props} />;

  WithBot.displayName = `WithBot(${getDisplayName(PageComponent)})`;
  WithBot.getInitialProps = async (ctx: WithBotContext) => {
    const isInAppContext = Boolean(ctx.ctx);

    let pageProps = {};
    if (PageComponent.getInitialProps) {
      pageProps = { ...pageProps, ...(await PageComponent.getInitialProps(ctx)) };
    } else if (isInAppContext) {
      pageProps = { ...pageProps, ...(await App.getInitialProps(ctx)) };
    }

    const userAgent = ctx.ctx.req?.headers['user-agent'];

    return {
      ...pageProps,
      isBot: typeof window === 'undefined' ? isBotSSR(userAgent) : isBotClient,
    };
  };

  return WithBot;
}

export { withBot };
